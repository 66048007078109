import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonToolbar,
    IonButtons,
    IonButton,

    IonBackButton,
    IonIcon,
    IonHeader,
    IonModal,
    IonMenuButton,
    IonToast,
    IonLoading,
    IonTextarea
} from '@ionic/react';

import { 
    globeOutline,
    callOutline,
    logoFacebook,
    logoInstagram,
    // heartOutline,
    // heartSharp,
    // alertCircle,
    // shareOutline,
    alertCircleOutline,
    chevronBack
} from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import { database } from '../lib/init-firebase';
import { ref, update, push } from 'firebase/database';


import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';

const MIN_LOADING_DURATION = 1000; // 1 second in milliseconds



const HealthWindow: React.FC<{
    gridNo: any;
    clinics: any[];
    // parties: any[];
    // currentDateAndTime: any;
    // currentDateAndTimePlusTwoWeeks: any
    // likedBarsAndClubs: any;
    // setLikedBarsAndClubs: any;
    // handleIncrement: any;
    // handleDecrement: any;
}> = (props) => {

    const selectedClinicId = useParams<{ id: string }>().id;
    const selectedClinic = props.clinics.find(clinic => clinic.id === selectedClinicId);
    // console.log(selectedBarClub, 'selected bc');

    // theres no reload in bars and clubs so current date plus two weeks would depend on poeple relaoding .. but i assume given its two weeks it wont ever get that off..like would app reload are a certain period

    const [isModalRv, setIsModalRv] = useState(false);
    const [messageRv, setMessageRv] = useState('');

  const [showLoadingRv, setShowLoadingRv] = useState(false);
  const [showToastRv, setShowToastRv] = useState(false);
  const [toastMessageRv, setToastMessageRv] = useState('');
  const [toastColorRv, setToastColorRv] = useState('');
  


    const [isWindowMapOpenH, setIsWindowMapOpenH] = useState<any>(false);
    const [isWindowMapLoadedH, setIsWindowMapLoadedH] = useState<any>(false);
    // const [myMapBC, setMyMapBC] = useState<any>(null);
    //why dont i use myMapP? ive got rid of setting it for the moment.

    const modalMapH = useRef<HTMLIonModalElement>(null);
    const modalAddReviewH = useRef<HTMLIonModalElement>(null);

    const mapContainerH = useRef<any>(null);

    const closeRv = () => {
        setIsModalRv(false);
        setMessageRv('');
      }
      
      
      const addRev = async () => {
        const startTime = Date.now();
        setShowLoadingRv(true);
      
        try {
          // const db = getDatabase();
          const feedbackRef = ref(database, 'data/reviews/venues');
          
          const newFeedbackKey = push(feedbackRef).key;
          
          const feedbackData = {
            id: newFeedbackKey,
            venue_id: selectedClinic.id,
            venue_name: selectedClinic.name,
            venue_city: selectedClinic.city,
            review: messageRv.trim(),
            rating: 0,
            timestamp: Date.now()
          };
      
          const updates: { [key: string]: any } = {};
          updates[`data/reviews/venues/${newFeedbackKey}`] = feedbackData;
          await update(ref(database), updates);
      
          setToastMessageRv('Review submitted successfully!');
          setToastColorRv('success');
          // setMessage('');
        } catch (error) {
          console.error("Error submitting review:", error);
          setToastMessageRv('Failed to submit review. Please try again.');
          setToastColorRv('danger');
        } finally {
          const endTime = Date.now();
          const elapsedTime = endTime - startTime;
          if (elapsedTime < MIN_LOADING_DURATION) {
            await new Promise(resolve => setTimeout(resolve, MIN_LOADING_DURATION - elapsedTime));
          }
          setShowLoadingRv(false);
          setIsModalRv(false);
          setMessageRv('');
          setShowToastRv(true);
        }
      };

    useEffect(() => {

        if (!mapContainerH.current) {
            return
        }

        const mapSau = new mapboxgl.Map({
            container: mapContainerH.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedClinic?.lng, selectedClinic?.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedClinic?.lng, selectedClinic?.lat]).addTo(mapSau);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapBC(mapBC);

        return () => mapSau.remove();

    },[isWindowMapLoadedH]);

    const handleDismissWindowMapModalH = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because...
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedH(false);
        setIsWindowMapOpenH(false);
    }

    return(
        <IonPage>
            {
                selectedClinic ? (
            <IonContent >
                <IonModal
                ref={modalAddReviewH}
                // id="example-modalPartiesNotifications"
                isOpen={isModalRv}
                onDidDismiss={closeRv}
                className="notifmodal2"
                >
                  <IonToolbar className="notiftoolbar">
                              <IonButtons slot="secondary">
                                      <IonButton
                                      // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolr"}
                                    onClick={closeRv}
                                    // disabled={showLoading || message.trim() === ''}
                                    >
                          
                                      Close
                                      {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                                  
                                  </IonButton>
                              </IonButtons>
                      <div className="notifheader">Review Clinic</div>
                      <IonButtons slot="primary">
                              <IonButton
                              // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolp"}
                              onClick={addRev}
                              disabled={showLoadingRv || messageRv.trim() === ''}
                              >
                            
                                Submit
                                {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                            
                            </IonButton>
                      </IonButtons>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                    <div className="notifgradient">

                      <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={11}
                                value={messageRv}
                                onIonInput={(e:any)=>setMessageRv(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please share your thoughts on the clinic. What did you like? What could be improved? The response will remain anonymous."
                                disabled={showLoadingRv}
                                />
                                <div className="plslimit">Please limit the response to 300 characters.</div>
                      </div>

                      <div className="btnsfeedback">
                            
                          
                        </div>

                                  
                    </div>

                                    

                              

                    </IonContent>
                              <IonLoading
                                isOpen={showLoadingRv}
                                message={'Submitting review...'}
                                className="custom-loading"
                              />
        </IonModal>


                <IonToast
                                isOpen={showToastRv}
                                onDidDismiss={() => setShowToastRv(false)}
                                message={toastMessageRv}
                                duration={3000}
                                color={toastColorRv}
                              />
                <IonModal
                ref={modalMapH}
                className="windowmapmodal"
                isOpen={isWindowMapOpenH}
                onDidDismiss={handleDismissWindowMapModalH}
                onDidPresent={() => setIsWindowMapLoadedH(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerH} className="mapcontainerinner" />
                    </div>
                </IonModal>

                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="ionbackbuttoncontoth">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                    <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                    </div>
                    

                    <div className="windowtopcont">
                        {
                            selectedClinic.image_src
                            &&
                            <img className="windowimgbox" src={selectedClinic.image_src} alt=""
                            onError={(e: any) => e.target.src = selectedClinic.image_url}

                            ></img>

                        }
                        {
                            !selectedClinic.image_src
                            &&
                            <img className="windowimgbox" src={selectedClinic.image_url} alt=""
                            ></img>

                        }
                        <div className="windowimggrad">
                            <div className="windowtitlebox">
                                <div className="windowtitlefull">
                                    {selectedClinic.name}
                                </div>
                            </div>

                        </div>
                    </div>
                    

                   

                   
                        
                        <div className="windowmainsetwidth">




                        








                        {
                        (selectedClinic.description !== "" || selectedClinic.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            
                            <div className="addflx">
                                <div className="windowmainheadingrv">
                                    About
                                </div>
                                <div className="viewmapbtnpurp"
                                // onClick={() => setIsWindowMapOpenCruises(true)}
                                onClick={() => setIsModalRv(true)}
                                >REVIEW</div>
                            </div>

                            <div className="windowmaintextboxstd">
                                {selectedClinic.description}
                            </div>
                          
                             {
                                (selectedClinic.extra_info !== "")
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedClinic.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedClinic.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>

                            }
                        </div>
                        }

                        

                        


                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                            Opening hours
                            </div>
                            <div className="openinghoursbox">
                            <div className="timetablebox">
                                <div className="">
                                    <div className="weekday">
                                        Monday
                                    </div>
                                    <div className="weekday">
                                        Tuesday
                                    </div>
                                    <div className="weekday">
                                        Wednesday
                                    </div>
                                    <div className="weekday">
                                        Thursday
                                    </div>
                                    <div className="weekday">
                                        Friday
                                    </div>
                                    <div className="weekday">
                                        Saturday
                                    </div>
                                    <div className="weekday">
                                        Sunday
                                    </div>
                                </div>
                                <div className="BCtimes">
                                    
                                    {
                                    selectedClinic.monday_open !== ""
                                    &&
                                    !(selectedClinic.monday_open === "12am" && selectedClinic.monday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.monday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.monday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.monday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.monday_open === "12am" && selectedClinic.monday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.tuesday_open !== ""
                                    &&
                                    !(selectedClinic.tuesday_open === "12am" && selectedClinic.tuesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.tuesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.tuesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.tuesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.tuesday_open === "12am" && selectedClinic.tuesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.wednesday_open !== ""
                                    &&
                                    !(selectedClinic.wednesday_open === "12am" && selectedClinic.wednesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.wednesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.wednesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.wednesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.wednesday_open === "12am" && selectedClinic.wednesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.thursday_open !== ""
                                    &&
                                    !(selectedClinic.thursday_open === "12am" && selectedClinic.thursday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.thursday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.thursday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.thursday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.thursday_open === "12am" && selectedClinic.thursday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.friday_open !== ""
                                    &&
                                    !(selectedClinic.friday_open === "12am" && selectedClinic.friday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.friday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.friday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.friday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.friday_open === "12am" && selectedClinic.friday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.saturday_open !== ""
                                    &&
                                    !(selectedClinic.saturday_open === "12am" && selectedClinic.saturday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.saturday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.saturday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.saturday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.saturday_open === "12am" && selectedClinic.saturday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedClinic.sunday_open !== ""
                                    &&
                                    !(selectedClinic.sunday_open === "12am" && selectedClinic.sunday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.sunday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedClinic.sunday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedClinic.sunday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedClinic.sunday_open === "12am" && selectedClinic.sunday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>

                        

                        {
                        selectedClinic.happy_hour !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Services
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedClinic.happy_hour}
                            </div>
                        </div>
                        }

                        {
                        selectedClinic.price_info !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Cost
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedClinic.price_info}
                            </div>
                        </div>
                        }



                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            <div className="windowmainvenuecardbox">
                                <div className="windowvenueleft">
                                {selectedClinic.suite_number === "" ? "" : <div>{selectedClinic.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {selectedClinic.street_number === "" ? <div>{selectedClinic.street_name}</div> : <div className="limitaddresstooneline">{selectedClinic.street_number}{" "}{selectedClinic.street_name}</div>}
                                        {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                    </div>
                                    {/* <div>{selectedSauna.suburb} {selectedSauna.postcode}</div> */}
                                    {selectedClinic.city !== "London" ? <div>{selectedClinic.suburb} {selectedClinic.postcode}</div> : <div><div>{selectedClinic.suburb}</div><div> {selectedClinic.postcode}</div></div>}
                                </div>
                                <div className="windowvenueright">
                                    <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenH(true)}>
                                        MAP
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="windowmainboxstd">
                            {
                            (selectedClinic.instagram !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.instagram.com/${selectedClinic.instagram}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Instagram</div>
                                    <div className="wmtxtb">@{selectedClinic.instagram}</div>
                                </div>
                                    
                                <div className="wmiconinsta"
                                
                                >
                                        <IonIcon icon={logoInstagram} className="insticn" />
                                </div>
                            </div>
                            }
                            {/* {
                                (selectedSauna?.facebook !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedSauna.facebook, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Facebook</div>
                                        <div className="wmtxtb">@{selectedSauna.facebook.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconfb"
                                    
                                    >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                    </div>
                                </div>
                            } */}
                            {
                            (selectedClinic.facebook !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.facebook.com/${selectedClinic.facebook}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Facebook</div>
                                    <div className="wmtxtb">{selectedClinic.facebook}</div>
                                </div>
                                    
                                <div className="wmiconfb"
                                
                                >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                </div>
                            </div>
                            }
                             {
                                (selectedClinic.website !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedClinic.website, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Website</div>
                                        <div className="wmtxtb">{selectedClinic.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconsc"
                                    // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson.youtube}`, '_system', 'location=yes')}
                                    
                                    >
                                        <IonIcon icon={globeOutline} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                            {
                            (selectedClinic.phone !== 0 && selectedClinic.phone !== "")
                            &&
                            <a className="aremove" href={`tel:+${selectedClinic.phone}`}>
                            <div className="wmcont">
                                <div className="wmflex">
                                    <div className="wmtxt">Phone</div>
                                    <div className="wmtxtb">+{selectedClinic.phone}</div>
                                </div>
                                
                                <div className="wmiconph">
                                    
                                    <IonIcon icon={callOutline} color="white" className="icoth" />
                                    
                                </div>
                                
                            </div>
                            </a>
                            }
                           
                        </div>

                       
                    
                    </div> 
           
                        

              
                    
                </div>                                 
            </IonContent>
            ) : (
                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                    <IonButtons
                    // slot="start"
                    >
                        <IonBackButton defaultHref="/" text="">
                        </IonBackButton>
                    </IonButtons>
                    </div>
                    <div className="innerwindowflex">
                        <div className="notfoundmsg">
                        This clinic has been removed. Please click the back button.
                        </div>
                        
                    </div>
                </div>
            )
        }
        </IonPage>
    )
}

export default HealthWindow;