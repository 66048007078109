import React, { useState, useEffect, useCallback, useRef } from 'react';

import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { Capacitor } from "@capacitor/core";

//ionic
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,

  IonSplitPane,
  
  IonHeader,
  IonIcon,

  IonToolbar,
  IonAlert,
  IonModal,
  
  IonToast,
 

  useIonPicker,
  IonContent,
  IonLoading,
  
} from '@ionic/react';

import { arrowForwardOutline, notificationsOutline, options, reorderThreeOutline, searchOutline } from 'ionicons/icons';

//firebase
import { database } from './lib/init-firebase';
import { onValue, ref, update, increment } from 'firebase/database';

//hooks (Ionic Storage, Network Status)
import useNetworkStatus from './hooks/useNetworkStatus'; // Import the custom hook
import { useStorage } from './hooks/useStorage';

import { isPlatform } from '@ionic/core';


// onesignal
import OneSignal from 'onesignal-cordova-plugin';

//Native-Market (to open app stores)
import { NativeMarket } from "@capacitor-community/native-market";



//components
import Menu from './components/Menu';

//pages
import LandingPage from './pages/LandingPage';
import Parties from './pages/Parties';
import PartyWindow from './pages/PartyWindow';
import PartiesFest from './pages/PartiesFest';
import CircFests from './pages/CircFests';
import CircWind from './pages/CircWind';
import BarsAndClubs from './pages/BarsAndClubs';
import Saunas from './pages/Saunas';
import Health from './pages/Health';
import BarAndClubWindow from './pages/BarAndClubWindow';
import SaunaWindow from './pages/SaunaWindow';
import HealthWindow from './pages/HealthWindow';
import People from './pages/People';
import PersonWindow from './pages/PersonWindow';
import DragTours from './pages/DragTours';
import DragTourWindow from './pages/DragTourWindow';
import Cruises from './pages/Cruises';
import CruiseWindow from './pages/CruiseWindow';

// images
import newlogo from './images/logomarktwo.png';
import logohq from './images/tgalogo_hq.png';
import defaultImage from './images/defaultImage.png';
import earthImage from './images/earthg.png';



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/splash.css';
import './theme/toolbars.css';



const appVersion = 14;


const SLOW_CONNECTION_THRESHOLD = 0.5 * 1024 * 1024; // 0.5 Mbps

// Define monthMap outside of the component or at the top level
const monthMap: { [key: string]: number } = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};


setupIonicReact();








const App: React.FC = () => {

  const { isConnected, showToast } = useNetworkStatus();

  const {
    storeIsLoaded,
    firstLoad,
    imagesAreLoaded,
    checkIonicStorageImagePointers,
    storedImages,
    checkExistingNotifsAndDetermineAction,
    viewedNotifications,
    setViewedNotifications,
    viewedNotificationsAreLoaded,
    defCity,
    setDefCity,
    defCityIsLoaded,
    citiesNotifs,
    setCitiesNotifs,
    likedParties,
    setLikedParties,
    likedDragExpos,
    setLikedDragExpos,
    likedBarsAndClubs,
    setLikedBarsAndClubs,
    likedPeople,
    setLikedPeople,
    likedCruises,
    setLikedCruises,
    likedCircFests,
    setLikedCircFests,
    isDownloading,
    downloadProgress,
  
  } = useStorage();


  

  const [present] = useIonPicker();

  const [isLanding, setIsLanding] = useState(false);


  const [width, setWidth] = useState(window.innerWidth);
  const [gridNo, setGridNo] = useState<any>(null);
  const [isWide, setIsWide] = useState(false);

  const [isWeb, setIsWeb] = useState(false);


  

  useEffect(() => {
    
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  },[]);

  useEffect(() => {
    if (width < 290) { setGridNo(0) } else
    if ((width >= 290) && (width < 320) ) { setGridNo(1) } else
    if ((width >= 320) && (width < 510) ) { setGridNo(2) } else
    if ((width >= 510) && (width < 690) ) { setGridNo(3) } else
    if ((width >= 690) && (width < 930)) { setGridNo(4) } else
    if ((width >= 930) && (width < 1200)) { setGridNo(5) } else
    if ((width >= 1200) && (width < 1300)) { setGridNo(4) } else
    if (width >= 1300) { setGridNo(5) }

    if (width < 1200 && isWide) setIsWide(false);
    if (width >= 1200 && !isWide) setIsWide(true);

  },[width]);

useEffect(() => {
  if (!isPlatform('hybrid')) {
    setIsWeb(true);
  }
}, [])



  const [openCitySelector, setOpenCitySelector] = useState(true);
  const [festDataIsLoaded, setFestDataIsLoaded] = useState(false);
  const [partyDataIsLoaded, setPartyDataIsLoaded] = useState(false);
  const [dragDataIsLoaded, setDragDataIsLoaded] = useState(false);
  const [venueDataIsLoaded, setVenueDataIsLoaded] = useState(false);
  const [cruiseDataIsLoaded, setCruiseDataIsLoaded] = useState(false);
  const [peopleDataIsLoaded, setPeopleDataIsLoaded] = useState(false);
  const [tagsDataIsLoaded, setTagsDataIsLoaded] = useState(false);
  const [notificationDataIsLoaded, setNotificationDataIsLoaded] = useState(false);
  const [appIsSuspended, setAppIsSuspended] = useState(false);
  const [versionIsCorrect, setVersionIsCorrect] = useState(true);
  const [cityList, setCityList] = useState<any>([]);
  const [cityListN, setCityListN] = useState<any>(null);
  const [cityCoordinates, setCityCoordinates] = useState<any>([]);
  const [cityZoom, setCityZoom] = useState<any>(null);
  const [citySelected, setCitySelected] = useState<any>(null);
  const [currentDateAndTime, setCurrentDateAndTime] = useState<any>(null);
  const [currentDateAndTimePlusTwoWeeks, setCurrentDateAndTimePlusTwoWeeks] = useState<any>(null);
  const [hawaiiTime, setHawaiiTime] = useState<any>(null);
  const [tempCity, setTempCity] = useState<any>("");
  const [rawCities, setRawCities] = useState<any>([]);
  const [rawNotifications, setRawNotifications] = useState<any>([]);
  const [rawImages, setRawImages] = useState<any>([]);
  const [rawParties, setRawParties] = useState<any>([]);
  const [rawDrag, setRawDrag] = useState<any>([]);
  const [rawVenues, setRawVenues] = useState<any>([]);
  const [rawCruises, setRawCruises] = useState<any>([]);
  const [rawPeople, setRawPeople] = useState<any>([]);
  const [rawFestivals, setRawFestivals] = useState<any>([]);
  const [rawTags, setRawTags] = useState<any>([]);
  const [versionServer, setVersionServer] = useState<any>(null);
  const [allParties, setAllParties] = useState<any>([]);
  const [parties, setParties] = useState<any>(null);
  const [partiesFeat, setPartiesFeat] = useState<any>([]);
  const [tagsParties, setTagsParties] = useState<any>([]);
  const [festivals, setFestivals] = useState<any>([]);
  const [festivalsPr, setFestivalsPr] = useState<any>([]);
  const [festivalsCirc, setFestivalsCirc] = useState<any>([]);
  const [circCountryList, setCircCountryList] = useState<any[]>([]);
  const [prideCountryList, setPrideCountryList] = useState<any[]>([]);
  const [venues, setVenues] = useState<any[]>([]);
  const [barsAndClubs, setBarsAndClubs] = useState<any[]>([]);
  const [tagsBC, setTagsBC] = useState<any>([]);
  const [saunas, setSaunas] = useState<any[]>([]);
  const [clinics, setClinics] = useState<any[]>([]);
  const [dragExpos, setDragExpos] = useState<any>(null);
  const [dragTours, setDragTours] = useState<any[]>([]);
  const [people, setPeople] = useState<any[]>([]);
  const [peopleOth, setPeopleOth] = useState<any[]>([]);
  // const [tvShows, setTvShows] = useState<any[]>([]);
  const [tagsPeople, setTagsPeople] = useState<any>([]);
  const [cruises, setCruises] = useState<any[]>([]);
  const [cruiseCountryList, setCruiseCountryList] = useState<any[]>([]);
  const [tagsCruises, setTagsCruises] = useState<any>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showSlowNetworkToast, setShowSlowNetworkToast] = useState(false);


  const openCapacitorSite = () => {
    let appIdent = '6472125056';
    if (Capacitor.getPlatform() === "android") {
      appIdent = "io.thegayagenda.app";
    }
    NativeMarket.openStoreListing({
        appId: appIdent,
    });
  };


// Call this function when your app starts
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE  
  // OneSignal.Debug.setLogLevel(6);
  
  // Uncomment to set OneSignal visual logging to VERBOSE  
  // OneSignal.Debug.setAlertLevel(6);

  // NOTE: Update the init value below with your OneSignal AppId.
  OneSignal.initialize("4d044987-8e3c-4667-970a-43d27180fb51");
  
  
  const myClickListener = async function(event:any) {
        const notificationData = JSON.stringify(event);
    };
  OneSignal.Notifications.addEventListener("click", myClickListener);
  

  // Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
    console.log("User accepted notifications: " + accepted);
  });
}

  // useEffect(() => {
  //   OneSignalInit();
  // },[]);



  const handleCityChange = (cityclicked: string) => {
    if (cityclicked === tempCity) return;
    setTempCity(cityclicked);
  };


  const changeTimeZone = (date: any, timeZone: any) => {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    } else {
      return new Date(
        date.toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  }

const changeCityLogic = (cityclicked:any) => {
            setFestDataIsLoaded(false);
            setPartyDataIsLoaded(false);
            setDragDataIsLoaded(false);
            setVenueDataIsLoaded(false);
            setCruiseDataIsLoaded(false);
            setPeopleDataIsLoaded(false);
            setTagsDataIsLoaded(false);
            setNotificationDataIsLoaded(false);

            const cityMatch = cityList.filter((ele:any) => ele.city === cityclicked)[0];
            const newArrayOfCities:any = cityList.filter((ele:any) => ele.city !== cityclicked);
            if (citySelected) {
              newArrayOfCities.push(citySelected);
            }
            newArrayOfCities.sort((a: any, b: any): number => {
              const cityA = a.city.toLowerCase();
              const cityB = b.city.toLowerCase();
            
              if (cityA < cityB) {
                return -1;
              }
              if (cityA > cityB) {
                return 1;
              }
              return 0;
            });
          
            const todaysDate = new Date();
            const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
            const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
            const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));
           
            setCityList(newArrayOfCities);
            setCitySelected(cityMatch);
            setCityCoordinates([cityMatch.lng, cityMatch.lat]);
            setCityZoom(cityMatch.zoom);
            setCurrentDateAndTime(changeDate);
            setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
            setHawaiiTime(hawTime);
}


  const openPickerSelectCity = async (history:any) => {
    const arrayOfCityOptions = [];
    for (let i = 0; i < cityList.length; i++) {
      const newObj = {
        text: cityList[i].city,
        value: cityList[i].city
      }
      arrayOfCityOptions.push(newObj);
    }

    present({
      columns: [
        {
          name: 'cities',
          options: arrayOfCityOptions
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (value) => {
            if (value.cities.value === citySelected.city) {
              return;
            }

            changeCityLogic(value.cities.value);
            history.push('/parties');
          },
        },
      ],
    });
  }


  const firstSetCity = (cityClicked:any) => {

    const cityMatch = cityList.filter((ele:any) => ele.city === cityClicked)[0];
    const newArrayOfCities:any = cityList.filter((ele:any) => ele.city !== cityClicked);
  
    newArrayOfCities.sort((a: any, b: any): number => {
      const cityA = a.city.toLowerCase();
      const cityB = b.city.toLowerCase();
    
      if (cityA < cityB) {
        return -1;
      }
      if (cityA > cityB) {
        return 1;
      }
      return 0;
    });
  

    const todaysDate = new Date();
    const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
    const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
    const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

    setDefCity(cityMatch.city);
    setCityList(newArrayOfCities);
    setCitySelected(cityMatch);
    setCityCoordinates([cityMatch.lng, cityMatch.lat]);
    setCityZoom(cityMatch.zoom);
    setCurrentDateAndTime(changeDate);
    setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
    setHawaiiTime(hawTime);
    setOpenCitySelector(false);
  }

  
  const handlePullRefresher = (TZParam:any) => {

    const todaysDate = new Date();
    const changeDate = changeTimeZone(todaysDate, TZParam);
    const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
    const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

    setCurrentDateAndTime(changeDate);
    setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
    setHawaiiTime(hawTime);
  }

  
  // const handleIncrement = (type:any, hash:any) => {
  //   const dbRef = ref(database);

  //   const updates:any = {};
  //   updates[`data/likes/${type}/${hash}/like_count`] = increment(1);
    
  //   update(dbRef, updates);
  // }

  // const handleDecrement = (type:any, hash:any) => {
  //   const dbRef = ref(database);

  //   const updates:any = {};
  //   updates[`data/likes/${type}/${hash}/like_count`] = increment(-1);
    
  //   update(dbRef, updates);
  // }

  const handleIncrement = useCallback((type: any, hash: any) => {
    const dbRef = ref(database);
    const updates: any = {};
    updates[`data/likes/${type}/${hash}/like_count`] = increment(1);
    update(dbRef, updates);
  }, []);
  
  const handleDecrement = useCallback((type: any, hash: any) => {
    const dbRef = ref(database);
    const updates: any = {};
    updates[`data/likes/${type}/${hash}/like_count`] = increment(-1);
    update(dbRef, updates);
  }, []);

  const handleIncrClick = (type:any, hash:any) => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/webclicks/${type}/${hash}/click_count`] = increment(1);
    
    update(dbRef, updates);
  }

  const handleClickUpdate = () => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/updates/fourteen/click_count`] = increment(1);
    update(dbRef, updates);
  }



  const addCitiesNotifs = (city: string) => {
    setCitiesNotifs((prevCities: string[]) => {
      const cityIndex = prevCities.indexOf(city);
      if (cityIndex !== -1) {
        // City exists, remove it
        // OneSignal.User.addTag(city, "false");
        return [...prevCities.slice(0, cityIndex), ...prevCities.slice(cityIndex + 1)];
      } else {
        // City doesn't exist, add it
        // OneSignal.User.addTag(city, "true");
        return [...prevCities, city];
      }
    });
  }



  const handleToggleCity = (event: CustomEvent) => {
    addCitiesNotifs(event.detail.value);
  };


  const checkNetworkSpeed = async () => {
    try {
      const startTime = Date.now();
      await fetch('https://www.google.com/favicon.ico', {
        mode: 'no-cors'
      });
  
      const endTime = Date.now();
      const durationInSeconds = (endTime - startTime) / 1000;
  
      // Since mode is 'no-cors', the response body is opaque and we can't access its content,
      // but we can still use the duration to measure network speed
      const estimatedFileSizeInBits = 700 * 8; // Estimate the size of the favicon (700 bytes)
  
      // bits per second
      const speed = estimatedFileSizeInBits / durationInSeconds;
  
      return speed;
    } catch (error) {
      // console.error('Error checking network speed:', error);
      return 0; // Returning 0 or an appropriate value in case of error
    }
  };








useEffect(() => {
  if (isConnected) {

    const suspendQuery = ref(database, '/production/app/suspend');
    const cityQuery = ref(database, '/production/cities');
    const notificationQuery = ref(database, '/production/notifications');
    const imageQuery = ref(database, '/production/images');
    const partyQuery = ref(database, '/production/events');
    const dragQuery = ref(database, '/production/drag');
    const venueQuery = ref(database, '/production/venues/online');
    const cruiseQuery = ref(database, '/production/cruises');
    const peopleQuery = ref(database, '/production/people');
    const festivalQuery = ref(database, '/production/festivals');
    const tagQuery = ref(database, '/production/tags');
    const versionQuery = ref(database, '/production/app/fourteen');

    const suspendUnsubscribe = onValue(suspendQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setAppIsSuspended(data);
    });

    const cityUnsubscribe = onValue(cityQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawCities(Object.values(data));
    });

    const notificationUnsubscribe = onValue(notificationQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawNotifications(Object.values(data));
    });

    const imageUnsubscribe = onValue(imageQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawImages(Object.values(data));
    });

    const partyUnsubscribe = onValue(partyQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawParties(Object.values(data));
    });

    const dragUnsubscribe = onValue(dragQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawDrag(Object.values(data));
    });

    const venueUnsubscribe = onValue(venueQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawVenues(Object.values(data));
    });

    const cruiseUnsubscribe = onValue(cruiseQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawCruises(Object.values(data));
    });

    const peopleUnsubscribe = onValue(peopleQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawPeople(Object.values(data));
    });

    const festivalUnsubscribe = onValue(festivalQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawFestivals(Object.values(data));
    });

    const tagUnsubscribe = onValue(tagQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawTags(Object.values(data));
    });

    const versionUnsubscribe = onValue(versionQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) {
        setVersionServer(data);
      } else if (data === undefined) {
        setVersionServer(1);
      }
    });

    return () => {
      suspendUnsubscribe();
      cityUnsubscribe();
      notificationUnsubscribe();
      imageUnsubscribe();
      partyUnsubscribe();
      dragUnsubscribe();
      venueUnsubscribe();
      cruiseUnsubscribe();
      peopleUnsubscribe();
      festivalUnsubscribe();
      tagUnsubscribe();
      versionUnsubscribe();
    };
  }
}, [isConnected]);


  useEffect(() => {
    if (firstLoad) {
      const intervalId = setInterval(async () => {
        const networkSpeed = await checkNetworkSpeed();
        if (networkSpeed < SLOW_CONNECTION_THRESHOLD) {
          setShowSlowNetworkToast(true);
          setTimeout(() => setShowSlowNetworkToast(false), 3000);
        }
      }, 5000); // Check every 5 seconds

      return () => clearInterval(intervalId);
    }
  }, [firstLoad]);


useEffect(() => {
  if (versionServer) {
      if (versionServer !== appVersion) {
        setVersionIsCorrect(false);
      } else if (versionServer === appVersion
        ) {
        setVersionIsCorrect(true);
      }
  }
}, [versionServer]);



  useEffect(() => {

    if (!storeIsLoaded || !rawCities.length || !defCityIsLoaded
      // ||
      // !rawParties.length
      // ||
      // !rawFestivals.length
      // ||
      // !rawVenues
      // ||
      // !rawPeople
      // ||
      // !rawDrag
    ) {
      return;
    }
    
      const dataArrayCities: any = [];

      for (let i = 0; i < rawCities.length; i++) {
        if (
          (rawCities[i].display)
          ) {
            dataArrayCities.push(rawCities[i]);
        }
      }

      dataArrayCities.sort((a: any, b: any): number => {
        const cityA = a.city.toLowerCase();
        const cityB = b.city.toLowerCase();
      
        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });

      const cityNames = [];
      for (let i = 0; i < dataArrayCities.length; i++) {
        cityNames.push(dataArrayCities[i].city)
      }

      setCityListN(cityNames);
      checkExistingNotifsAndDetermineAction(cityNames);
      
      if (defCity === "") {
        
          setCityList(dataArrayCities);
        
        return;
      }
      
      if (openCitySelector) {

        let cityMatch;
        let newArrayOfCities;

        const currentPath = window.location.pathname;
        const regex = /\/(parties|parties-fest|barsandclubs|saunas|people|drag)\/([-a-zA-Z0-9]+)/;
        const match = currentPath.match(regex);

        console.log('bb 1', currentPath, match);

        // Check if the path has a valid category and ID
        //below is for reload where you are on specfic page vs just reloading on the main parties or people etc page
        if (match) {
          const category = match[1]; // e.g., 'parties', 'barsandclubs', etc.
          const id = match[2]; // The ID, e.g., '-O4LPb75fgN8qJWWAJBy'

          let foundObject:any = null;

          // Determine which array to search based on the category
          switch (category) {
            case 'parties':
              foundObject = rawParties.find((item:any) => item.id === id);
              break;
            case 'parties-fest':
              foundObject = rawFestivals.find((item:any) => item.festparam === id);
              break;
            case 'barsandclubs':
              foundObject = rawVenues.find((item:any) => item.id === id);
              break;
            case 'saunas':
              foundObject = rawVenues.find((item:any) => item.id === id);
              break;
            case 'clinics':
              foundObject = rawVenues.find((item:any) => item.id === id);
              break;
            case 'people':
              foundObject = rawPeople.find((item:any) => item.id === id);
              break;
              //above wont currently work because people object doesnt have city property.
            case 'drag':
              foundObject = rawDrag.find((item:any) => item.id === id);
              break;
            default:
              break;
          }

          console.log('bb 2', category, id, foundObject);

          // If an object is found, set the current city to the object's city property
          if (foundObject && foundObject.city) {
            cityMatch = dataArrayCities.filter((ele:any) => ele.city === foundObject.city)[0];
            newArrayOfCities = dataArrayCities.filter((ele:any) => ele.city !== foundObject.city);
          } else {
            cityMatch = dataArrayCities.filter((ele:any) => ele.city === defCity)[0];
            newArrayOfCities = dataArrayCities.filter((ele:any) => ele.city !== defCity);
          }
        } else {
          cityMatch = dataArrayCities.filter((ele:any) => ele.city === defCity)[0];
          newArrayOfCities = dataArrayCities.filter((ele:any) => ele.city !== defCity);
        }
        // const cityMatch = dataArrayCities.filter((ele:any) => ele.city === defCity)[0];
        console.log(cityMatch, defCity, 'bb3');
        // const newArrayOfCities:any = dataArrayCities.filter((ele:any) => ele.city !== defCity);
        
        newArrayOfCities.sort((a: any, b: any):any => {
                a = a.city;
                b = b.city;
                return a - b
              });
    
        const todaysDate = new Date();
        const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
        const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
        const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

        setCityList(newArrayOfCities);
        setCitySelected(cityMatch);
        setCityCoordinates([cityMatch.lng, cityMatch.lat]);
        setCityZoom(cityMatch.zoom);
        setCurrentDateAndTime(changeDate);
        setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
        setHawaiiTime(hawTime);
        setOpenCitySelector(false);
      } else {
        const cityMatch = dataArrayCities.filter((ele:any) => ele.city === citySelected.city)[0];
        const newArrayOfCities:any = dataArrayCities.filter((ele:any) => ele.city !== citySelected.city);

        newArrayOfCities.sort((a: any, b: any):any => {
                a = a.city;
                b = b.city;
                return a - b
              });
       
        const todaysDate = new Date();       
        const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
        const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
        const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));
    
        setCityList(newArrayOfCities);
        setCitySelected(cityMatch);
        setCityCoordinates([cityMatch.lng, cityMatch.lat]);
        setCityZoom(cityMatch.zoom);
        setCurrentDateAndTime(changeDate);
        setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
        setHawaiiTime(hawTime);
      }
    
    
  }, [
    storeIsLoaded,
    rawCities,
    defCityIsLoaded,

    // rawParties,
    // rawFestivals,
    // rawVenues,
    // rawPeople,
    // rawDrag
  ]
  );

  useEffect(() => {
    if (!storeIsLoaded || !rawImages.length) {
      return;
    }

    const dataArrayImages: any = [];

      for (let i = 0; i < rawImages.length; i++) {
        if (
          rawImages[i].display
          ) {
            dataArrayImages.push(rawImages[i]);
        }
      }

      checkIonicStorageImagePointers(dataArrayImages);

  }, [
    storeIsLoaded,
    rawImages,
  ]);



useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !currentDateAndTime || !rawParties.length) {
    return;
  }

  console.log('PARTIES_PROPS parties use effect ran.')

      const dataArrayParties = rawParties.filter((party: any) => 
        party.city === citySelected.city && party.display
      );

      const partiesWithFinishDate = dataArrayParties.map((party: any) => {
        
        const datemonth = monthMap[party.finish_month];
        const newHourFinish = Math.floor(party.timeorderfinish / 100);
        const newMinutesFinish = party.timeorderfinish % 100;
        const daterealfinish = new Date(
          +party.finish_year,
          datemonth,
          +party.finish_date,
          newHourFinish,
          newMinutesFinish
        );
      
        return { ...party, daterealfinish };
      });
      

      const upcomingParties = partiesWithFinishDate.filter(
        (party: any) => party.daterealfinish > currentDateAndTime
      );

      const partiesWithStartDate = upcomingParties.map((party: any) => {
      
        const datemonth = monthMap[party.start_month];
        const newHourStart = Math.floor(party.timeorder / 100);
        const newMinutesStart = party.timeorder % 100;
        const daterealstart = new Date(
          +party.start_year,
          datemonth,
          +party.start_date,
          newHourStart,
          newMinutesStart
        );
      
        return { ...party, daterealstart };
      });
      

      const partiesWithTags = partiesWithStartDate.map((party: any) => {
        if (party.tags_array) return party; // If tags_array already exists, skip
        console.log('attention. at least one event without a tags array.');
        const tagsArray = [];
      
        // Add tags based on conditions
        if (party.tickets_available) tagsArray.push('Tickets left');
        if (!party.template_id) tagsArray.push('Special');
        if (party.price === 0) tagsArray.push('Free');
        if (party.price < 100) tagsArray.push('Under $100');
        if (party.live_music) tagsArray.push('Live music');
        if (party.boat_party) tagsArray.push('Boat party');
        if (party.pool_party) tagsArray.push('Pool party');
        if (party.beach_party) tagsArray.push('Beach party');
        if (party.drag) tagsArray.push('Drag shows');
        if (party.trivia_or_games) tagsArray.push('Trivia/games');
        if (party.karaoke) tagsArray.push('Karaoke');
        if (party.underwear_party) tagsArray.push('Underwear party');
        if (party.fetish_party) tagsArray.push('Fetish party');
        if (party.bears_party) tagsArray.push('Bears party');
        if (party.trans_party) tagsArray.push('Trans party');
        if (party.non_binary) tagsArray.push('Non-binary');
        if (party.lesbian_party) tagsArray.push('Women');
        if (party.watch_party) tagsArray.push('Screening');
        if (party.festival_viewing_party) tagsArray.push('Parade');
        if (party.brunch) tagsArray.push('Brunch');
        if (party.lunch) tagsArray.push('Lunch');
        if (party.dinner) tagsArray.push('Dinner');
        if (!tagsArray.includes(party.city)) tagsArray.push(party.city);
      
        return { ...party, tags_array: tagsArray };
      });

      const sortedParties = [...partiesWithTags].sort((a: any, b: any) => {
        if (a.daterealstart.getTime() !== b.daterealstart.getTime()) {
          return a.daterealstart.getTime() - b.daterealstart.getTime();
        }
        return a.daterealfinish.getTime() - b.daterealfinish.getTime();
      });

   

        const partiesWithTimeString = sortedParties.map((party: any) => {
          if (party.timestring) return party; // If timestring already exists, skip
          console.log('attention. at least one event without a timestring.');
          let timestring;
          if (party.start_time.slice(-2) !== party.finish_time.slice(-2)) {
            timestring = `${party.start_time} - ${party.finish_time}`;
          } else {
            timestring = `${party.start_time.slice(0, -2)} - ${party.finish_time}`;
          }
        
          return { ...party, timestring };
        });



      const imageMap = new Map<string, any>();
      storedImages.forEach((image: any) => {
        imageMap.set(image.name, image.useableFilePath);
      });

      const partiesWithImages = partiesWithTimeString.map((party: any) => {
        const image_src = imageMap.get(party.image_name) ?? defaultImage;
        let image_src_feat = undefined;
        if (party.image_name_feat) {
          image_src_feat = imageMap.get(party.image_name_feat) ?? defaultImage;
        }

        return { ...party, image_src, image_src_feat };
      });


    

      const finalParties = partiesWithImages.map((party: any) => {
        let festparam = party.festparam;
        // if (!festparam) {
        if (festparam === null || festparam === undefined) {
          console.log('attention. at least one event without a festparam.');
          if (!party.festival) {
            festparam = '';
          } else {
            // festparam = party.festival.toLowerCase().replace(/\s+/g, '');
            festparam = party.festival.toLowerCase().split(' ').join('');
          }
        }
        return { ...party, festparam };
      });
      
    

      setParties(finalParties);
      setAllParties(rawParties.filter((party: any) => party.display));


      const partiesFeat = finalParties.filter(
        (party: any) => party.featured && party.daterealfinish > currentDateAndTime
      );

      setPartiesFeat(partiesFeat);
      setPartyDataIsLoaded(true);


     

    

}, [storeIsLoaded, imagesAreLoaded, citySelected, currentDateAndTime, rawParties, storedImages])



useEffect(() => {
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !currentDateAndTime || !rawDrag.length) {
    return;
  }

     

        // **1. Filter Drag Expos by City and Display**
        const dataArrayDragExpos = rawDrag.filter(
          (drag: any) => drag.city === citySelected.city && drag.display
        );

    
      // **2. Add `daterealfinish` to Each Drag Expo**
      const dragExposWithFinishDate = dataArrayDragExpos.map((drag: any) => {
        const datemonth = monthMap[drag.finish_month];
        const newHourFinish = Math.floor(drag.timeorderfinish / 100);
        const newMinutesFinish = drag.timeorderfinish % 100;
        const daterealfinish = new Date(
          +drag.finish_year,
          datemonth,
          +drag.finish_date,
          newHourFinish,
          newMinutesFinish
        );
        return { ...drag, daterealfinish };
      });
   


        // **3. Filter Upcoming Drag Expos**
        const upcomingDragExpos = dragExposWithFinishDate.filter(
          (drag: any) => drag.daterealfinish > currentDateAndTime
        );


    

      // **4. Add `daterealstart` to Each Drag Expo**
      const dragExposWithStartDate = upcomingDragExpos.map((drag: any) => {
        const datemonth = monthMap[drag.start_month];
        const newHourStart = Math.floor(drag.timeorder / 100);
        const newMinutesStart = drag.timeorder % 100;
        const daterealstart = new Date(
          +drag.start_year,
          datemonth,
          +drag.start_date,
          newHourStart,
          newMinutesStart
        );
        return { ...drag, daterealstart };
      });

   

      // **5. Sort Drag Expos**
      const sortedDragExpos = [...dragExposWithStartDate].sort((a: any, b: any) => {
        if (a.daterealstart.getTime() !== b.daterealstart.getTime()) {
          return a.daterealstart.getTime() - b.daterealstart.getTime();
        }
        return a.daterealfinish.getTime() - b.daterealfinish.getTime();
      });

      

      // **6. Add `image_src` to Each Drag Expo**
      const imageMap = new Map<string, any>();
      storedImages.forEach((image: any) => {
        imageMap.set(image.name, image.useableFilePath);
      });

      const dragExposWithImages = sortedDragExpos.map((drag: any) => {
        const image_src = imageMap.get(drag.image_name) ?? defaultImage;
        return { ...drag, image_src };
      });



      // **7. Add `datestring` to Each Drag Expo**
      const dragExposWithDateString = dragExposWithImages.map((drag: any) => {
        if (drag.datestring) return drag; // If datestring already exists, skip
        console.log('attention. at least one drag event without a datestring.');
        let dateString;
        if (drag.start_date === drag.finish_date && drag.start_month === drag.finish_month) {
          dateString = `${drag.start_date} ${drag.start_month}`;
        } else if (drag.start_month === drag.finish_month) {
          dateString = `${drag.start_date} - ${drag.finish_date} ${drag.start_month}`;
        } else {
          dateString = `${drag.start_date} ${drag.start_month.slice(
            0,
            3
          )} - ${drag.finish_date} ${drag.finish_month.slice(0, 3)}`;
        }
        return { ...drag, datestring: dateString };
      });

   

      // **8. Filter Drag Tours**
      const dataArrayDragTours = dragExposWithDateString.filter(
        (drag: any) => drag.type === 'Tour'
      );

      // **9. Update State**
      setDragExpos(dragExposWithDateString);
      setDragTours(dataArrayDragTours);
      setDragDataIsLoaded(true);
    

}, [storeIsLoaded, imagesAreLoaded, citySelected, currentDateAndTime, rawDrag, storedImages])


useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !rawVenues.length) {
    return;
  }


      // **1. Create an Image Map for Quick Lookup**
        const imageMap = new Map<string, any>();
        storedImages.forEach((image: any) => {
          imageMap.set(image.name, image.useableFilePath);
        });

  
     // **2. Define a Function to Generate Tags for a Venue**
      const getTagsForVenue = (venue: any) => {
        // If tags_array already exists, return it
        if (venue.tags_array) {
          return venue.tags_array;
        }
        console.log('attention. at least one venue without a tags array.');
        // Otherwise, generate the tags
        const tagsArray = [];
        if (venue.monday_open !== "") tagsArray.push('Monday');
        if (venue.tuesday_open !== "") tagsArray.push('Tuesday');
        if (venue.wednesday_open !== "") tagsArray.push('Wednesday');
        if (venue.thursday_open !== "") tagsArray.push('Thursday');
        if (venue.friday_open !== "") tagsArray.push('Friday');
        if (venue.saturday_open !== "") tagsArray.push('Saturday');
        if (venue.sunday_open !== "") tagsArray.push('Sunday');
        if (venue.rooftop_or_balcony) tagsArray.push('Rooftop / balcony');
        if (venue.terrace_or_beergarden) tagsArray.push('Terrace / beer garden');
        if (venue.drag_shows) tagsArray.push('Drag shows');
        if (venue.late) tagsArray.push('Late night');
        if (venue.lesbian) tagsArray.push('Women');
        if (venue.gogo_dancers) tagsArray.push('Gogo dancers');
        return tagsArray;
      };

     
       // **3. Process Bars and Clubs**
      const dataArrayBC = rawVenues
      .filter(
        (venue:any) =>
          venue.city === citySelected.city &&
          venue.type === 'barorclub' &&
          venue.display
      )
      .map((venue:any) => {
        const tags_array = getTagsForVenue(venue);
        const image_src = imageMap.get(venue.image_name) ?? defaultImage;
        return { ...venue, tags_array, image_src };
      });

    // **4. Sort Bars and Clubs Alphabetically**
      const sortedBarsAndClubs = [...dataArrayBC].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setBarsAndClubs(sortedBarsAndClubs);

   

      // **5. Process Saunas**
      const dataArraySau = rawVenues
      .filter(
        (venue: any) =>
          venue.city === citySelected.city &&
          (venue.type === 'sauna' || venue.type === 'gym' || venue.type === 'gymsauna') &&
          venue.display
      )
      .map((venue: any) => {
        const image_src = imageMap.get(venue.image_name) ?? defaultImage;
      
        // Add a new property 'type_name' based on the original 'type'
        let type_name;
        switch (venue.type) {
          case 'sauna':
            type_name = 'Sauna';
            break;
          case 'gym':
            type_name = 'Gym';
            break;
          case 'gymsauna':
            type_name = 'Gym & Sauna';
            break;
          default:
            type_name = venue.type; // Default case if new types are added
        }
      
        return { ...venue, type_name, image_src };
      });

      // **6. Sort Saunas Alphabetically**
      const sortedSaunas = [...dataArraySau].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setSaunas(sortedSaunas);

      

      //  **7. Process Clinics**
       const dataArrayClinics = rawVenues
       .filter(
         (venue:any) =>
           venue.city === citySelected.city &&
           venue.type === 'clinic' &&
           venue.display
       )
       .map((venue:any) => {
         const image_src = imageMap.get(venue.image_name) ?? defaultImage;
         return { ...venue, image_src };
       });

     const sortedClinics = [...dataArrayClinics].sort((a, b) =>
       a.name.localeCompare(b.name)
     );

     setClinics(sortedClinics);

     

     // **x. Combine All Venues and Sort Alphabetically**
      const allVenues = [...sortedBarsAndClubs, ...sortedSaunas, ...sortedClinics];
      // const allVenues = [...sortedBarsAndClubs, ...sortedSaunas];
      const sortedVenues = [...allVenues].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setVenues(sortedVenues);
      setVenueDataIsLoaded(true);



  }, [
  storeIsLoaded,
  imagesAreLoaded,
  citySelected,
  rawVenues,
  storedImages
]);





useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected
    || !parties
    || !dragExpos
    || !rawPeople.length
    || !rawTags.length
    || !festivalsCirc || !festivalsPr
    || !cruises
  ) {
    return;
  }
  
 

  // **1. Create an Image Map for Quick Lookup**
  const imageMap = new Map<string, any>();
  storedImages.forEach((image: any) => {
    if (image.name === 'biminialt') console.log('thisthis2', image)
    imageMap.set(image.name, image.useableFilePath);
  });

  // **2. Filter Parties and Drag Expos with Entertainers**
  const partiesWithEnt = parties.filter(
    (party: any) => party.entertainers && Array.isArray(party.entertainers) && party.entertainers.length > 0
  );
  const dragWithEnt = dragExpos.filter(
    (drag: any) => drag.entertainers && Array.isArray(drag.entertainers) && drag.entertainers.length > 0
  );
  const festWithEnt = festivalsCirc.concat(festivalsPr).filter(
    (fest: any) => fest.entertainers && Array.isArray(fest.entertainers) && fest.entertainers.length > 0
  );
  const cruiseWithEnt = cruises.filter(
    (cruise: any) => cruise.entertainers && Array.isArray(cruise.entertainers) && cruise.entertainers.length > 0
  );

    

        // **3. Build dataArrayPeople with Global Property**
      const dataArrayPeople = rawPeople.filter((person: any) => {
        const inParty = partiesWithEnt.some((party: any) =>
          party.entertainers.includes(person.name)
        );
        const inDrag = dragWithEnt.some((drag: any) =>
          drag.entertainers.includes(person.name)
        );
        const inFest = festWithEnt.some((fest: any) =>
          fest.entertainers.includes(person.name)
        );
        const inCruise = cruiseWithEnt.some((cruise: any) =>
          cruise.entertainers.includes(person.name)
        );
        const forced = person.force === citySelected.city;

        // Determine if person should be marked as global
        // person.global = (inFest || inCruise) && !inParty && !inDrag;
        // is it okay to set parameters with the filter method?!
        // person.events = inParty;
        // person.drag = inDrag;
        // person.local = (inParty || inDrag)
        person.festivals = inFest;
        person.cruises = inCruise;

        return person.display && (inParty || inDrag || inFest || inCruise || forced);
      });

   

     // **4. Ensure tv_shows is an Array**
      const dataArrayPeopleWithTvShows = dataArrayPeople.map((person: any) => {
        const tv_shows = person.tv_shows === '' ? [] : person.tv_shows;
        return { ...person, tv_shows };
      });

      // 5.
      const dataArrayPeopleWithTags = dataArrayPeopleWithTvShows.map((person: any) => {
        const tags_array = person.tags_array === "" ? [] : person.tags_array;
        // if (!person.global) tags_array.push('Local');
        // if (person.events) tags_array.push('Events');
        // if (person.drag) tags_array.push('Drag');
        // if (person.local) tags_array.push('Local events');
        // tags_array.push(person.festivals ? 'Festivals' : 'No Festivals');
        // tags_array.push(person.cruises ? 'Cruises' : 'No Cruises');
        if (!person.festivals) tags_array.push('No Festivals');
        if (!person.cruises) tags_array.push('No Cruises');
        //logic for above can be improved and perhaps taken to backend
        return { ...person, tags_array };
      });

  
    
      // **5. Generate Tags for Each Person or Use Existing tags_array**
   
    

      // **6. Sort People by Name**
      const sortedDataArrayPeople = [...dataArrayPeopleWithTags].sort(
        (a: any, b: any): number => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
      );


    

      // **7. Add image_src to Each Person**
      const dataArrayPeopleWithImages = sortedDataArrayPeople.map((person: any) => {
        if (person.name === 'Bimini') {
          console.log('thisthis', imageMap.get(person.image_name))
        }
        const image_src = imageMap.get(person.image_name) ?? defaultImage;
        return { ...person, image_src };
      });
  
    

      // **8. Update State with Processed People Data**
      setPeople(dataArrayPeopleWithImages);

    

      // **9. Create otherPres Sorted by Type**
      const otherPres = [...dataArrayPeopleWithImages].sort(
        (a: any, b: any): number => {
          if (a.type[0] < b.type[0]) return 1;
          if (a.type[0] > b.type[0]) return -1;
          return 0;
        }
      );
      setPeopleOth(otherPres);

     

      // **10. Indicate Data Loading is Complete**
      setPeopleDataIsLoaded(true);
    
  }, [
   
  storeIsLoaded,
  imagesAreLoaded,
  citySelected,
  rawPeople,
  storedImages,
  parties,
  dragExpos,
  festivalsCirc,
  festivalsPr,
  cruises,
 
 
]);



useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !hawaiiTime || !citySelected || !rawCruises.length) {
    return;
  }

  //1 was create month map which is now defined outside component

  // **2. Create an Image Map for Quick Lookup**
  const imageMap = new Map();
  storedImages.forEach((image) => {
    imageMap.set(image.name, image.useableFilePath);
  });



  // **3. Filter Cruises that are Displayed**
  const dataArrayCruises = rawCruises.filter((cruise:any) => cruise.display);

           



                // **4. Add `daterealstart` to Each Cruise**
                const cruisesWithStartDate = dataArrayCruises.map((cruise:any) => {
                  const monthIndex = monthMap[cruise.start_month];
                  const timeString = cruise.timeorder.toString().padStart(4, '0');
                  const hours = parseInt(timeString.slice(0, -2), 10);
                  const minutes = parseInt(timeString.slice(-2), 10);
                  const daterealstart = new Date(
                    parseInt(cruise.start_year, 10),
                    monthIndex,
                    parseInt(cruise.start_date, 10),
                    hours,
                    minutes
                  );
                  return { ...cruise, daterealstart };
                });

                // **5. Filter Cruises Starting After `hawaiiTime`**
                const upcomingCruises = cruisesWithStartDate.filter(
                  (cruise:any) => cruise.daterealstart > hawaiiTime
                );


            

                // **6. Add `daterealfinish` to Each Cruise**
                const cruisesWithFinishDate = upcomingCruises.map((cruise:any) => {
                  const monthIndex = monthMap[cruise.finish_month];
                  const timeString = cruise.timeorderfinish.toString().padStart(4, '0');
                  const hours = parseInt(timeString.slice(0, -2), 10);
                  const minutes = parseInt(timeString.slice(-2), 10);
                  const daterealfinish = new Date(
                    parseInt(cruise.finish_year, 10),
                    monthIndex,
                    parseInt(cruise.finish_date, 10),
                    hours,
                    minutes
                  );
                  return { ...cruise, daterealfinish };
                });


                // **7. Generate Tags for Each Cruise**
                const cruisesWithTags = cruisesWithFinishDate.map((cruise:any) => {
                  // Use existing tags_array if it exists
                  const tags_array = cruise.tags_array || [];

                  // Generate tags if tags_array doesn't exist
                  if (!cruise.tags_array) {
                    console.log('attention. at least one cruise without a tags array.');
                    if (cruise.tickets_available) tags_array.push('Tickets left');
                    if (cruise.open_bar) tags_array.push('Open bar');
                    if (cruise.pool) tags_array.push('Pool');
                    if (cruise.lesbian) tags_array.push('Women');

                    cruise.other_destinations.forEach((destination:any) => {
                      if (!tags_array.includes(destination[1])) {
                        tags_array.push(destination[1]);
                      }
                    });
                  }

                  return { ...cruise, tags_array };
                });
            
             

                // **8. Sort Cruises by `daterealstart`**
                const sortedCruises = [...cruisesWithTags].sort(
                  (a, b) => a.daterealstart - b.daterealstart
                );

            

              // **9. Add `image_src` to Each Cruise**
              const cruisesWithImages = sortedCruises.map((cruise) => {
                const image_src = imageMap.get(cruise.image_name) ?? defaultImage;
                return { ...cruise, image_src };
              });


              // **10. Add `datestring` to Each Cruise**
              const cruisesWithDateString = cruisesWithImages.map((cruise) => {
                if (cruise.datestring) return cruise; // If timestring already exists, skip
                console.log('attention. at least one cruise without a datestring.');
                let dateString;
                if (
                  cruise.start_date === cruise.finish_date &&
                  cruise.start_month === cruise.finish_month
                ) {
                  dateString = `${cruise.start_date} ${cruise.start_month}`;
                } else if (cruise.start_month === cruise.finish_month) {
                  dateString = `${cruise.start_date} - ${cruise.finish_date} ${cruise.start_month.slice(0, 3)}`;
                } else {
                  dateString = `${cruise.start_date} ${cruise.start_month.slice(0, 3)} - ${cruise.finish_date} ${cruise.finish_month.slice(0, 3)}`;
                }
                return { ...cruise, datestring: dateString };
              });

            

              // **11. Compile List of Countries**
              const cruiseCountrySet = new Set();
              cruisesWithDateString.forEach((cruise) => {
                cruise.other_destinations.forEach((destination:any) => {
                  cruiseCountrySet.add(destination[1]);
                });
              });
              const cruiseCountryList = Array.from(cruiseCountrySet);
              setCruiseCountryList(cruiseCountryList);

    

      // **12. Add `geojson_data` to Each Cruise**
          const cruisesWithGeoJSON = cruisesWithDateString.map((cruise) => {
            const geojson_data = {
              features: cruise.other_destinations.map((destination:any, index:number) => ({
                type: 'Feature',
                properties: {
                  title: cruise.name,
                  description: index + 1,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [destination[3], destination[4]],
                },
              })),
            };
            return { ...cruise, geojson_data };
          });

          // **13. Update State**
          setCruises(cruisesWithGeoJSON);
          setCruiseDataIsLoaded(true);
   
  }, [
  storeIsLoaded,
  imagesAreLoaded,
  hawaiiTime,
  citySelected,
 rawCruises,
 storedImages
]);




useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !hawaiiTime || !citySelected || !rawFestivals.length) {
    return;
  }

  // 1. was month mapping

  // **2. Create an Image Map for Quick Lookup**
  const imageMap = new Map();
  storedImages.forEach((image) => {
    imageMap.set(image.name, image.useableFilePath);
  });
  
 
      // **3. Filter Festivals that are Displayed**
    const displayedFestivals = rawFestivals.filter((festival:any) => festival.display);

      
        // **4. Add `daterealfinish` to Each Festival**
        const festivalsWithFinishDate = displayedFestivals.map((festival:any) => {
          const monthIndex = monthMap[festival.finish_month];
          const daterealfinish = new Date(
            parseInt(festival.finish_year, 10),
            monthIndex,
            parseInt(festival.finish_date, 10)
          );
          return { ...festival, daterealfinish };
        });

        // **5. Filter Festivals Finishing After `hawaiiTime`**
        const upcomingFestivals = festivalsWithFinishDate.filter(
          (festival:any) => festival.daterealfinish > hawaiiTime
        );

       

        // **6. Add `daterealstart` to Each Festival**
        const festivalsWithStartDate = upcomingFestivals.map((festival:any) => {
          const monthIndex = monthMap[festival.start_month];
          const daterealstart = new Date(
            parseInt(festival.start_year, 10),
            monthIndex,
            parseInt(festival.start_date, 10)
          );
          return { ...festival, daterealstart };
        });

     

        // **7. Add `datestring` to Each Festival**
        const festivalsWithDateString = festivalsWithStartDate.map((festival: any) => {
          let dateString = festival.datestring; // Check if datestring already exists
          
          // If datestring is not already set, generate it
          if (!dateString) {
            console.log('attention. at least one festival without a datestring.');
            if (
              festival.start_date === festival.finish_date &&
              festival.start_month === festival.finish_month
            ) {
              dateString = `${festival.start_date} ${festival.start_month}`;
            } else if (festival.start_month === festival.finish_month) {
              dateString = `${festival.start_date} - ${festival.finish_date} ${festival.start_month}`;
            } else {
              dateString = `${festival.start_date} ${festival.start_month.slice(
                0,
                3
              )} - ${festival.finish_date} ${festival.finish_month.slice(0, 3)}`;
            }
          }
        
          // Return the updated festival object, either with existing or newly generated datestring
          return { ...festival, datestring: dateString };
        });

       

        // **8. Add `festparam` to Each Festival**
        const festivalsWithParam = festivalsWithDateString.map((festival: any) => {
          // Check if festparam already exists, otherwise generate a new one
          // const festparam = festival.festparam ?? festival.name.toLowerCase().split(' ').join('');
          if (!festival.festparam) console.log("attention. at least one festival without a festparam.")
          const festparam = festival.festparam || festival.name.toLowerCase().split(' ').join('');
          return { ...festival, festparam };
        });

       

        // **9. Add `image_src` to Each Festival**
        const festivalsWithImages = festivalsWithParam.map((festival:any) => {
          let image_src = festival.image_src;
          if (festival.type === 'Circuit' || festival.type === 'Pride') {
            image_src = imageMap.get(festival.image_name) ?? defaultImage;
          }
          return { ...festival, image_src };
        });
        //alert check

    
        // **10. Generate Tags for Each Festival**
        const festivalsWithTags = festivalsWithImages.map((festival:any) => {
          // Use existing tags_array if it exists
          const tags_array = festival.tags_array || [];

          // Generate tags if tags_array doesn't exist
          if (!festival.tags_array) {
            if (festival.tickets_available) {
              tags_array.push('Tickets left');
            }
            tags_array.push(festival.country);
            tags_array.push(festival.start_month);
            tags_array.push(festival.finish_month);
          }

          return { ...festival, tags_array };
        });

   

        // **11. Sort Festivals by `daterealstart`**
        const sortedFestivals = [...festivalsWithTags].sort(
          (a, b) => a.daterealstart - b.daterealstart
        );

        // setFestivals(dataArrayFestivals);
        setFestivals(sortedFestivals);




        // **12. Separate and Set Circuit Festivals**
        const dataArrayCirc = sortedFestivals.filter(
          (festival) => festival.type === 'Circuit'
        );
        setFestivalsCirc(dataArrayCirc);




        // **13. Compile List of Countries for Circuit Festivals**
        const circCountrySet = new Set();
        dataArrayCirc.forEach((festival) => {
          circCountrySet.add(festival.country);
        });
        const arrayOfCountriesC = Array.from(circCountrySet);
        setCircCountryList(arrayOfCountriesC);

  

        // **14. Separate and Set Pride Festivals**
        const dataArrayPr = sortedFestivals.filter(
          (festival) => festival.type === 'Pride'
        );
        setFestivalsPr(dataArrayPr);

  
        // **15. Compile List of Countries for Pride Festivals**
        const prideCountrySet = new Set();
        dataArrayPr.forEach((festival) => {
          prideCountrySet.add(festival.country);
        });
        const arrayOfCountriesPr = Array.from(prideCountrySet);
        setPrideCountryList(arrayOfCountriesPr);

    
        // **16. Indicate Data Loading is Complete**
        setFestDataIsLoaded(true);
  }, [
  storeIsLoaded,
  imagesAreLoaded,
  hawaiiTime,
  citySelected,
 rawFestivals,
 storedImages
]);





useEffect(() => {
  if (!storeIsLoaded || !citySelected || !rawTags.length) {
    return;
  }
  
              const dataArrayTagsParties: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "parties")
                ) {
                  dataArrayTagsParties.push(rawTags[i]);
                }
              }

              dataArrayTagsParties.sort((a:any, b:any):any => {
                a = a.order;
                b = b.order;
                return a - b
              });

              setTagsParties(dataArrayTagsParties);
              

              const dataArrayTagsBC: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "barsandclubs")
                ) {
                  dataArrayTagsBC.push(rawTags[i]);
                }
              }

              setTagsBC(dataArrayTagsBC);


              const dataArrayTagsPeople: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "people")
                ) {
                  dataArrayTagsPeople.push(rawTags[i]);
                }
              }
              
              dataArrayTagsPeople.sort((a: any, b: any):any => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });

              dataArrayTagsPeople.sort((a:any, b:any):any => {
                a = a.order;
                b = b.order;
                return a - b
              });

              setTagsPeople(dataArrayTagsPeople);



              const dataArrayTagsCruises: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "cruises")
                ) {
                  dataArrayTagsCruises.push(rawTags[i]);
                }
              }

              setTagsCruises(dataArrayTagsCruises);
        setTagsDataIsLoaded(true);
    
   
  }, [
  storeIsLoaded,
  citySelected,
  rawTags,
]);


useEffect(() => {
  if (!storeIsLoaded || !citySelected || !rawNotifications.length) {
    return;
  }
  
        const dataArrayNotifications: any = [];
      for (let i = 0; i < rawNotifications.length; i++) {
        if (
          (rawNotifications[i].display)
        ) {
          dataArrayNotifications.push(rawNotifications[i]);
        }
      }

      for (let i = 0; i < dataArrayNotifications.length; i++) {
        let datemonth: any;
        switch(dataArrayNotifications[i].month) {
          case "January":
          datemonth = 0;
          break;
          case "February":
          datemonth = 1;
          break;
          case "March":
          datemonth = 2;
          break;
          case "April":
          datemonth = 3;
          break;
          case "May":
          datemonth = 4;
          break;
          case "June":
          datemonth = 5;
          break;
          case "July":
          datemonth = 6;
          break;
          case "August":
          datemonth = 7;
          break;
          case "September":
          datemonth = 8;
          break;
          case "October":
          datemonth = 9;
          break;
          case "November":
          datemonth = 10;
          break;
          case "December":
          datemonth = 11;
        }
        const newHour = +dataArrayNotifications[i].timeorder.toString().slice(0,-2);
        const newMinutes = +dataArrayNotifications[i].timeorder.toString().slice(-2);
        const newDate = new Date(+dataArrayNotifications[i].year, datemonth, +dataArrayNotifications[i].date, newHour, newMinutes);
        dataArrayNotifications[i]['datereal'] = newDate;
      }

      dataArrayNotifications.sort((a:any, b:any):any => {
        a = a.datereal.getTime();
        b = b.datereal.getTime();
        return b - a
      });

      dataArrayNotifications.sort((a:any, b:any):any => {
        a = a.locked;
        b = b.locked;
        return b - a
      });

      setNotifications(dataArrayNotifications);
      setNotificationDataIsLoaded(true);
  }, [
  storeIsLoaded,
  citySelected,
  rawNotifications
]);

  useEffect(() => {
    if (!citySelected) {
      return;
    }
    const intervalId = setInterval(() => {
      handlePullRefresher(citySelected.timezone);
      return;
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [citySelected]);







useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 isWeb updated:', isWeb);
}, [isWeb]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 gridNo updated:', gridNo);
}, [gridNo]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 isWide updated:', isWide);
}, [isWide]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 parties updated:', parties);
}, [parties]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 partiesFeat updated:', partiesFeat);
}, [partiesFeat]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 citySelected updated:', citySelected);
}, [citySelected]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 currentDateAndTime updated:', currentDateAndTime);
}, [currentDateAndTime]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 likedParties updated:', likedParties);
}, [likedParties]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 tagsParties updated:', tagsParties);
}, [tagsParties]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 notifications updated:', notifications);
}, [notifications]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 viewedNotifications updated:', viewedNotifications);
}, [viewedNotifications]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 viewedNotificationsAreLoaded updated:', viewedNotificationsAreLoaded);
}, [viewedNotificationsAreLoaded]);


useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 partyDataIsLoaded updated:', partyDataIsLoaded);
}, [partyDataIsLoaded]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 isDownloading updated:', isDownloading);
}, [isDownloading]);

useEffect(() => {
  console.log('PARTIES_PROPS: 🔄 imagesAreLoaded updated:', imagesAreLoaded);
}, [imagesAreLoaded]);

useEffect(() => {
  console.log('🐛 DEBUG_CONDITION isLanding:', isLanding);
  console.log('🐛 DEBUG_CONDITION imagesAreLoaded:', imagesAreLoaded);
  console.log('🐛 DEBUG_CONDITION isDownloading:', isDownloading);
  console.log('🐛 DEBUG_CONDITION openCitySelector:', openCitySelector);
  console.log('🐛 DEBUG_CONDITION cityListN:', cityListN);
  console.log('🐛 DEBUG_CONDITION festDataIsLoaded:', festDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION partyDataIsLoaded:', partyDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION dragDataIsLoaded:', dragDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION venueDataIsLoaded:', venueDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION cruiseDataIsLoaded:', cruiseDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION peopleDataIsLoaded:', peopleDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION tagsDataIsLoaded:', tagsDataIsLoaded);
  console.log('🐛 DEBUG_CONDITION notificationDataIsLoaded:', notificationDataIsLoaded);

  const combinedCondition =
    !isLanding &&
    imagesAreLoaded &&
    !isDownloading &&
    (!openCitySelector &&
      cityListN &&
      (!festDataIsLoaded ||
        !partyDataIsLoaded ||
        !dragDataIsLoaded ||
        !venueDataIsLoaded ||
        !cruiseDataIsLoaded ||
        !peopleDataIsLoaded ||
        !tagsDataIsLoaded ||
        !notificationDataIsLoaded));

  console.log('🐛 DEBUG_CONDITION Combined Condition:', combinedCondition);
}, [
  isLanding,
  imagesAreLoaded,
  isDownloading,
  openCitySelector,
  cityListN,
  festDataIsLoaded,
  partyDataIsLoaded,
  dragDataIsLoaded,
  venueDataIsLoaded,
  cruiseDataIsLoaded,
  peopleDataIsLoaded,
  tagsDataIsLoaded,
  notificationDataIsLoaded,
]);

  return (
    <IonApp>
    
    {/* <IonToast
        isOpen={showToast}
        message="No internet connection. Please check your network."
        duration={3000}
        position="top"
        color="dark"
      />
      <IonToast
        isOpen={showSlowNetworkToast}
        message="Slow internet connection detected. App initialisation may take longer."
        duration={3000}
        position="top"
        color="dark"
        onDidDismiss={() => setShowSlowNetworkToast(false)}
      /> */}
      
      {
        appIsSuspended
        &&
        <div className="suspendscreen">
           
            <IonModal
            isOpen={appIsSuspended}
            backdropDismiss={false}
            className="custommod"
            >
              <div className="updatebox">
                <div className="updatehead">App Suspended</div>
                <div className="updatemsg">The Gay Agenda is currently undergoing maintenance and will be back online as soon as possible. Apologies for the inconvenience.</div>
              </div>
            </IonModal>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
             
            </div>
           
        </div>
      }

    
      {
        !versionIsCorrect
        &&
        <div className="suspendscreen">
          <IonAlert
          isOpen={!versionIsCorrect}
          backdropDismiss={false}
          
          header="Update Required"
          message="Please update the app to the latest version."
          className="customalert"
          buttons={[
            {
              text: 'Update',
              role: 'confirm',
              handler: () => {
                handleClickUpdate();
                openCapacitorSite();
                return false;
              }
            }
          ]}
          >
          </IonAlert>
            <div className="splashupper"></div>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
            </div>
        </div>
      }

      


      {
      ((!imagesAreLoaded) || !cityListN)
      &&
      <div className="splashscreen">
            <div className="splashupper"></div>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
              </div>
                  <div className="splashlower">
                    {/* Loading */}
                  {
                  firstLoad && !isWeb
                  &&
                        <div className="loadingtxt">{downloadProgress}%</div>
                  }
                   {
                  firstLoad && !isWeb
                  &&
                        <div className="containerforprogressbar">  
                                <div className="innerbar" style={{ width: `${downloadProgress}%` }}></div>
                        </div>
                  }
                  </div>
                  {/* <div>{imagesAreLoaded ? "true" : "false"}</div>
                  <div>{cityListN ?  <ul>
        {cityListN.map((city:any, index: number) => (
          <li key={index}>{city}</li>
        ))}
      </ul> : "no city list"}</div> */}
      </div>
      }

        {
          isLanding
          &&
          <LandingPage
          setIsLanding={setIsLanding}
          />
        }


      {!isLanding &&
      openCitySelector && imagesAreLoaded && cityListN
      &&
      <div className="splashscreen"> 
          <div className="fadeanim">
            <div className="splashupper"></div>
              <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
            </div>
              <div className="splashlower">
                {/* Loading */}
                  {
                  firstLoad
                  &&
                        <div className="loadingtxt">{downloadProgress}%</div>
                  }
                   {
                  firstLoad
                  &&
                        <div className="containerforprogressbar">
                                <div className="innerbar" style={{ width: `${downloadProgress}%` }}></div>
                        </div>
                  }
                  </div>
          </div>
          {/* <div className="image-container">
            <img src={earthImage} />
        </div> */}
          <div className="pickcitybox">
          <div className="cityonethrd">
              <img className="triang2" src={newlogo} alt="" />
                <div className="pickcity">Select a city:</div>
            <IonContent className="contmin">
              <div className="innerwrap">
                  {
                  cityListN.map((city:any, index:number) => {
                    return <div key={index} className={tempCity === city ? "citybb addpnk" : "citybb"}
                    onClick={() => handleCityChange(city)}
                    >{city}</div>
                  })
                }
              </div>
            
            </IonContent>
                    <div className="contbtm">
                    {
                        tempCity === ""
                        &&
                            <div className="splashbtn">
                                    <IonIcon icon={arrowForwardOutline} className="contarrow small" />
                          </div>
                      }
                      {
                        tempCity !== ""
                        &&
                            <div className="splashbtn2"
                            onClick={() => firstSetCity(tempCity)}
                            >
                                    <IonIcon icon={arrowForwardOutline} className="contarrow" />
                          </div>
                      }
                    </div>
            </div>
            </div>
            
      </div>
      }



        



          {
            !isLanding
            &&
          imagesAreLoaded
          &&
          !isDownloading
          &&
          (!openCitySelector
          &&
          cityListN
          &&
          (!festDataIsLoaded || !partyDataIsLoaded || !dragDataIsLoaded || !venueDataIsLoaded || !cruiseDataIsLoaded || !peopleDataIsLoaded || !tagsDataIsLoaded || !notificationDataIsLoaded)
          )
          &&
          <>
          
          <IonHeader>
            <IonToolbar className="transparenttoolbar">
            <div className="toolbar2">
                      <div className="abscontionmenu">
                              <IonIcon
                              icon={reorderThreeOutline}
                              className="toolbariconfade2"
                              />
                      </div>
                      <div className="abscontionmenu">
                              <IonIcon
                              icon={notificationsOutline}
                              className="toolbariconfade"
                              />
                      </div>
              <div className="abscontionmenu">
                <div className="toolbarother">
                          <img className="trihq" src={logohq} alt="" />
                </div>
              </div>
              <div className="abscontionmenu">
                                <IonIcon
                                icon={searchOutline}
                                className="toolbariconfade"
                                />    
              </div>
              <div className="abscontionmenu">
                            <IonIcon
                            icon={options}
                            className="toolbariconfade"              
                            />
                </div>
            </div>
                <div className="mgdatesoth">
                <div className="gridviewsectionheader6">
                          <div className="extbox pinkcol">
                            </div>
                  </div>
                </div>
            </IonToolbar>
          </IonHeader>
          <div className="loadingdiv">
                    {/* <IonLoading spinner="circles"/> */}
                    LOADING
              </div>
          </>
          }
            {
              !isLanding
              &&
            (!openCitySelector && festDataIsLoaded && partyDataIsLoaded && dragDataIsLoaded && venueDataIsLoaded && cruiseDataIsLoaded && peopleDataIsLoaded && tagsDataIsLoaded && notificationDataIsLoaded)
            &&
            imagesAreLoaded
            &&
            <IonReactRouter>
              <IonSplitPane when={width >= 1200} contentId="main">
              <Menu
              isWeb={isWeb}
              citySelected={citySelected.city}
              cityListN={cityListN}
              citiesNotifs={citiesNotifs}
              handleToggleCity={handleToggleCity}
              openPickerSelectCity={openPickerSelectCity}
              setDefCity={setDefCity}
              defCity={defCity}
              parties={parties}
              barsAndClubs={barsAndClubs}
              saunas={saunas}
              clinics={clinics}
              festivals={festivals}
              festivalsCirc={festivalsCirc}
              festivalsPr={festivalsPr}
              people={people}
              dragExpos={dragExpos}
              dragTours={dragTours}
              cruises={cruises}
              />
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/parties" />
                </Route>
                <Route path="/parties" exact={true}>
                  <Parties
                  isWeb={isWeb}
                  text={"Events"}
                  gridNo={gridNo}
                  isWide={isWide}
                  parties={parties}
                  partiesFeat={partiesFeat}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  tagsParties={tagsParties}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
               
                <Route path="/parties-fest/:festival" exact={true}>
                  <PartiesFest
                  isWeb={isWeb}
                  isWide={isWide}
                  festivals={festivals}
                  parties={parties}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  tagsParties={tagsParties}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route path="/circuit" exact={true}>
                  <CircFests
                  isWeb={isWeb}
                  text={"Circuit Parties"}
                  isWide={isWide}
                  festivalsCirc={festivalsCirc}
                  citySelected={citySelected}
                  circCountryList={circCountryList}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCircFests={likedCircFests}
                  setLikedCircFests={setLikedCircFests}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  pride={false}
                  />
                </Route>
                <Route path="/pride" exact={true}>
                  <CircFests
                  isWeb={isWeb}
                  text={"Pride Festivals"}
                  isWide={isWide}
                  festivalsCirc={festivalsPr}
                  citySelected={citySelected}
                  circCountryList={prideCountryList}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCircFests={likedCircFests}
                  setLikedCircFests={setLikedCircFests}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  pride={true}
                  />
                </Route>
                <Route exact path="/parties/:id">
                  <PartyWindow
                  isWeb={isWeb}
                  parties={parties}
                  barsAndClubs={venues}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
                <Route exact path="/circuit/:id">
                  <CircWind
                  isWeb={isWeb}
                  festivalsCirc={festivalsCirc}
                  parties={allParties}
                  barsAndClubs={venues}
                  likedParties={likedCircFests}
                  setLikedParties={setLikedCircFests}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  cityListN={cityListN}
                  changeCityLogic={changeCityLogic}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/pride/:id">
                  <CircWind
                  isWeb={isWeb}
                  festivalsCirc={festivalsPr}
                  parties={allParties}
                  barsAndClubs={venues}
                  likedParties={likedCircFests}
                  setLikedParties={setLikedCircFests}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  cityListN={cityListN}
                  changeCityLogic={changeCityLogic}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/barsandclubs">
                  <BarsAndClubs
                  isWeb={isWeb}
                  text={"Bars & Clubs"}
                  isWide={isWide}
                  barsAndClubs={barsAndClubs}
                  citySelected={citySelected}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  tagsBC={tagsBC}
                  setTagsBC={setTagsBC}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedBarsAndClubs={likedBarsAndClubs}
                  setLikedBarsAndClubs={setLikedBarsAndClubs}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/saunas">
                  <Saunas
                  // text={"Saunas"}
                  saunas={saunas}
                  citySelected={citySelected}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  />
                </Route>
                <Route exact path="/clinics">
                  <Health
                  clinics={clinics}
                  citySelected={citySelected}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  />
                </Route>
                <Route exact path="/barsandclubs/:id">
                  <BarAndClubWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  barsAndClubs={barsAndClubs}
                  parties={parties}
                  currentDateAndTime={currentDateAndTime}
                  currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                  likedBarsAndClubs={likedBarsAndClubs}
                  setLikedBarsAndClubs={setLikedBarsAndClubs}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/saunas/:id">
                  <SaunaWindow
                  gridNo={gridNo}
                  saunas={saunas}
                  parties={parties}
                  currentDateAndTime={currentDateAndTime}
                  currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                  />
                </Route>
                <Route exact path="/clinics/:id">
                  <HealthWindow
                  gridNo={gridNo}
                  clinics={clinics}
                  // parties={parties}
                  // currentDateAndTime={currentDateAndTime}
                  // currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                  />
                </Route>
                <Route exact path="/people">
                  <People
                  isWeb={isWeb}
                  isWide={isWide}
                  text={"Performers"}
                  people={people}
                  citySelected={citySelected}
                  tagsPeople={tagsPeople}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedPeople={likedPeople}
                  setLikedPeople={setLikedPeople}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  parties={parties}
                  dragExpos={dragExpos}
                  />
                </Route>
                <Route exact path="/people/:id">
                  <PersonWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  people={people}
                  parties={parties}
                  festivalsCirc={festivalsCirc}
                  festivalsPr={festivalsPr}
                  dragExpos={dragExpos}
                  dragTours={dragTours}
                  cruises={cruises}
                  likedPeople={likedPeople}
                  setLikedPeople={setLikedPeople}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/drag">
                  <DragTours
                  text={"Drag Events"}
                  dragExpos={dragExpos}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedDragExpos={likedDragExpos}
                  setLikedDragExpos={setLikedDragExpos}
                  />
                </Route>
                <Route exact path="/drag/:id">
                  <DragTourWindow
                  dragExpos={dragExpos}
                  barsAndClubs={venues}
                  likedDragExpos={likedDragExpos}
                  setLikedDragExpos={setLikedDragExpos}
                  // tvShows={tvShows}
                  people={people}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
                <Route exact path="/cruises">
                  <Cruises
                  isWeb={isWeb}
                  text={"Cruises"}
                  isWide={isWide}
                  cruises={cruises}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  tagsCruises={tagsCruises}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCruises={likedCruises}
                  setLikedCruises={setLikedCruises}
                  cruiseCountryList={cruiseCountryList}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/cruises/:id">
                  <CruiseWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  cruises={cruises}
                  likedCruises={likedCruises}
                  setLikedCruises={setLikedCruises}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
              </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>       
            }
    </IonApp>
 
  );
};

export default App;
