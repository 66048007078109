// search probably needs to be updated for live events - see what I did for spot cards in CalView

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { useParams } from 'react-router';

import {
  IonButtons,
  IonContent,
  IonHeader,
  // IonRefresher,
  // IonRefresherContent,
  IonMenuButton,
  IonPage,
  // IonTitle,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonBackButton,
  IonLabel,
  IonModal,
  IonToggle,
  IonSearchbar,
  IonButton,
  // IonFab,
  // IonFabButton,

  // IonicSlides,
} from '@ionic/react';

import {
  // locationOutline,
  options,
  calendarOutline,
  searchOutline,
  heartSharp,
  notificationsOutline,
  heartOutline,
  lockClosedOutline,
  // reorderThreeOutline,
  // informationOutline,
  gridOutline,
  chevronForwardOutline,
  chevronDown,
  // helpCircleOutline,
  // helpCircle,
} from 'ionicons/icons';

// import CalViewParties from './CalViewParties';
import CalViewMG from './CalViewMG';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
// so in tgamarknine i actually back-graded to a 9 version of Swiper so I could continue with how I was doing it before. above doesn't work now.


import logohq from '../images/tgalogo_hq.png';



import 'swiper/css';
// import '@ionic/react/css/ionic-swiper.css';

import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/infomodal.css';
import '../theme/searchbars.css';
import '../theme/spotcards.css';
import '../theme/filtermodals.css';
import '../theme/swipers.css';
import '../theme/segments.css';
// import '../components/Menu.css';
// import ExploreContainer from '../components/ExploreContainer';


// Accent-folding function
const removeAccents = (str:string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};


const FilterToggle: React.FC<{label:any; value:any; checked:any; onToggle:any}> = ({ label, value, checked, onToggle }) => (
  <div className="filtersinnerflexbox">
    <div className="filtername2">{label}</div>
    <IonToggle
      slot="end"
      value={value}
      checked={checked}
      onIonChange={onToggle}
    />
  </div>
);




const Section: React.FC<{sectionName:any; tagsParties:any; tagsShown:any; tagsSelected:any; onToggle:any}> = ({ sectionName, tagsParties, tagsShown, tagsSelected, onToggle }) => {
  // Filter tags for the current section
  const filteredTags = tagsParties.filter(
    (tag:any) => tag.section === sectionName && tagsShown.includes(tag.name)
  );

  if (!filteredTags.length) return null; // Skip rendering if no tags are found for this section

  return (
    <>
      <div className="menusectheader2">{sectionName.toUpperCase()}</div>
      <div className="filtersgrid">
        {filteredTags.map((tag:any, index:any) => (
          <FilterToggle
            key={index}
            label={tag.name}
            value={tag.name}
            checked={tagsSelected.includes(tag.name)}
            onToggle={onToggle}
          />
        ))}
      </div>
    </>
  );
};



const PartiesFest: React.FC<{
  // text: any;
  // gridNo: any;
  isWeb: any;
  isWide: any;
    festivals: any;
  parties: any;
  citySelected: any;
  currentDateAndTime: any;
  // openPickerSelectCity: any;
  likedParties: any;
  setLikedParties: any;
  tagsParties: any;
  // handlePullRefresherWithPause: any;
  notifications: any;
  setNotifications: any;
  viewedNotifications: any;
  setViewedNotifications: any;
  viewedNotificationsAreLoaded: any;
  handleIncrement: any;
  handleDecrement: any;
}> = ( props ) => {

const selectedFestParam = useParams<{ festival: string }>().festival;
const selectedFest = props.festivals.find((fest:any) => fest.festparam === selectedFestParam);
// console.log(selectedFest, 'selected fest')


  const [partyView, setPartyView] = useState("gridview");
  const [filteredParties, setFilteredParties] = useState<any>(null);
  // const [filteredPartiesInProgressAndToday, setFilteredPartiesInProgressAndToday] = useState<any[]>([]);
  const [dateOfLastParty, setDateOfLastParty] = useState<any>(null);
  const [dateOfFirstParty, setDateOfFirstParty] = useState<any>(null);


  const [isNotificationsOpenMG, setIsNotificationsOpenMG] = useState(false);
  // const [isReadyF, setIsReadyF] = useState(false);

  
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [isLikedPartiesTagOn, setIsLikedPartiesTagOn] = useState(false);
  const [tagsShown, setTagsShown] = useState<any[]>([]);
  const [associatedSections, setAssociatedSections] = useState<any[]>([]);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");
  const [searchedForParties, setSearchedForParties] = useState([]);

  const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);


  const pageParties = useRef(undefined);
  const modalNotificationsMG= useRef<HTMLIonModalElement>(null);
  const modalInfoMG= useRef<HTMLIonModalElement>(null);
  const modalTagsParties= useRef<HTMLIonModalElement>(null);
  const modalSearchParties = useRef<HTMLIonModalElement>(null);




  const handleViewChange = (viewClicked: string) => {
    if (viewClicked === partyView) return;
    if (viewClicked === "gridview") setPartyView("gridview");
    if (viewClicked === "calview") setPartyView("calview");
  };

  const handleOpenNotificationsMG = () => {
    setIsNotificationsOpenMG(true);
    const arrayOfNotificationsNotAlreadyInState: any = [];
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
    }
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
    }
    for (let i = 0; i < props.notifications.length; i++) {
      if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
        arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
      }
    }
    props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
  }

  const handleCloseNotificationsMG = () => {
    setIsNotificationsOpenMG(false);
  }

  const handleTogView = () => {
    if (partyView === "gridview") setPartyView("calview")
    if (partyView === "calview") setPartyView("gridview")
  }

  const handleLikeParty = (event: any, partyhash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    // console.log(event.target.value, 'does this cause reset23');
    props.setLikedParties((prev: any)=>[...prev, partyhash]);
    props.handleIncrement("parties", partyhash);
  }

  const handleUnlikeParty = (event: any, partyhash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    props.setLikedParties(props.likedParties.filter((partylikedhash:any) => partylikedhash !== partyhash));
    props.handleDecrement("parties", partyhash);
  }

  const handleOpenSearchBar = () => {
    // if (props.parties.length > 0) {
    setIsSearchBarOpen(true);
    // }
  }

  const handleCloseSearchBar = () => {
    setIsSearchBarOpen(false);
    setSearchText("");
    setSearchedForParties(props.parties);
  }

  const handleSearchText = ({ target }:any) => {
    setSearchText(target.value);
  }
  //above seems inefficient

  const handleSearchText2 = ({ target }:any) => {
    setSearchText2(target.value);
  }

  const handleOpenTags = () => {
    // if (props.parties.length > 0) {
    setIsFiltersOpen(true);
    // }
  }

  const addTags = (tag: string) => {
    if (tagsSelected.includes(tag)) {
      // is includes just as efficient as filter. if (tagsSelected.filter(ele => ele === tag).length > 0)
      const tagSelectedOneLess = tagsSelected.filter((ele: any) => ele !== tag);
      setTagsSelected(tagSelectedOneLess);
    } else {
      setTagsSelected((prevTags: any) => {
        return prevTags.concat(tag);
      });
    }
  };

  const handleTurnOnTag = (event: CustomEvent) => {
    addTags(event.detail.value);
  };

  const handleFilterParties = () => {

    if (!selectedFest) {
      return
    }

    let tempArrayOfFilteredParties:any = [];

   
    if (!isLikedPartiesTagOn) {
      tempArrayOfFilteredParties = props.parties.filter((party: any) => (
        party.festparam === selectedFest?.festparam
        &&
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
       
      ));
    }

    if (isLikedPartiesTagOn) {
      tempArrayOfFilteredParties = props.parties.filter((party: any) => (
        party.festparam === selectedFest?.festparam
        &&
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
        &&
        props.likedParties.filter((ele: any) => ele === party.hash).length > 0
       
      ));
    }




    if (tempArrayOfFilteredParties.length > 0) {
      const lastEvent = tempArrayOfFilteredParties?.slice(-1);
      const lastDate = lastEvent[0]?.daterealstart;
  
      const frstEvent = tempArrayOfFilteredParties[0];
      const frstDate = frstEvent.daterealstart;
      setDateOfLastParty(lastDate);
      setDateOfFirstParty(frstDate);
    }

    setFilteredParties(tempArrayOfFilteredParties);

  }


  const handleFilterFestivalParties = () => {
    if (!selectedFest) {
      return;
    }
  
    const tagsSelectedLength = tagsSelected.length;
    const likedPartiesSet = new Set(props.likedParties);
  
    // Prepare search text
    const searchTextWTrimmed = (searchText2 || '').trim();
    const hasSearchText = searchTextWTrimmed !== '';
    const transformedSearchText = hasSearchText
      ? removeAccents(searchTextWTrimmed.toLowerCase())
      : '';
  
    const filteredParties = [];
  
    // Iterate over all parties once
    for (const party of props.parties) {
      // Check if the party's festparam matches the selected festival
      if (party.festparam !== selectedFest.festparam) {
        continue; // Skip this party
      }
  
      // Check if the party includes all selected tags or if no tags are selected
      const hasAllTags =
        tagsSelectedLength === 0 ||
        tagsSelected.every((tag) => party.tags_array.includes(tag));
  
      if (!hasAllTags) {
        continue; // Skip this party
      }
  
      // If liked parties filter is on, check if the party is liked
      if (isLikedPartiesTagOn && !likedPartiesSet.has(party.hash)) {
        continue; // Skip this party
      }
  
      // Check if the party's name matches the search text
      if (hasSearchText) {
        const partyNameTransformed = removeAccents((party.name || '').toLowerCase());
        if (!partyNameTransformed.includes(transformedSearchText)) {
          continue; // Skip this party
        }
      }
  
      // If all conditions are met, include the party
      filteredParties.push(party);
    }
  
    if (filteredParties.length > 0) {
      // Sort the filtered parties by `daterealstart`
      filteredParties.sort((a, b) => a.daterealstart - b.daterealstart);
  
      // Get the first and last events
      const firstEvent = filteredParties[0];
      const lastEvent = filteredParties[filteredParties.length - 1];
  
      setDateOfFirstParty(firstEvent.daterealstart);
      setDateOfLastParty(lastEvent.daterealstart);
    }
  
    setFilteredParties(filteredParties);
  };

  useEffect(() => {
    if (!props.isWide && searchText2 !== "") {
  
      setSearchText2("");
    }
    else if (props.isWide && isSearchBarOpen && searchText !== "") {
      console.log('why not heee');
      handleCloseSearchBar();
      
    } else if (props.isWide && isSearchBarOpen && searchText === "") {
      setIsSearchBarOpen(false);
    }
  }, [props.isWide])


  useEffect(() => {
    handleFilterFestivalParties();
  }, [
    props.parties,
      props.tagsParties,
      // think more about whether above is required. yes because if you say change a filter like pool party it would need to be removed if pool party tag is on.
      // hmm what about tagsParties. could that create an error
      tagsSelected,




      // props.citySelected,





      props.currentDateAndTime,
      // is the above required? yes i think so but TMA. what about when city changes. well that will cause time to change but why have i used city then in useeffect below re resetting tags...
      // ive added it because setting state is async...
      isLikedPartiesTagOn,
      props.likedParties,
      //the above i think is for where you have filter for liked parties on and you like or unlike a party... you need it to update.

      props.festivals,

      selectedFest,

      searchText2
  ]);
//i had to add selected fest as a dependency because otherwise component wasnt rerendering with new parties when router url changed. but for some reason didnt need to do this in web version because the app was creating two separate partiesfest components automatically?

  // useEffect(() => {
  //   setPresentingElement(pageParties.current);
  // }, []);

  useEffect(() => {
    // if (!searchText) return;

    const filteredParties = [];
  
    // Iterate over all parties once
    for (const party of props.parties) {
      // Check if the party's festparam matches the selected festival
      if (party.festparam !== selectedFest.festparam) {
        continue; // Skip this party
      }
  
      // If all conditions are met, include the party
      filteredParties.push(party);
    }
    
    const tempArrayOfSearchedForParties:any = filteredParties.filter((party: any) => {
      const newName = removeAccents(party.name.toLowerCase());
        return newName.includes(searchText.toLowerCase())
      }
    );
      // console.log(tempArrayOfSearchedForParties, 'search parties something whats different')
      setSearchedForParties(tempArrayOfSearchedForParties)
  }, [searchText, props.parties])
  // previous version had parties as dependent... could have it... but does it matter... if you are in the middle of search does it matter if new party gets added... ill put it in for now

  useEffect(() => {
    setTagsSelected([]);
  }, [props.citySelected]);

  // useEffect(() => {
  //   const tagsShownArray = [];
  //   const sectionsArray = [];

  //   for (let i = 0; i < props.tagsParties.length; i++) {
  //     if (props.parties.some((party: any) => party.tags_array && (party.tags_array.includes(props.tagsParties[i].name) || tagsSelected.includes(props.tagsParties[i].name)))) {
  //       tagsShownArray.push(props.tagsParties[i].name);
  //       sectionsArray.push(props.tagsParties[i].section);
  //     }
  //   }
  //   console.log(tagsShownArray, 'doesthislookright');
  //   console.log(sectionsArray, 'doesthislookright2');

  //   setTagsShown(tagsShownArray);
  //   setAssociatedSections(sectionsArray);
  // }, [props.parties, props.tagsParties]);


  useEffect(() => {
    const tagsShownArray: any[] = [];
    const sectionsSet = new Set<string>(); // Specify the type as Set<string> to avoid issues
  
    // Create a map of tags present in the parties array for quick lookup
    const tagMap = new Map<string, boolean>();


    const filteredParties = [];
  
    // Iterate over all parties once
    for (const party of props.parties) {
      // Check if the party's festparam matches the selected festival
      if (party.festparam !== selectedFest.festparam) {
        continue; // Skip this party
      }
  
      // If all conditions are met, include the party
      filteredParties.push(party);
    }


  
    // Populate the tagMap by iterating through the parties only once
    filteredParties.forEach((party: any) => {
      if (party.tags_array) {
        party.tags_array.forEach((tag: string) => {
          if (!tagMap.has(tag)) {
            tagMap.set(tag, true);
          }
        });
      }
    });
  
    // Iterate over tagsParties to find relevant tags based on the tagMap
    props.tagsParties.forEach((tagObj: any) => {
      const tag = tagObj.name;
  
      // Check if the tag is in the map or selected by the user
      if (tagMap.has(tag) || tagsSelected.includes(tag)) {
        tagsShownArray.push(tag);
        sectionsSet.add(tagObj.section); // Add section to the Set to ensure uniqueness
      }
    });
  
    // Sort tagsShownArray alphabetically
    tagsShownArray.sort((a: string, b: string) => a.localeCompare(b));
  
    // Convert sectionsSet to an array, sort alphabetically
    const sortedSectionsArray = Array.from(sectionsSet as Set<string>).sort((a: string, b: string) => a.localeCompare(b));
  
    // Update state with sorted arrays
    setTagsShown(tagsShownArray);
    setAssociatedSections(sortedSectionsArray);
  }, [props.parties, props.tagsParties, tagsSelected]);


  return (
    <IonPage ref={pageParties}>
      {
        selectedFest ? (
          <>
      <IonHeader>
        <IonToolbar className="transparenttoolbar">
        <div className="toolbar2">
                      
                        
                  <div className="abscontionmenu">

                      <div className="abstlb">
                            <IonButtons className="btnposition">
                              <IonMenuButton color="primary" className="toolbaricontransparent" />
                            </IonButtons>

                            {/* <IonIcon
                              icon={reorderThreeOutline}
                              className="toolbaricon3"
                              /> */}
                        </div>
                            
                          
                              

                          
                            
                          
                      </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsMG}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
            
                    <div className="abscontionmenu">
                      <div className="toolbarother">
                                <img className="trihq" src={logohq} alt="" />
                                {/* <div className="logocirc">

                                  <img className="trihq" src={logohq} alt="" />
                                </div> */}
                      
                      </div>
                    </div>
            <div className="abscontionmenu">
                              {
                              props.parties.length > 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBar}
                              />
                              }
                              {
                              props.parties.length === 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              }
                              
            </div>
            <div className="abscontionmenu">
                          {
                          props.parties.length > 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon"              
                          onClick={handleOpenTags}
                          />
                          }
                          {
                          props.parties.length === 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                          }

                          {
                          (tagsSelected.length > 0 || isLikedPartiesTagOn)
                          &&
                          props.parties.length > 0
                          
                          &&
                          <div className="filtercounter">{tagsSelected.length + (isLikedPartiesTagOn ? 1 : 0)}</div>
                          }
              </div>
        
          </div>

          {
            !(tagsSelected.length === 0
            &&
            !isLikedPartiesTagOn
            &&
            (
              filteredParties?.length === 0
            )
            &&
            searchText2 === "")
            // I dont think i need anymore additional to the above with isWide but TMA.
            &&
            <div className="toolbar3">
              <div className="searchtoolbarweb">
                    <IonSearchbar showClearButton="focus" animated={true} placeholder="Search for an event . . ."
                    onIonInput={(event)=>handleSearchText2(event)}
                    value={searchText2}
                    className={searchText2 === "" ? "searchbarpartiesweb" : "searchbarpartiesweb2"}
                    >
                    </IonSearchbar>
                    
              </div>
              <div className="menusectb">
                

                {
                        (tagsSelected.length === 0)
                
                        &&
                <div className="menuicncontalt" onClick={handleOpenTags}>
                <IonIcon icon={options} className="menicn2"></IonIcon>

                     
                        <div className="filtcirc">
                    
                        <IonIcon icon={chevronDown} className="menicnfilt"></IonIcon>

                      </div>
                        
              
                  
                      {/* Filter */}
                </div>
                  }
                  {
                        (tagsSelected.length > 0)
                
                        &&
                <div className="menuicncontalt2" onClick={handleOpenTags}>
                <IonIcon icon={options} className="menicn22"></IonIcon>

                     
                      
                        
                
                    
                        <div className="filtcirc">
                          <div className="menicnfiltnum">{tagsSelected.length}</div>
                        

                      </div>
                      
                  
                  
                </div>
                  }

                <div className="newviewtog">
                  <div className={partyView === "gridview" ? "segtext segcolored" : "segtext"}
                  onClick={() => handleViewChange("gridview")}
                  >
                    <IonIcon icon={gridOutline} className="grdic"></IonIcon>
                  </div>
                  <div className="segtog">
                    <IonToggle
                    className="grdcaltog"
                    value={partyView}
                    checked={partyView === "calview"}
                    onIonChange={handleTogView}
                    >
                    </IonToggle>
                  </div>
                  <div className={partyView === "calview" ? "segtext segcolored" : "segtext"}
                  onClick={() => handleViewChange("calview")}
                  >
                    <IonIcon icon={calendarOutline} className="calic"></IonIcon>
                  </div>
                  
                </div>
                
              </div>
          </div>

          }
          

       



       

                
                <div className="mgdatesoth">
                
                <div className="gridviewsectionheader6">
                          {/* <div className="extbox">
                            {selectedFest.name}
                            </div>
                            <div className="tinyball2"></div>
                            <div className="extbox">
                            {selectedFest.datestring}
                            </div> */}

                        <div className="extbox pinkcol">
                          {/* {selectedFest.name} */}
                          {/* {props.citySelected} */}
                          {/* {props.citySelected.name_abbr} */}
                          {props.citySelected.name_abbr || props.citySelected.city}
                          </div>
                          {/* <div className="tinyball2"></div> */}
                          <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                          <div className="extbox whitecol">
                          {/* {selectedFest.datestring} */}
                          {/* {selectedFest.name} */}
                          {selectedFest.menu_name ? selectedFest.menu_name : selectedFest.name}
                          </div>
                            
                    {/* <div className="sechead1">
                        <div className="secbbl">
                          {selectedFest.datestring}
                        </div>
                      </div>
                    
                    <div className="sechead2">
                        <div className="secbbl">
                          {selectedFest.name}
                          </div>
                    </div> */}
                    
                    
                    {/* <div className="sechead3"> 
                    <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                    </div> */}
                    
                </div>

                
                
                </div>
                
                
          
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen>

        {/* NOTIFICATIONS MODAL */}
        <IonModal
          ref={modalNotificationsMG}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenMG}
          onDidDismiss={handleCloseNotificationsMG}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                    <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                          <div className="notifcardmain">
                                            {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                            <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                            <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                          </div>    
                                                    
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                  Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>
                                          <div className="notifcardmalt">
                                          
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div> 
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>

        {/* SEARCH MODAL */}
        <IonModal
        isOpen={isSearchBarOpen}
        ref={modalSearchParties}
        // presentingElement={presentingElement}
        // className="searchmodal"
        // canDismiss={canDismiss}
        onDidDismiss={() => handleCloseSearchBar()}
        // not 100% sure the above will work or if its the best way. the problem was that swiming gesture wasnt resetting search bar. could always turn off swipe. Do i need both this and below...is it doubling up on functions?
        >
          <IonHeader>
            <IonToolbar className="searchtoolbar">
              <div className="searchtoolbardiv">
                <IonSearchbar showClearButton="focus" animated={false} placeholder="Search for an event . . ."
                onIonInput={(event)=>handleSearchText(event)}
                value={searchText}
                className="searchbarparties"
                >
                </IonSearchbar>
                <IonButtons slot="end" className="closebutton">
                  <IonButton className="inncl" color="primary" onClick={handleCloseSearchBar}>Close</IonButton>
                </IonButtons>
              </div>
            </IonToolbar>
          </IonHeader>
          <div className="searchmodalflexbox">
          {/* is the above needed? */}         
                                {
                                  searchedForParties.length === 0
                                  &&
                                  <div className="noparties2">No results found.</div>
                                }  
            <IonContent>
              {searchedForParties.map((party: any, index: number) => {
                return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}
                // onClick={() => dismiss()}
                onClick={() => handleCloseSearchBar()}
                >
                  <div className="searchspotcard">
                    <div className="searchspotfirstsection">
                      {
                        party.image_src
                        &&
                      <img className="searchfirstsectimg" src={party.image_src} alt=""
                      onError={(e: any) => e.target.src = party.image_url}
                      ></img>
                      }
                        {
                        !party.image_src
                        &&
                      <img className="searchfirstsectimg" src={party.image_url} alt=""
                      ></img>
                      }
                    </div>
                    
                    <div className="searchspotmainsectionnew">
                        
                        <div className="searchspotmainsectinneroth">{party.name}</div>
                        <div className="searchspotmainsectinnerothb">
                          <div>{party.venue}</div>
                          <div className="tinyball"></div>
                          <div className="grncol">{party.start_date}{" "}{party.start_month}</div>
                          {/* <div className="tinyball3"></div>
                          {party.start_time} */}
                        </div>
                   
                      </div>



                  </div>
                </Link> 
                })}
            </IonContent>
          </div>
        </IonModal>

        {/* FILTERS MODAL */}
        <IonModal
        isOpen={isFiltersOpen}
        ref={modalTagsParties}
        // trigger="open-tags-modal"
        onDidDismiss={()=>setIsFiltersOpen(false)}
        // initialBreakpoint={1}
        // breakpoints={[0, .25, .5, .75, 1]}
        className="filtersmodalstd"
        >
          <IonContent className="filtersmodalcontainer">

            {/* Static GENERAL section */}
          <div className="menusectheader2">GENERAL</div>
              <div className="filtersgrid">
                <FilterToggle
                  label="Tickets left"
                  value="Tickets left"
                  checked={tagsSelected.includes("Tickets left")}
                  onToggle={handleTurnOnTag}
                />
                {!props.isWeb && (
                  <FilterToggle
                    label="Liked"
                    value="Liked parties"
                    checked={isLikedPartiesTagOn}
                    onToggle={() => setIsLikedPartiesTagOn(!isLikedPartiesTagOn)}
                  />
                )}
                {/* <FilterToggle
                  label="Limited-time"
                  value="Special"
                  checked={tagsSelected.includes("Special")}
                  onToggle={handleTurnOnTag}
                /> */}
              </div>

              {/* Static PRICE section */}
              <div className="menusectheader2">PRICE</div>
                  <div className="filtersgrid">
                    <FilterToggle
                      label="Free"
                      value="Free"
                      checked={tagsSelected.includes("Free")}
                      onToggle={handleTurnOnTag}
                    />
                    <FilterToggle
                      label={`< ${props.parties[0]?.currency_symbol}100`}
                      value="100"
                      checked={tagsSelected.includes("100")}
                      onToggle={handleTurnOnTag}
                    />
                  </div>


                  {/* Dynamically rendered sections based on associatedSections */}
                {associatedSections.map((section) => (
                  <Section
                    key={section}
                    sectionName={section}
                    tagsParties={props.tagsParties}
                    tagsShown={tagsShown}
                    tagsSelected={tagsSelected}
                    onToggle={handleTurnOnTag}
                  />
                ))}
          
            {/* <div className="menusectheader2">GENERAL</div>
            <div className="filtersgrid">
              <div className="filtersinnerflexbox">
                <div className="filtername2">Tickets left</div>
                <IonToggle
                slot="end"
                value="Tickets left"
                checked={tagsSelected.includes("Tickets left") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
              {
                !props.isWeb
                &&
              <div className="filtersinnerflexbox">      
                <div className="filtername2">
                  Liked
                </div>
                <IonToggle
                slot="end"
                value="Liked parties"
                checked={isLikedPartiesTagOn}
                onIonChange={()=>setIsLikedPartiesTagOn(!isLikedPartiesTagOn)}
                >
                </IonToggle>
              </div>
              }
            </div>
              <div className="menusectheader2">PRICE</div>
            <div className="filtersgrid">
              <div className="filtersinnerflexbox">
                <div className="filtername2">Free</div>
                <IonToggle
                slot="end"
                value={"Free"}
                checked={tagsSelected.includes("Free") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
              <div className="filtersinnerflexbox">
                <div className="filtername2">{"< "}{props.parties[0]?.currency_symbol}100</div>
                <IonToggle
                slot="end"
                value={"100"}
                checked={tagsSelected.includes("100") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
            </div>
            {
            associatedSections.includes('includes')
            &&
            <div className="menusectheader2">INCLUDES</div>
            }
            {
            associatedSections.includes('includes')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "includes") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('theme')
            &&
            <div className="menusectheader2">THEME</div>
            }
            {
            associatedSections.includes('theme')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "theme") && tagsShown.filter(tag => tag === ele.name).length > 0))
                .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('target')
            &&
            <div className="menusectheader2">GROUP</div>
            }
            {
            associatedSections.includes('target')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "target") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('location')
            &&
            <div className="menusectheader2">LOCATION</div>
            }
            {
            associatedSections.includes('location')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "location") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('meal')
            &&
            <div className="menusectheader2">MEAL</div>
            }
            {
            associatedSections.includes('meal')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "meal") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            } */}
          </IonContent>
        </IonModal>

        {/* <IonModal
          ref={modalInfoMG}
          isOpen={isInfoOpenMG}
          onDidDismiss={() => setIsInfoOpenMG(false)}
          className="infomodal"
          >
            <div className="infomodmsg">
              {selectedFest?.ticket_provider}
            </div>
          </IonModal> */}

          


        {/* <IonRefresher slot="fixed" onIonRefresh={props.handlePullRefresherWithPause}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>


        <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton size="small" onClick={()=>setIsInfoOpenMG(true)}>
                      <IonIcon icon={informationOutline} className="infoicon"></IonIcon>
                    </IonFabButton>
          </IonFab> */}

          


        {
          
        partyView === "gridview"
        &&
        <div className="ultimategridviewcontainer">
        
         

          {
          tagsSelected.length === 0
          &&
          !isLikedPartiesTagOn
          &&
          (
            filteredParties?.length === 0
          )
          &&
          searchText2 === ""
          // I dont think i need anymore additional to the above with isWide but TMA.
          &&
          <div className="noparties gridviewlineheightsm">
            {/* This festival is not happening in the selected city. Please select another option from the menu. */}
            Festival not found. Please select a different option from the menu.
          </div>
          }
          {/* the above in theory cant be reached since the festival wont be in menu if not matching parties... NO, you get to it when you are on a different city and switch. */}
          {
             !props.isWide
             &&
          (tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          (
            filteredParties?.length === 0
          )
          &&
          <div className="noparties gridviewlineheightsm">
            There are no parties that correspond to the selected filter criteria. Please remove one or more filters.
          </div>
          }
          {
             props.isWide
             &&
          (searchText2 !== "" || tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          (
            filteredParties?.length === 0
          )
          &&
          <div className="noparties gridviewlineheightsm">
            There are no parties that correspond to the selected filter and/or search criteria.
          </div>
          }

          <div className="gridviewgrid"
          // style={{gridTemplateColumns: `repeat(${props.gridNo}, calc(100% / ${props.gridNo}))`}}
          >
            {
            filteredParties?.map((party: any, index: number) => {
              return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                      <div className="trybox2">
                      <div className="gridviewcardstdnewnew">
                        {
                        party.image_src
                        &&
                        <img className="gridviewcardimage" src={party.image_src} alt=""
                        onError={(e: any) => e.target.src = party.image_url}
                        />
                        }
                        {
                        !party.image_src
                        &&
                        <img className="gridviewcardimage" src={party.image_url} alt="" />
                        }
                        {
                            !props.isWeb
                            &&
                        <div className="gridviewcardimagecover2">

                          {
                          props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length === 0
                          &&
                          <div className="gridviewlikebuttoncontainer">
                            <IonIcon
                            icon={heartOutline}
                            className="gridviewlikebutton"
                            onClick={(event)=>handleLikeParty(event, party.hash)}
                            />
                          </div>
                          }
                          {
                          props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length > 0
                          &&
                          <div className="gridviewlikebuttoncontainer"
                          
                          >
                            <IonIcon icon={heartSharp} color="danger" className="gridviewlikebutton"
                            onClick={(event)=>handleUnlikeParty(event, party.hash)}
                            />
                          </div>
                          }

                        


                        

                        </div>
                    }
                      </div>

                      <div className="gridviewbottomcontainertry">

                            <div className="gridviewtitlecontainer">
                                  {
                                    party.name_short === ""
                                    &&
                                    <div className="gridviewtitlefulloth">{party.name}</div>
                                  }
                                  {
                                    party.name_short !== ""
                                    &&
                                    <div className="gridviewtitlefulloth">{party.name_short}</div>
                                  }

                                {
                                (props.currentDateAndTime >= party.daterealstart)
                                &&
                                <div className="midcont2">
                                      
                                    <div className="midcontdatelive">
                                        LIVE
                                    </div>
                                    <div className="tinyballr">

                                    </div>
                                    <div className="midconttimer">
                                        Ends {party.finish_time}
                                    </div>
                                </div>
                                }
                                {
                                    (party.daterealstart > props.currentDateAndTime)
                                    &&
                                    (
                                      party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                      &&
                                      party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                      && 
                                      party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                                    )
                                    &&
                                    <div className="midcont2">
                                    <div className="midcontdate">
                                      Starts {party.start_time}
                                    </div>
                                   
                                </div>
                                  }
                                  {
                                    (party.daterealstart > props.currentDateAndTime)
                                    &&
                                    !(
                                      party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                      &&
                                      party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                      && 
                                      party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                                    )
                                    &&
                                  <div className="midcont2">
                                    <div className="midcontdate">
                                        {party.start_date}{" "}{party.start_month.slice(0,3)}
                                    </div>
                                    <div className="tinyball">

                                    </div>
                                    <div className="midconttime">
                                        {party.start_time}
                                    </div>
                                  </div>
                                  }

                                  <div className="midcontalt">
                                                {party.description}
                                  </div>

                                  </div>


                              </div>



                      </div>
                    </Link>
            })
            }
          </div>
        </div>
        }
        {
        partyView === "calview"
        &&
        <CalViewMG 
        currentDateAndTime={props.currentDateAndTime}
        dateOfLastParty={dateOfLastParty}
        dateOfFirstParty={dateOfFirstParty}
        filteredParties={filteredParties}
        mardiGras={true}
        />
        }
      </IonContent>

      <div className="segmentcon">
        <IonSegment
        id="check"
        value={partyView}
        className="segment-inner-container"
        swipeGesture={false}
        >

          <IonSegmentButton value="gridview"
          layout="icon-top"
          onClick={() => handleViewChange("gridview")}
          >
            {/* <IonLabel>GRID</IonLabel> */}
            <IonLabel>Grid</IonLabel>
              <IonIcon icon={gridOutline}></IonIcon>
            </IonSegmentButton>
          {
            props.parties.length > 0
            &&
            <IonSegmentButton value="calview"
            onClick={() => handleViewChange("calview")}
          >
            {/* <IonLabel>CALENDAR</IonLabel> */}
            <IonLabel>Calendar</IonLabel>
            <IonIcon icon={calendarOutline}></IonIcon>
          </IonSegmentButton>
          }


          {/* <IonSegmentButton value="gridview"
          onClick={() => handleViewChange("gridview")}
          >
            <IonLabel>GRID</IonLabel>
          </IonSegmentButton>
          {
            props.parties.length > 0
            &&
            <IonSegmentButton value="calview"
            onClick={() => handleViewChange("calview")}
          >
            <IonLabel>CALENDAR</IonLabel>
          </IonSegmentButton>
          }
          {
            props.parties.length === 0
            &&
            <IonSegmentButton value="calview" disabled
            onClick={() => handleViewChange("calview")}
          >
            <IonLabel>CALENDAR</IonLabel>
          </IonSegmentButton>
          } */}
          
        </IonSegment>
      </div>
      </>
        ) : (
          <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonBackButton defaultHref="/" text="">
                            </IonBackButton>
                        </IonButtons>
                        </div>
                        <div className="innerwindowflex">
                            <div className="notfoundmsg">
                            This festival has either finished or been removed. Please click the back button.
                            </div>
                            
                        </div>
                    </div>
        )
      }
    </IonPage>
  );
};

export default PartiesFest;