import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  IonButtons,
  IonContent,
  IonHeader,
  // IonRefresher,
  // IonRefresherContent,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonIcon,
  IonModal,
  // IonToggle,
  // IonSearchbar,
  // IonButton
} from '@ionic/react';

import {
  // locationOutline,
  options,
  searchOutline,
  // heartSharp,
  notificationsOutline,
  // heartOutline,
  lockClosedOutline,
  // pricetagOutline,
  // reorderThreeOutline,
  chevronForwardOutline,
} from 'ionicons/icons';

import logohq from '../images/tgalogo_hq.png';


import '../theme/gridview.css';
import '../theme/notifications.css';
// import '../theme/searchbars.css';
// import '../theme/spotcards.css';
// import '../theme/filtermodals.css';
// import '../theme/swipers.css';
// import '../theme/segments.css';

const DragTours: React.FC<{
  text: any;
  dragExpos: any;
  citySelected: any;
  currentDateAndTime: any;
  // openPickerSelectCity: any;
  // tagsDE: any;
  notifications: any;
  setNotifications: any;
  viewedNotifications: any;
  setViewedNotifications: any;
  viewedNotificationsAreLoaded: any;
  likedDragExpos: any;
  setLikedDragExpos: any;
  // handlePullRefresherWithPause: any;
}> = ( props ) => {

  const [filteredDragExpos, setFilteredDragExpos] = useState<any>(null);

  const [isNotificationsOpenDE, setIsNotificationsOpenDE] = useState(false);
  
  const modalNotificationsDT = useRef<HTMLIonModalElement>(null);
// ive renamed ref but not most of the state or function etc names...


  //can combine the above two functions into one...

  const handleOpenNotificationsDE = () => {
    setIsNotificationsOpenDE(true);
    const arrayOfNotificationsNotAlreadyInState: any = [];
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
    }
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
    }
    for (let i = 0; i < props.notifications.length; i++) {
      if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
        arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
      }
    }
    props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
  }

  const handleCloseNotificationsDE = () => {
    setIsNotificationsOpenDE(false);
  }





  const handleFilterDragExpos = () => {
    const tempArrayOfFilteredDragExpos:any = props.dragExpos.filter((dragexpo: any) => (
        // dragexpo.daterealstart > props.currentDateAndTime
        // &&
        // (tagsSelectedDE.every(tag => dragexpo.tags.includes(tag)) || tagsSelectedDE.length === 0)
        true
      ));
    
    setFilteredDragExpos(tempArrayOfFilteredDragExpos);
  }

  useEffect(() => {
    handleFilterDragExpos();
  }, [props.dragExpos,
    //   props.tagsDE,
      // think more about whether above is required
    //   tagsSelectedDE,

      props.currentDateAndTime,



      // props.citySelected








      // is the above required? yes i think so but TMA. what about when city changes. well that will cause time to change but why have i used city then in useeffect below re resetting tags...
    //   isLikedDETagOn,
    //   props.likedDragExpos
      //the above i think is for where you have filter for liked parties on and you like or unlike a party... you need it to update.
  ]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="transparenttoolbar">
        <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">

                      <div className="abstlb">
                          <IonButtons className="btnposition">
                            <IonMenuButton color="primary" className="toolbaricontransparent" />
                          </IonButtons>
                      </div>
                          
                         
                            {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}

                        
                          
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsDE}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
           
            
                    <div className="abscontionmenu">
                        <div className="toolbarother">
                                    <img className="trihq" src={logohq} alt="" />
                                    {/* <div className="logocirc">

                                    <img className="trihq" src={logohq} alt="" />
                                    </div> */}
                        
                        </div>
                    </div>


            <div className="abscontionmenu">
                              
                             
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              
            </div>
            <div className="abscontionmenu">
                          
                        
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                         

                          
              </div>
           
          </div>


          <div className="toolbar3">
                            <div className="searchtoolbarweb">
                                        <div className="misctitle">Drag Events</div>
                                </div>
                             
                </div>

          


            <div className="mgdatesoth">

            <div className="gridviewsectionheader6">
                      <div className="extbox pinkcol">
                        {/* {selectedFest.name} */}
                        {/* {props.citySelected} */}
                        {/* {props.citySelected.name_abbr} */}
                        {props.citySelected.name_abbr || props.citySelected.city}
                        </div>
                        {/* <div className="tinyball2"></div> */}
                        <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                        <div className="extbox whitecol">
                        {/* {selectedFest.datestring} */}
                        {props.text}
                        </div>

                        


                        
                {/* <div className="sechead1">
                    <div className="secbbl">
                      {selectedFest.datestring}
                    </div>
                  </div>
                
                <div className="sechead2">
                    <div className="secbbl">
                      {selectedFest.name}
                      </div>
                </div> */}
                
                
                {/* <div className="sechead3"> 
                <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                </div> */}
                
              </div>



            </div>

        </IonToolbar>
     
      </IonHeader>


      {/* <IonRefresher slot="fixed" onIonRefresh={props.handlePullRefresherWithPause}>
          <IonRefresherContent></IonRefresherContent>
      </IonRefresher> */}


      {/* I think the above is meant to be inside IonContent (see Parties) and that's why it's not showing up */}
      

      <IonContent fullscreen>


                {/* NOTIFICATIONS MODAL */}
        <IonModal
          ref={modalNotificationsDT}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenDE}
          onDidDismiss={handleCloseNotificationsDE}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                    <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>
                                          
                                          <div className="notifcardmain">
                                            {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                            <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                            <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                        </div>  


                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                  Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                            <div className="notifcardmalt">
                                          
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div>
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>




                {/* in cruises i think this is not wrapped outside. make the sam and use same conditional logic */}
      <div className="ultimategridviewcontainer">
        {
        filteredDragExpos?.length === 0
        &&
        // <div className="noeventsavailablecontainer">
          <div className="noparties gridviewlineheightsm">
            There are no upcoming drag events in the selected city.
          </div>
        // </div>
        }

     

              <div className="gridviewgrid"
                >
                    {
                    filteredDragExpos?.map((tour: any, index: number) => {
                        return <Link key={index} to={`/drag/${tour.id}`} style={{ display: 'contents' }}>
                            <div className="trybox2">
                            <div
                            className="gridviewcardstdnewnew"
                            >
                                {
                                tour.image_src
                                &&
                                <img className="gridviewcardimage" src={tour.image_src} alt=""
                                onError={(e: any) => e.target.src = tour.image_url}
                                />
                                }
                                {
                                !tour.image_src
                                &&
                                <img className="gridviewcardimage" src={tour.image_url} alt=""
                                />
                                }
                                
                                <div className="gridviewcardimagecover2">

                                   

                                 
                                   
                                   
                                    
                                </div>
                                
                            </div>



                            <div className="gridviewbottomcontainertry">
                                    <div className="gridviewtitlecontainer">
                                          {
                                            tour.name_short === ""
                                            &&
                                            <div className="gridviewtitlefulloth">{tour.name}</div>
                                          }
                                          {
                                            tour.name_short !== ""
                                            &&
                                            <div className="gridviewtitlefulloth">{tour.name_short}</div>
                                          }
                                            <div className="midcont2">
                                                <div className="addcut2">{tour.type === "Tour" ? "Tour or Special Event" : "Convention"}</div>
                                            </div>
                                          {
                                            tour.type === "Tour"
                                            &&
                                            <div className="midcont2">
                                              <div className="midcontdate">
                                                  {tour.start_date}{" "}{tour.start_month.slice(0,3)}
                                              </div>
                                              <div className="tinyball">

                                              </div>
                                              <div className="midconttime">
                                                  {tour.start_time}
                                              </div>
                                            </div>
                                          }
                                          {
                                            tour.type === "Expo"
                                            &&
                                            <div className="midcont2">
                                              <div className="midcontdate">
                                                    {tour.datestring}
                                                </div>
                                            </div>
                                          }
                                          

                                          <div className="midcontalt">
                                                        {tour.description}
                                          </div>
                                          
                                          </div>
                                       
                            </div>


                            </div>
                        </Link>
                        })
                        } 
                </div>




        </div>
      </IonContent>
    </IonPage>
  );
};

export default DragTours;