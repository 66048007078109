import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonIcon,
  IonModal,
  IonToggle,
  IonSearchbar,
  IonButton,
  // IonFab,
  // IonFabButton
} from '@ionic/react';

import {
  options,
  searchOutline,
  heartSharp,
  notificationsOutline,
  heartOutline,
  lockClosedOutline,
  // reorderThreeOutline,
  // informationOutline,
  chevronForwardOutline,
  helpCircleOutline,
  addOutline,
} from 'ionicons/icons';

import logohq from '../images/tgalogo_hq.png';

import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/infomodal.css';
// why doesnt people have any css? ive now added the above 3... i guess it currently doesnt need segments, swipers, filtermodal, spotcards or searchbar


// Accent-folding function
const removeAccents = (str:string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};




const FilterToggle: React.FC<{label:any; value:any; checked:any; onToggle:any}> = ({ label, value, checked, onToggle }) => (
  <div className="filtersinnerflexbox">
    <div className="filtername2">{label}</div>
    <IonToggle
      slot="end"
      value={value}
      checked={checked}
      onIonChange={onToggle}
    />
  </div>
);




const Section: React.FC<{sectionName:any; tagsParties:any; tagsShown:any; tagsSelected:any; onToggle:any}> = ({ sectionName, tagsParties, tagsShown, tagsSelected, onToggle }) => {
  // Filter tags for the current section
  const filteredTags = tagsParties.filter(
    (tag:any) => tag.section === sectionName && tagsShown.includes(tag.name)
  );

  if (!filteredTags.length) return null; // Skip rendering if no tags are found for this section

  return (
    <>
      <div className="menusectheader2">{sectionName.toUpperCase()}</div>
      <div className="filtersgrid">
        {filteredTags.map((tag:any, index:any) => (
          <FilterToggle
            key={index}
            label={tag.name}
            value={tag.name}
            checked={tagsSelected.includes(tag.name)}
            onToggle={onToggle}
          />
        ))}
      </div>
    </>
  );
};



const People: React.FC<{
  isWeb: any;
  isWide: any;
  text: any;
  // gridNo: any;
  people: any;
  citySelected: any;
  // openPickerSelectCity: any;
  tagsPeople: any;
  notifications: any;
  setNotifications: any;
  viewedNotifications: any;
  setViewedNotifications: any;
  viewedNotificationsAreLoaded: any;
  likedPeople: any;
  setLikedPeople: any;
  handleIncrement: any;
  handleDecrement: any;

  parties: any;
  dragExpos: any;

}> = ( props ) => {

  
  const [filteredPeople, setFilteredPeople] = useState<any>(null);

  const [isNotificationsOpenPP, setIsNotificationsOpenPP] = useState(false);
  const [isInfoOpenPP, setIsInfoOpenPP] = useState(false);

    const [isFiltersOpenPP, setIsFiltersOpenPP] = useState(false);
    const [tagsSelectedPP, setTagsSelectedPP] = useState<any[]>([]);
    const [isLikedPeopleTagOn, setIsLikedPeopleTagOn] = useState(false);
    const [associatedSectionsPP, setAssociatedSectionsPP] = useState<any[]>([]

    );

    const [isSearchBarOpenPP, setIsSearchBarOpenPP] = useState(false);
    const [searchTextPP, setSearchTextPP] = useState("");
    const [searchTextPP2, setSearchTextPP2] = useState("");

    const [searchedForPeople, setSearchedForPeople] = useState([]);
    const [tagsShownPP, setTagsShownPP] = useState<any[]>([]);



  // const [presentingElementPP, setPresentingElementPP] = useState<HTMLElement | undefined>(undefined);
  
  const pagePeople = useRef<any>(null);
  const modalTagsPP = useRef<HTMLIonModalElement>(null);
  const modalNotificationsPP = useRef<HTMLIonModalElement>(null);
  const modalInfoPP = useRef<HTMLIonModalElement>(null);
  const modalSearchPP = useRef<HTMLIonModalElement>(null);


  const addTagsPP = (tag: string) => {
    if (tagsSelectedPP.includes(tag)) {
      // is includes just as efficient as filter. if (tagsSelected.filter(ele => ele === tag).length > 0)
      const tagSelectedOneLess = tagsSelectedPP.filter((ele: any) => ele !== tag);
      setTagsSelectedPP(tagSelectedOneLess);
    } else {
      setTagsSelectedPP((prevTags: any) => {
        return prevTags.concat(tag);
      });
    }
  };

  const handleTurnOnTagPP = (event: CustomEvent) => {
    addTagsPP(event.detail.value);
  };

  //can combine the above two functions into one...

  const handleOpenNotificationsPP = () => {
    setIsNotificationsOpenPP(true);
    const arrayOfNotificationsNotAlreadyInState: any = [];
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
    }
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
    }
    for (let i = 0; i < props.notifications.length; i++) {
      if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
        arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
      }
    }
    props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
  }

  const handleCloseNotificationsPP = () => {
    setIsNotificationsOpenPP(false);
  }

  const handleLikePerson = (event: any, hashpp: any) => {
    event.preventDefault();
    // event.stopPropagation();
    // console.log(event.target.value, 'does this cause reset23');
    props.setLikedPeople((prev: any)=>[...prev, hashpp]);
    props.handleIncrement("people", hashpp);
  }

  const handleUnlikePerson = (event: any, hashpp: any) => {
    event.preventDefault();
    // event.stopPropagation();
    props.setLikedPeople(props.likedPeople.filter((personlikedhash:any) => personlikedhash !== hashpp));
    props.handleDecrement("people", hashpp);
  }

  const handleOpenSearchBarPP = () => {
    setIsSearchBarOpenPP(true);
  }

  const handleCloseSearchBarPP = () => {
    setIsSearchBarOpenPP(false);
    setSearchTextPP("");
    setSearchedForPeople(props.people);
  }

  const handleSearchTextPP = ({ target }:any) => {
    setSearchTextPP(target.value);
  }

  const handleSearchTextPP2 = ({ target }:any) => {
    setSearchTextPP2(target.value);
  }

  const handleFilterPeople = () => {
    
    let tempArrayOfFilteredPeople:any = props.people;
   
    if (!isLikedPeopleTagOn) {
     
      tempArrayOfFilteredPeople = tempArrayOfFilteredPeople.filter((person: any) => (
        (tagsSelectedPP.every(tag => person.tags_array.includes(tag)) || tagsSelectedPP.length === 0)
      ));
    }

    if (isLikedPeopleTagOn) {
    
      tempArrayOfFilteredPeople = tempArrayOfFilteredPeople.filter((person: any) => (
        (tagsSelectedPP.every(tag => person.tags_array.includes(tag)) || tagsSelectedPP.length === 0)
        &&
        props.likedPeople.filter((ele: any) => ele === person.hash).length > 0
      ));
    }

    setFilteredPeople(tempArrayOfFilteredPeople);
  }

  const handleFilterPeopleNew = () => {
    const tagsSelectedLength = tagsSelectedPP.length;
    const likedPeopleSet = new Set(props.likedPeople);
  
    // Prepare search text
    const searchTextTrimmed = (searchTextPP2 || '').trim();
    const hasSearchText = searchTextTrimmed !== '';
    const transformedSearchText = hasSearchText
      ? removeAccents(searchTextTrimmed.toLowerCase())
      : '';
  
    const filteredPeople = [];
  
    // Iterate over all people once
    for (const person of props.people) {
      // Check if the person includes all selected tags or if no tags are selected
      const hasAllTags =
        tagsSelectedLength === 0 ||
        tagsSelectedPP.every((tag) => person.tags_array.includes(tag));
  
      if (!hasAllTags) {
        continue; // Skip this person
      }
  
      // If liked people filter is on, check if the person is liked
      if (isLikedPeopleTagOn && !likedPeopleSet.has(person.hash)) {
        continue; // Skip this person
      }
  
      // Check if the person's name matches the search text
      if (hasSearchText) {
        const personNameTransformed = removeAccents((person.name || '').toLowerCase());
        if (!personNameTransformed.includes(transformedSearchText)) {
          continue; // Skip this person
        }
      }
  
      // If all conditions are met, include the person
      filteredPeople.push(person);
    }
  
    setFilteredPeople(filteredPeople);
  };

  useEffect(() => {
    if (!props.isWide && searchTextPP2 !== "") {
  
      setSearchTextPP2("");
    }
    else if (props.isWide && isSearchBarOpenPP && searchTextPP !== "") {
      // console.log('why not heee');
      handleCloseSearchBarPP();
      
    } else if (props.isWide && isSearchBarOpenPP && searchTextPP === "") {
      setIsSearchBarOpenPP(false);
    }
  }, [props.isWide])

  useEffect(() => {
    handleFilterPeopleNew();
  }, [
    props.people,
      props.tagsPeople,
      // think more about whether above is required
      tagsSelectedPP,

        // props.currentDateAndTime,
      // is the above required? yes i think so but TMA. what about when city changes. well that will cause time to change but why have i used city then in useeffect below re resetting tags...
      
      
      
      
      
      // props.citySelected,
      
      
      
      
      
      
      
      isLikedPeopleTagOn,
      props.likedPeople,
      //the above i think is for where you have filter for liked parties on and you like or unlike a party... you need it to update.

      props.parties,
      props.dragExpos,

      searchTextPP2
  ]);

  // useEffect(() => {
  //   setPresentingElementPP(pagePeople.current);
  // }, []);

  useEffect(() => {
    
      // if (!searchTextPP) return;
    
            const tempArrayOfSearchedForPeople = props.people.filter((person: any) => {
            const newName = removeAccents(person.name.toLowerCase());
                return newName.includes(searchTextPP.toLowerCase())
            }
            );
      // console.log(tempArrayOfSearchedForPeople, 'search people something whats different')
      setSearchedForPeople(tempArrayOfSearchedForPeople)
  }, [searchTextPP, props.people])
  // previous version had parties as dependent... could have it... but does it matter... if you are in the middle of search does it matter if new party gets added... ill put it in for now

  useEffect(() => {
    // const initTags = [];
    // if (props.people.some((person:any) => person.festivals)) initTags.push('Festivals');
    // if (props.people.some((person:any) => person.cruises)) initTags.push('Cruises');
    // setTagsSelectedPP(initTags);
    setTagsSelectedPP([]);
  }, [props.citySelected,
    // props.people
  ]);
//do i need props.people as a dependency. my concern is the situation where someone has somehting selected. but i suppose tags selected logic in useEffect below caters for that ... so i will add for now.

  // useEffect(() => {
  //   const tagsShownArray = [];

  //   for (let i = 0; i < props.tagsPeople.length; i++) {
  //     if (props.people.some((person: any) => person.tags_array.includes(props.tagsPeople[i].name) || tagsSelectedPP.includes(props.tagsPeople[i].name))) {
  //       tagsShownArray.push(props.tagsPeople[i].name);
  //     }
  //   }
  //   console.log(tagsShownArray, 'doesthislookright');

  //   setTagsShownPP(tagsShownArray);
  // }, [props.people, props.tagsPeople]);

  


  useEffect(() => {
    const tagsShownArray: any[] = [];
    const sectionsSet = new Set<string>(); // Specify the type as Set<string> to avoid issues
  
    // Create a map of tags present in the people array for quick lookup
    const tagMap = new Map<string, boolean>();
  
    // Populate the tagMap by iterating through the people only once
    props.people.forEach((person: any) => {
      if (person.tags_array) {
        person.tags_array.forEach((tag: string) => {
          if (!tagMap.has(tag)) {
            tagMap.set(tag, true);
          }
        });
      }
    });
  
    // Iterate over tagsPeople to find relevant tags based on the tagMap
    props.tagsPeople.forEach((tagObj: any) => {
      const tag = tagObj.name;
  
      // Check if the tag is in the map or selected by the user
      if (tagMap.has(tag) || tagsSelectedPP.includes(tag)) {
        tagsShownArray.push(tag);
        sectionsSet.add(tagObj.section); // Add section to the Set to ensure uniqueness
      }
    });
  
    // Sort tagsShownArray alphabetically
    tagsShownArray.sort((a: string, b: string) => a.localeCompare(b));
  
    // Convert sectionsSet to an array and apply custom sorting for "TYPE" as second
    const sortedSectionsArray = Array.from(sectionsSet as Set<string>).sort((a: string, b: string) => {
      if (a === "TYPE") return -1;    // "TYPE" always second
      if (b === "TYPE") return 1;     // "TYPE" always second
      return a.localeCompare(b);      // Alphabetical sorting for the rest
    });
  
    // Update state with sorted arrays
    setTagsShownPP(tagsShownArray);
    setAssociatedSectionsPP(sortedSectionsArray);
  }, [props.people, props.tagsPeople, tagsSelectedPP]);



  return (
    <IonPage
    // ref={pagePeople}
    >
      <IonHeader>
        <IonToolbar className="transparenttoolbar">
          
          
          
        <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">

                        <div className="abstlb">
                            <IonButtons className="btnposition">
                              <IonMenuButton color="primary" className="toolbaricontransparent" />
                            </IonButtons>
                        </div>
                          
                         
                            {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}

                        
                          
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsPP}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
           
            
                    <div className="abscontionmenu">
                        <div className="toolbarother">
                                    <img className="trihq" src={logohq} alt="" />
                                    {/* <div className="logocirc">

                                    <img className="trihq" src={logohq} alt="" />
                                    </div> */}
                        
                        </div>
                    </div>


            <div className="abscontionmenu">
                              
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBarPP}
                              />
                              
            </div>
            <div className="abscontionmenu">
                         
                          <IonIcon
                          icon={options}
                          className="toolbaricon"   
                          onClick={() => setIsFiltersOpenPP(true)}
                          // onClick={handleOpenSearchBarPP}
                          />
                          

                          {
                          (tagsSelectedPP.length > 0 || isLikedPeopleTagOn)
                          &&
                          
                          <div className="filtercounter">{tagsSelectedPP.length + (isLikedPeopleTagOn ? 1 : 0)}</div>
                          }
              </div>
           
          </div>
          
          
          <div className="toolbar3">
                  <div className="searchtoolbarweb">
                    <IonSearchbar showClearButton="focus" animated={true} placeholder="Search for a performer . . ."
                    onIonInput={(event)=>handleSearchTextPP2(event)}
                    value={searchTextPP2}
                    className={searchTextPP2 === "" ? "searchbarpartiesweb" : "searchbarpartiesweb2"}
                    >
                    </IonSearchbar>
                    
                  </div>

                  <div className="menusectb">



                      

                      {
                              (tagsSelectedPP.length === 0)
                      
                              &&
                      <div className="menuicncontaltpp" onClick={() => setIsFiltersOpenPP(true)}>
                      <IonIcon icon={options} className="menicn2"></IonIcon>

                          
                              <div className="filtcirc">
                          
                              <IonIcon icon={addOutline} className="menicnfilt"></IonIcon>

                            </div>
                              
                    
                        
                            {/* Filter */}
                      </div>
                        }
                        {
                              (tagsSelectedPP.length > 0)
                      
                              &&
                      <div className="menuicncontaltpp2" onClick={() => setIsFiltersOpenPP(true)}>
                      <IonIcon icon={options} className="menicn22"></IonIcon>

                          
                            
                              
                      
                          
                              <div className="filtcirc">
                                <div className="menicnfiltnum">{tagsSelectedPP.length}</div>
                              

                            </div>
                            
                        
                        
                      </div>
                        }

                     
                
              </div>

              <div className="infocontr2" onClick={()=>setIsInfoOpenPP(true)}>
                    <IonIcon icon={helpCircleOutline} className="helpicon2"></IonIcon>

                </div>
          </div>
         

              <div className="mgdatesothpp">

              <div className="infocontl"></div>
                <div className="gridviewsectionheader6">
                          <div className="extbox pinkcol">
                            {/* {selectedFest.name} */}
                            {/* {props.citySelected} */}
                            {/* {props.citySelected.name_abbr} */}
                            {props.citySelected.name_abbr || props.citySelected.city}
                            </div>
                            {/* <div className="tinyball2"></div> */}
                            <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                            <div className="extbox whitecol">
                            {/* {selectedFest.datestring} */}
                            {props.text}
                            </div>

                            


                            
                    {/* <div className="sechead1">
                        <div className="secbbl">
                          {selectedFest.datestring}
                        </div>
                      </div>
                    
                    <div className="sechead2">
                        <div className="secbbl">
                          {selectedFest.name}
                          </div>
                    </div> */}
                    
                    
                    {/* <div className="sechead3"> 
                    <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                    </div> */}
                    
                  </div>
                  <div className="infocontr" onClick={()=>setIsInfoOpenPP(true)}>
                      <IonIcon icon={helpCircleOutline} className="helpicon"></IonIcon>

                  </div>


              </div>
        </IonToolbar>
      </IonHeader>

      

      <IonContent fullscreen>

        {/* SEARCH MODAL */}
        <IonModal
        isOpen={isSearchBarOpenPP}
        ref={modalSearchPP}
        // presentingElement={presentingElementPP!}
        // canDismiss={canDismiss}
        onDidDismiss={() => handleCloseSearchBarPP()}
        >
            <IonHeader>
                <IonToolbar className="searchtoolbar">
                    <div className="searchtoolbardiv">
                        <IonSearchbar
                        showClearButton="focus"
                        animated={false}
                        placeholder="Search for a performer . . ."
                        onIonInput={(event)=>handleSearchTextPP(event)}
                        value={searchTextPP}
                        className="searchbarparties"
                        ></IonSearchbar>
                        <IonButtons slot="end" className="closebutton">
                            <IonButton className="inncl" color="primary" onClick={handleCloseSearchBarPP}>Close</IonButton>
                        </IonButtons>
                    </div>
                </IonToolbar>
            </IonHeader>

            <div className="searchmodalflexbox">
                                {
                                  searchedForPeople.length === 0
                                  &&
                                  <div className="noparties2">No results found.</div>
                                }
                <IonContent>
                    {searchedForPeople.map((person: any, index: number) => {
                        return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}
                        // onClick={() => dismiss()}
                        onClick={() => handleCloseSearchBarPP()}
                        >
                        <div className="searchspotcard">
                            <div className="searchspotfirstsection">
                              {
                                person.image_src
                                &&
                                <img className="searchfirstsectimg" src={person.image_src} alt=""
                                onError={(e: any) => e.target.src = person.image_url}
                                ></img>
                              }
                               {
                                !person.image_src
                                &&
                                <img className="searchfirstsectimg" src={person.image_url} alt=""
                                ></img>
                              } 
                            </div>

                            <div className="searchspotmainsectionnew">
                                    
                                        <div className="searchspotmainsectinneroth">{person.name}</div>
                                        <div className="searchspotmainsectinnerothb">
                                          <div className="addcut">
                                            {person.type[0] === "Drag" ? "Drag performer" : person.type[0]}
                                            </div>
                                          
                                        
                                        
                                        </div>
                                        
                                 
                                </div>
                        
                           
                            
                          
                        </div>
                        </Link> 
                        })}
                    </IonContent>
            </div>
        </IonModal>

        {/* NOTIFICATIONS MODAL */}
        <IonModal
          ref={modalNotificationsPP}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenPP}
          onDidDismiss={handleCloseNotificationsPP}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>


                                                <div className="notifcardmain">
                                                    {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                                    <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                                    <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                                </div> 
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                  Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>


                                          <div className="notifcardmalt">
                                           
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div>
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>

        {/* FILTERS MODAL */}
        <IonModal
        isOpen={isFiltersOpenPP}
        ref={modalTagsPP}
        onDidDismiss={()=>setIsFiltersOpenPP(false)}
        // initialBreakpoint={1}
        // breakpoints={[0, .25, .5, .75, 1]}
        className="filtersmodalstd"
        >
          <IonContent className="filtersmodalcontainer">
            {/* Static GENERAL section */}
            {!props.isWeb && (
                <div className="menusectheader2">GENERAL</div>
              )}

                {!props.isWeb && (
                    
                    <div className="filtersgrid">
                       
                       
                        
                        <FilterToggle
                            label="Liked"
                            value="Liked parties"
                            checked={isLikedPeopleTagOn}
                            onToggle={() => setIsLikedPeopleTagOn(!isLikedPeopleTagOn)}
                        />
                     
                        
                    </div>
              )}


              {/* Render "TYPE" section first if it exists */}
              {associatedSectionsPP.includes("type") && (
                  <Section
                    key="type"
                    sectionName="type"
                    tagsParties={props.tagsPeople}
                    tagsShown={tagsShownPP}
                    tagsSelected={tagsSelectedPP}
                    onToggle={handleTurnOnTagPP}
                  />
                )}



              {
                (props.people.some((person:any) => person.festivals) || props.people.some((person:any) => person.cruises) || tagsSelectedPP.includes("No Festivals") || tagsSelectedPP.includes("No Cruises"))
              && (
                <div className="menusectheader2">EXCLUDE</div>
              )}

              {
                (props.people.some((person:any) => person.festivals) || props.people.some((person:any) => person.cruises) || tagsSelectedPP.includes("No Festivals") || tagsSelectedPP.includes("No Cruises"))
              && (
                <div className="filtersgrid">

                    {
                    (props.people.some((person:any) => person.festivals) || tagsSelectedPP.includes("No Festivals"))
                    &&
                    (
                        
                        
                          
                          
                            
                            <FilterToggle
                                label="Festivals"
                                value="No Festivals"
                                checked={tagsSelectedPP.includes("No Festivals")}
                                onToggle={handleTurnOnTagPP}
                            />
                        
                            
                        
                  )
                  }
                  {
                    (props.people.some((person:any) => person.cruises) || tagsSelectedPP.includes("No Cruises"))
                    &&
                    (
                        
                    
                          
                          
                            
                            <FilterToggle
                                label="Cruises"
                                value="No Cruises"
                                checked={tagsSelectedPP.includes("No Cruises")}
                                onToggle={handleTurnOnTagPP}
                            />
                        
                            
        
                  )
                  }

              </div>
              )}

                


                  {/* Render the rest of the sections excluding "TYPE" */}
                  {associatedSectionsPP
                    .filter(section => section !== "type")
                    .map((section) => (
                      <Section
                        key={section}
                        sectionName={section}
                        tagsParties={props.tagsPeople}
                        tagsShown={tagsShownPP}
                        tagsSelected={tagsSelectedPP}
                        onToggle={handleTurnOnTagPP}
                      />
                    ))}

               

            {/* {
              !props.isWeb
              &&

            
                <div className="menusectheader2">
                    GENERAL
                </div>
              }
              {
              !props.isWeb
              &&
                <div className="filtersgrid">
                    <div className="filtersinnerflexbox">
                          <div className="filtername2">
                            Liked
                       
                          </div>
                          <IonToggle
                          slot="end"
                          value="Liked parties"
                          checked={isLikedPeopleTagOn}
                          onIonChange={()=>setIsLikedPeopleTagOn(!isLikedPeopleTagOn)}
                          >
                          </IonToggle>
                            
                    </div>
                </div>
            }
                <div className="menusectheader2">
                    TYPE
                </div>
                <div className="filtersgrid">
                    {
                    props.tagsPeople.filter((ele: any) => ele.section === "type")
                    .map((tag: any, index: number) => {
                    return <div
                    key={index}
                    className="filtersinnerflexbox"
                    >
                        <div className="filtername2">{tag.name}</div>
                        <IonToggle
                        slot="end"
                        value={tag.name}
                        checked={tagsSelectedPP.includes(tag.name) ? true : false}
                        onIonChange={handleTurnOnTagPP}
                        >
                        </IonToggle>
                    </div>
                    })
                    }
              </div> */}
              {/* {
                props.tagsPeople.filter((ele: any) => (ele.section === "RPDR" && tagsShownPP.includes(ele.name))).length > 0
                &&
                <div className="menusectheader2">
                    RUPAUL{"'"}S DRAG RACE
                </div>
              }
                
                <div className="filtersgrid">
                    {
                    props.tagsPeople.filter((ele: any) => (ele.section === "RPDR" && tagsShownPP.includes(ele.name)))
                    .map((tag: any, index: number) => {
                    return <div
                    key={index}
                    className="filtersinnerflexbox"
                    >
                        <div className="filtername2">{tag.name}</div>
                        <IonToggle
                        slot="end"
                        value={tag.name}
                        checked={tagsSelectedPP.includes(tag.name) ? true : false}
                        onIonChange={handleTurnOnTagPP}
                        >
                        </IonToggle>
                    </div>
                    })
                    }
              </div>
              {
                props.tagsPeople.filter((ele: any) => (ele.section === "RPDRI" && tagsShownPP.includes(ele.name))).length > 0
                &&
                <div className="menusectheader2">
                    RPDR INTERNATIONAL
                </div>
              }
                
                <div className="filtersgrid">
                    {
                    props.tagsPeople.filter((ele: any) => (ele.section === "RPDRI" && tagsShownPP.includes(ele.name)))
                    .map((tag: any, index: number) => {
                    return <div
                    key={index}
                    className="filtersinnerflexbox"
                    >
                        <div className="filtername2">{tag.name}</div>
                        <IonToggle
                        slot="end"
                        value={tag.name}
                        checked={tagsSelectedPP.includes(tag.name) ? true : false}
                        onIonChange={handleTurnOnTagPP}
                        >
                        </IonToggle>
                    </div>
                    })
                    }
              </div> */}
          </IonContent>
            
        </IonModal>

        <IonModal
          ref={modalInfoPP}
          isOpen={isInfoOpenPP}
          onDidDismiss={() => setIsInfoOpenPP(false)}
          className="infomodal"
          >
            <div className="infomodmsg">
            Performers are featured only when part of a featured event, festival or cruise.
            </div>
          </IonModal>

          {/* <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton size="small" onClick={()=>setIsInfoOpenPP(true)}>
                      <IonIcon icon={informationOutline} className="infoicon"></IonIcon>
                    </IonFabButton>
          </IonFab> */}


         

        <div className="ultimategridviewcontainer">
        {
        
        !props.isWide
        &&
          filteredPeople?.length === 0
          &&
          (tagsSelectedPP.length > 0 || isLikedPeopleTagOn)
        
        &&
        <div className="noparties gridviewlineheightsm">
            There are no <span className="upcoming">performers</span> that correspond to the selected filter criteria. Please remove one or more filters.
        </div>
        }
         {
        
        props.isWide
        &&
          filteredPeople?.length === 0
          &&
          (searchTextPP2 !== "" || tagsSelectedPP.length > 0 || isLikedPeopleTagOn)
        
        &&
        <div className="noparties gridviewlineheightsm">
            There are no <span className="upcoming">performers</span> that correspond to the selected filter and/or search criteria.
        </div>
        }

{
        
          
        filteredPeople?.length === 0
        &&
        tagsSelectedPP.length === 0
        &&
        !isLikedPeopleTagOn
      &&
      searchTextPP2 === ""
      &&
      <div className="noparties gridviewlineheightsm">
          No performers found.
      </div>
      }

        
 
            


              <div className="gridviewgrid"
                >
                    {
                    filteredPeople?.map((person: any, index: number) => {
                        return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                            <div className="trybox2">
                            <div
                            className="gridviewcardstdnewnew"
                            >
                                {
                                person.image_src
                                &&
                                <img className="gridviewcardimage" src={person.image_src} alt=""
                                onError={(e: any) => e.target.src = person.image_url}
                                />
                                }
                                {
                                !person.image_src
                                &&
                                <img className="gridviewcardimage" src={person.image_url} alt=""
                                />
                                }
                                {
                              !props.isWeb
                              &&
                                <div className="gridviewcardimagecover2">

                                {
                                  props.likedPeople.filter((likedpersonhash: any) => likedpersonhash === person.hash).length === 0
                                  &&
                                  <div className="gridviewlikebuttoncontainer"
                                  
                                  >
                                    <IonIcon
                                    icon={heartOutline}
                                    className="gridviewlikebutton"
                                    onClick={(event)=>handleLikePerson(event, person.hash)}
                                    />
                                  </div>
                                  }
                                  {
                                  props.likedPeople.filter((likedpersonhash: any) => likedpersonhash === person.hash).length > 0
                                  &&
                                  <div className="gridviewlikebuttoncontainer"
                                  
                                  >
                                    <IonIcon
                                    icon={heartSharp}
                                    color="danger"
                                    className="gridviewlikebutton"
                                    onClick={(event)=>handleUnlikePerson(event, person.hash)}
                                    />
                                  </div>
                                  }
                                   
                                    
                                </div>
                            }
                            </div>



                            <div className="gridviewbottomcontainertry">
                                    <div className="gridviewtitlecontainer">
                                        {
                                            person.name_short === ""
                                            &&
                                            <div className="gridviewtitlefulloth">{person.name}</div>
                                        }
                                        {
                                            person.name_short !== ""
                                            &&
                                            <div className="gridviewtitlefulloth">{person.name_short}</div>
                                        }
                                        
                                        

                                        <div className="midcont2">
                                                <div className="midcontdate">
                                                   {person.type[0]}
                                                </div>
                                                {/* <div className="tinyball">

                                                </div>
                                                <div className="midconttime">
                                                    {party.start_time}
                                                </div> */}
                                            </div>

                                            <div className="midcontalt">
                                                {person.description}
                                            </div>
                                    </div>
                                       
                                </div>


                            </div>
                        </Link>
                        })
                        } 
                </div>

        </div>
        
       
      </IonContent>

      
      
    </IonPage>
  );
};

export default People;