// need to add live sign somewhere
// do you need question marks after selectedParty..ive taken like half off..
// need to add sold out case. whereever tickets available is false. (whether free or not)
// dont need swiper anymore. also remove css from swipers.css

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';
// to work with typescript had to do npm install -D @types/mapbox-gl

// import { Share } from '@capacitor/share';

import {
    IonPage,
    IonContent,
    // IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonModal,
    IonMenuButton
} from '@ionic/react';

import { heartSharp, heartOutline, arrowForwardOutline, alertCircleOutline, chevronBack } from 'ionicons/icons';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

// import neoncal from '../images/neoncal.png';


import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';


// import mapimage from '../images/buttonroundmap.png';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const PartyWindow: React.FC<{
    isWeb: any;
    parties: any[];
    barsAndClubs: any[];
    likedParties: any;
    setLikedParties: any;
    // people: any;
    peopleOth: any;
    currentDateAndTime: any;
    handleIncrement: any;
    handleDecrement: any;
    handleIncrClick: any;
    // handleDecrClick: any;
}> = (props) => {

    const selectedPartyId = useParams<{ id: string }>().id;
    // const selectedParty = props.parties.find(party => party.id === +selectedPartyId);
    const selectedParty = props.parties.find(party => party.id === selectedPartyId);

    const [isWindowMapOpenP, setIsWindowMapOpenP] = useState<any>(false);
    const [isWindowMapLoadedP, setIsWindowMapLoadedP] = useState<any>(false);
    // const [myMapP, setMyMapP] = useState<any>(null);
    // why dont i use myMapP? ive got rid of setting it for the moment.

    const modalMapP= useRef<HTMLIonModalElement>(null);
    const mapContainerP = useRef<any>(null);

    
    const handleLikePartyW = (partyhash: any) => {
        // event.preventDefault();
        props.setLikedParties((prev: any)=>[...prev, partyhash]);
        props.handleIncrement("parties", partyhash);
      }
    
      const handleUnlikePartyW = (partyhash: any) => {
        // event.preventDefault();
        props.setLikedParties(props.likedParties.filter((partylikedhash:any) => partylikedhash !== partyhash));
        props.handleDecrement("parties", partyhash);
      }

    const handleClickLink = () => {
        window.open(selectedParty.website, '_system', 'location=yes');
        props.handleIncrClick("parties", selectedParty.hash);
    }


    // const shareParty = async(titleparam:any, textparam:any, urlparam:any) => {
    //     await Share.share({
    //       title: titleparam,
    //       text: "Description",
    //       url: urlparam
    //       dialogTitle: 'Share with buddies',
    //     });
    //   }


    useEffect(() => {

        if (!mapContainerP.current) {
            return
        }

        const mapP = new mapboxgl.Map({
            container: mapContainerP.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedParty?.lng, selectedParty?.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedParty?.lng, selectedParty?.lat]).addTo(mapP);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapP(mapP);

        return () => mapP.remove();

    },[isWindowMapLoadedP]);

    const handleDismissWindowMapModalP = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because... (update: i commented it out)
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedP(false);
        setIsWindowMapOpenP(false);
    }

    //   const shareParty = async(titleparam:any, textparam:any, urlparam:any, dialogparam:any) => {

    //     await Share.share({
    //       title: titleparam,
    //       text: textparam,
    //       url: urlparam,
    //       dialogTitle: dialogparam,
    //     });
    //   }


    useEffect(() => {
        // console.log(selectedParty, new Date(selectedParty.start_timestamp), 'datex s')
        console.log(selectedParty, 'sel party');
    })



    return(
        <IonPage>
            {
                selectedParty ? (
            <IonContent>
                <IonModal
                ref={modalMapP}
                className="windowmapmodal"
                isOpen={isWindowMapOpenP}
                onDidDismiss={handleDismissWindowMapModalP}
                onDidPresent={() => setIsWindowMapLoadedP(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerP} className="mapcontainerinner" />
                    </div>
                </IonModal>

                <div className="windowcontainerwithgrad">
                    {/* <div className="btntabcont">
                        <div className="btntab"></div>
                    </div> */}
                    <div className="ionbackbuttoncont">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="ionbackbuttoncontoth">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="mainmenucont">
                        <IonButtons
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                    </div>
                    {
                        !props.isWeb
                        &&
                    props.likedParties.filter((likedpartyhash: any) => likedpartyhash === selectedParty.hash).length === 0
                    &&
                    <div
                    className="windowheartcont"
                    onClick={() => handleLikePartyW(selectedParty.hash)}
                    >
                        <IonIcon icon={heartOutline} className="windowheart" />
                    </div>
                    }
                    {
                        !props.isWeb
                        &&
                    props.likedParties.filter((likedpartyhash: any) => likedpartyhash === selectedParty.hash).length > 0
                    &&
                    <div
                    className="windowheartcont"
                    onClick={() => handleUnlikePartyW(selectedParty.hash)}
                    >
                        <IonIcon icon={heartSharp} color="danger" className="windowheart" />
                    </div>
                    }
                    <div className="windowtopcont">
                        {
                            selectedParty.image_src
                            &&
                            <img className="windowimgbox" src={selectedParty.image_src}
                            onError={(e: any) => e.target.src = selectedParty.image_url}
                            ></img>
                        }
                        {
                            !selectedParty.image_src
                            &&
                            <img className="windowimgbox" src={selectedParty.image_url}
                            ></img>
                        }
                        <div className="windowimggrad">
                          
                                <div className="windowtitlebox">

                              

                                

                                
                                    <div className="windowtitlefull">
                                       
                                            
                                              
                                        {selectedParty.name}
                                      

                                        


                                    </div>
                                    
                                    
                                
                                  
                                </div>
                            
                            
                            
                        </div>
                    </div>

                    
                    
                    <div className="windowmainsetwidth">

                      
                                

                       
                        {
                        !(selectedParty.daterealstart <= props.currentDateAndTime)
                        &&
                                            
                                        
                        <div className="windowdatetimecont">
                            <div className="datetimebox">
                                {/* <IonIcon icon={calendarOutline} className="datetimeicon"/> */}
                                {/* <img className="neoncal" src={neoncal} alt="" /> */}
                                <div className="datebox">
                                    <div className="datetimetext">
                                        {selectedParty.start_day.slice(0,3)}{","}&nbsp;{selectedParty.start_date}&nbsp;{selectedParty.start_month.slice(0,3)}
                                        {/* {selectedParty?.start_day.slice(0,3)}{", "}{selectedParty?.start_date}{" "}{selectedParty?.start_month.slice(0,3)} */}
                                        {/* <br/>
                                        2-11pm */}
                                    </div>
                                </div>
                            </div>
                            <div className="smalldot"></div>
                            <div className="datetimebox">
                                {/* <IonIcon icon={timeOutline} className="datetimeicon" /> */}
                                {/* <img className="neoncal" src={neoncal} alt="" /> */}
                                <div className="timebox">
                                    <div className="datetimetext">
                                        {selectedParty.start_time}&nbsp;to&nbsp;{selectedParty.finish_time}
                                        {/* {selectedParty.timestring} */}
                                    </div>
                                </div>
                            </div> 
                            {/* <div className="datetimebox">
                                <IonIcon icon={timeOutline} className="datetimeicon" />
                                <div className="timebox">
                                    <div className="datetimetext">
                                       
                                        Tickets from <div className="anothbubble">{selectedParty.currency_symbol}{selectedParty.price}</div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        }
                        {
                        selectedParty.daterealstart <= props.currentDateAndTime
                        &&
                                            
                                        
                        <div className="windowdatetimecont">
                            <div className="datetimebox">
                                {/* <IonIcon icon={calendarOutline} className="datetimeicon"/> */}
                                {/* <img className="neoncal" src={neoncal} alt="" /> */}
                                <div className="datebox">
                                    <div className="datetimetext2">
                                        LIVE
                                    </div>
                                </div>
                            </div>
                            <div className="smalldot2"></div>
                            <div className="datetimebox">
                                {/* <IonIcon icon={timeOutline} className="datetimeicon" /> */}
                                {/* <img className="neoncal" src={neoncal} alt="" /> */}
                                <div className="timebox">
                                    <div className="datetimetext2">
                                        Ends&nbsp;{selectedParty.finish_time}
                                        {/* {selectedParty.timestring} */}
                                    </div>
                                </div>
                            </div> 
                            {/* <div className="datetimebox">
                                <IonIcon icon={timeOutline} className="datetimeicon" />
                                <div className="timebox">
                                    <div className="datetimetext">
                                       
                                        Tickets from <div className="anothbubble">{selectedParty.currency_symbol}{selectedParty.price}</div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        }
                  


                        
                        
                        {
                        (selectedParty.description !== ""
                        // || selectedParty?.extra_info !== "" || selectedParty?.festival !== "" || selectedParty?.top !== ""
                        //i think about should only show these other things IF there is a description. otherwise looks weird
                        )
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>   

                            
                            




                            <div className="windowmaintextboxstd">
                                {selectedParty.description}
                            </div>
                        

                                {
                                (
                                   
                                    selectedParty.extra_info !== "")
                                &&
                                            <div className="festbtncont">
                                       

                                        {
                                        
                                        selectedParty.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedParty.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>

                            }
                        </div>
                        }

                        

                   
                            
                            {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty.venue).length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Venue
                            </div>
                            }
                            {/* end of set width main box */}

                        </div>

                        {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty.venue).length > 0
                            &&
                            <div className="gridviewgridBC mgab2">
                                {
                                    props.barsAndClubs.filter(ele => ele.name === selectedParty.venue)
                                    .map((venue: any, index: number) => {
                                        return <div key={index} className="gridviewcardstd">
                                            <Link to={`/${venue.type === "barorclub" ? "barsandclubs" : "saunas"}/${venue.id}`} style={{ display: 'contents' }}>
                                        {
                                        (venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_src} alt="" 
                                        onError={(e: any) => e.target.src = venue.image_url}
                                        />
                                        }
                                        {
                                        !(venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_url} alt="" 
                                        />
                                        }
                                        <div className="gridviewcardimagecover">
                                           
                                            <div className="gridviewbottomcontainer19">               
                                               
                                                    {
                                                        venue.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name}</div>
                                                    }
                                                    {
                                                        venue.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name_short}</div>
                                                    }
                                              
                                            </div>
                                        </div>
                                    </Link>   
                                        </div>

                                    })
                                }
                            </div>
                            }

                       

                            <div className="windowmainsetwidth">

                            <div className="windowmainboxstd">
                            {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty.venue).length === 0
                            &&
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            }
                            {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty.venue).length === 0
                            &&
                            <div className="windowmainvenuecardbox">
                                {/* <div className="windowvenueleft">      
                                    {
                                    selectedParty.venue !== ""
                                    &&
                                    <div className="addmgb">
                                        <span className="addresshasvenuename">{selectedParty.venue}</span>
                                    </div>
                                    }
                                    {selectedParty.suite_number === "" ? "" : <div>{selectedParty.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {selectedParty.street_number === "" ? <div>{selectedParty.street_name}</div> : <div className="limitaddresstooneline">{selectedParty.street_number}{" "}{selectedParty.street_name}</div>}
                                    </div>
                                    {selectedParty.city !== "London" ? <div>{selectedParty.suburb} {selectedParty.postcode}</div> : <div><div>{selectedParty.suburb}</div><div> {selectedParty.postcode}</div></div>}
                                </div> */}

                                    <div className="windowvenueleft">      
                                            {
                                            selectedParty.venue !== "" &&
                                            <div className="addmgb">
                                                <span className="addresshasvenuename">{selectedParty.venue}</span>
                                            </div>
                                            }
                                            
                                            
                                                {/* Conditional logic for suite number placement */}
                                                {
                                                    selectedParty.suite_number !== "" && /^[A-Za-z]/.test(selectedParty.suite_number) ? (
                                                        // If suite_number contains letters, show it on a new line
                                                        <div>{selectedParty.suite_number}</div>
                                                    ) : (
                                                        // If suite_number is numbers only, concatenate it with street_number
                                                        selectedParty.street_number !== "" && selectedParty.suite_number !== "" ? (
                                                            <div className="limitaddresstooneline">
                                                                {selectedParty.suite_number}/{selectedParty.street_number}{" "}{selectedParty.street_name}
                                                            </div>
                                                        ) : (
                                                            // Fallback if there's no suite number
                                                            selectedParty.street_number !== "" ? (
                                                                <div className="limitaddresstooneline">
                                                                    {selectedParty.street_number}{" "}{selectedParty.street_name}
                                                                </div>
                                                            ) : (
                                                                <div>{selectedParty.street_name}</div>
                                                            )
                                                        )
                                                    )
                                                }
                                      

                                            {/* Display city, suburb, and postcode */}
                                            {selectedParty.city !== "London" ? (
                                                <div>{selectedParty.suburb} {selectedParty.postcode}</div>
                                            ) : (
                                                <div>
                                                    <div>{selectedParty.suburb}</div>
                                                    <div>{selectedParty.postcode}</div>
                                                </div>
                                            )}
                                        </div>


                                
                                <div className="windowvenueright">  
                                    {/* <img
                                    src={mapimage}
                                    alt=""
                                    className="windowvenueimg" 
                                    onClick={() => setIsWindowMapOpenP(true)}
                                    /> */}
                                            <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenP(true)}>
                                                MAP
                                            </div>
                                </div>
                            </div>
                            }    
                        {/* </div> */}
                        </div>
                        
                        {
                        (selectedParty.entertainers !== "")
                        &&
                        props.peopleOth.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                        &&
                        <div className="windowmainheadingstd">
                            Featured entertainers
                        </div>
                        }

                        {/* end of set width main box */}
                    </div>

                    {/* can i move the below calcs up or some of them and memoize? */}

                    {
                    (selectedParty.entertainers !== "")
                    // &&
                    // props.peopleOth.filter((person: any) => selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                    &&
                    <div className="gridviewgridBC mgab">
                        {props.peopleOth.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name))
                        .map((person: any, index: any) => {
                            return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardstd mgbel">
                                            {
                                                person.image_src
                                                &&
                                                <img className="gridviewcardimage" src={person.image_src} alt=""
                                                onError={(e: any) => e.target.src = person.image_url}
                                                />
                                            }
                                            {
                                                !person.image_src
                                                &&
                                                <img className="gridviewcardimage" src={person.image_url} alt="" />
                                            }
                                            
                                            <div className="gridviewcardimagecoverppl">
                                                {/* {
                                                person.type[0] === "Drag"
                                                &&
                                                <div className="newtypecont">Drag</div>
                                                }      
                                                {
                                                person.type[0] === "DJ"
                                                &&
                                                <div className="newtypecont">DJ</div>
                                                }         
                                                {
                                                person.type[0] === "Singer"
                                                &&
                                                <div className="newtypecont">Singer</div>
                                                }          
                                                {
                                                person.type[0] === "Other"
                                                &&
                                                <div className="newtypecont">Dancer</div>
                                                } */}
                                                <div className="newtypecont">{person.type[0]}</div>
                                                
                                                <div className="gridviewbottomcontainer19">               
                                        
                                                        
                                                            <div className="gridviewtitlefull">
                                                                {person.name}
                                                            </div>
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                        })}
                    </div>
                    }

                        

                 

                    <div className="windowmainsetwidth">
                    {
                        (selectedParty.entertainers !== "")
                        &&
                        selectedParty.entertainers.filter((entertainer:any) => !props.peopleOth.some((person:any) => entertainer === person.name)).length > 0
                        &&
                    <div className="windowmainboxstd">
                        <div className="windowmainheadingstd">
                            {props.peopleOth.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0 ? 'Other entertainers' : 'Entertainers'}
                        </div>
                        
                      
                            <div className="windowmaintextboxstd">
                                {
                                selectedParty.entertainers.filter((entertainer:any) => !props.peopleOth.some((person:any) => entertainer === person.name)).join(", ")
                                }
                            </div>
                    </div>
                        }
                   
                        
                        


                        <div className="windowdatetimecont">
                            {/* using the above because same properties and values */}
                            {
                            selectedParty.organiser !== ""
                            &&
                            <div
                            style={selectedParty.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                            >
                                <div className="windowmainheadingstd">
                                    Organiser
                                </div>
                                <div className="windowmaintextboxstd extrapadorg">
                                    {selectedParty.organiser}
                                </div>
                            </div>
                            }
                            {
                            selectedParty.ticket_provider !== ""
                            &&
                            <div
                            style={selectedParty.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                            >
                                <div className="windowmainheadingstd">
                                    Ticket vendor
                                </div>
                                <div className="windowmaintextboxstd">
                                    {selectedParty.ticket_provider}
                                </div>
                            </div>
                            }
                        </div>

                        {
                        (selectedParty.price !== 0 && selectedParty.tickets_available
                            && !selectedParty.force_more_info
                            // && selectedParty.price_type !== "more"
                            // &&
                            // !selectedParty.book
                        )
                        &&
                        <div className="floatingbuttoncont2">

                           

                            {
                                selectedParty.price !== selectedParty.price_lower
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Price range</div>
                                <div className="newpricefree">{selectedParty.currency_symbol}{selectedParty.price_lower} - {selectedParty.price}</div>
                            </div>
                            }
                            {
                                selectedParty.price === selectedParty.price_lower
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Tickets from</div>
                                <div className="newpricefree">{selectedParty.currency_symbol}{selectedParty.price}</div>
                            </div>
                            }
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLink}
                                >
                                    
                                    {selectedParty.book ? "BOOK" : "TICKETS"}
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                    
                                </div>

                            </div>

                        </div>
                        }
                        
                        {
                        // ((selectedParty?.price === 0 || !selectedParty?.tickets_available || selectedParty?.price === 999 || selectedParty?.price_type === "more") && !selectedParty?.global)
                        (selectedParty.price === 0 && selectedParty.tickets_available
                            && !selectedParty.force_more_info
                            // && !selectedParty.book
                        )
                        &&
                        
                        <div className="floatingbuttoncont2">

                          

                            <div className="btnsect1free">
                                <div className="newpriceheadfree">This event is</div>
                                <div className="newpricefree">FREE</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLink}
                                >
                                    
                                        {selectedParty.book ? "BOOK" : "INFO"}
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                    
                                </div>

                            </div>

                            
                            
                        
                        </div>
                        }

                        {
                            false
                            &&
                            selectedParty.tickets_available
                            &&
                            !selectedParty.force_more_info
                            &&
                        selectedParty.book
                        &&
                        <div className="floatingbuttoncont2">

                           

                       

                            {
                                selectedParty.price !== selectedParty.price_lower
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Price range</div>
                                <div className="newpricefree">{selectedParty.currency_symbol}{selectedParty.price_lower} - {selectedParty.price}</div>
                            </div>
                            }
                            {
                                selectedParty.price === selectedParty.price_lower
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">From</div>
                                <div className="newpricefree">{selectedParty.currency_symbol}{selectedParty.price}</div>
                            </div>
                            }

                                {/* <div className="btnsect1free">
                                    <div className="newpriceheadfree">Price range</div>
                                    <div className="newpricefree">{selectedParty.currency_symbol}{selectedParty.price} - $45</div>
                                </div> */}
                                <div className="btnsect2">
                                    <div className="mainwindowbtngreen"
                                    onClick={handleClickLink}
                                    >
                                        
                                            BOOK
                                            {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        
                                    </div>

                                </div>

                        </div>
                        }

                        {
                        // ((selectedParty?.price === 0 || !selectedParty?.tickets_available || selectedParty?.price === 999 || selectedParty?.price_type === "more") && !selectedParty?.global)
                        !selectedParty.tickets_available
                            && !selectedParty.force_more_info
                          
                        &&
                        
                        <div className="floatingbuttoncont2">

                            

                            <div className="btnsect1free">
                                <div className="newpriceheadfree">This event is</div>
                                <div className="newpricesoldout">SOLD OUT</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtnred"
                                onClick={handleClickLink}
                                >
                                    
                                        INFO
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrowrd" />
                                </div>

                            </div>

                            
                            
                        
                        </div>
                        }

                        {
                        // ((selectedParty?.price === 0 || !selectedParty?.tickets_available || selectedParty?.price === 999 || selectedParty?.price_type === "more") && !selectedParty?.global)
                     
                          selectedParty.force_more_info
                          
                        &&
                        
                        <div className="floatingbuttoncontonething">

                           

                    
                                <div className="btnsectonething">                       
                                        <div className="mainwindowbtngreen"
                                            onClick={handleClickLink}
                                            >
                                            
                                                MORE INFO
                                                <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                            
                                        </div>
                                </div>

                        

                            
                            
                        
                        </div>
                        }
                        
                    </div>
                    <div className="spaceforbtn"></div>
                </div>               
            </IonContent>
                ) : (
                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonBackButton defaultHref="/" text="">
                            </IonBackButton>
                        </IonButtons>
                        </div>
                        <div className="innerwindowflex">
                            <div className="notfoundmsg">
                            This event has either finished or been removed. Please click the back button.
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </IonPage>
    )
}

export default PartyWindow;