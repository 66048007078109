// useNetworkStatus.js
import { useState, useEffect } from 'react';
import { Network } from '@capacitor/network';

const useNetworkStatus = () => {
  const [isConnected, setIsConnected] = useState(true);
  const [showToast, setShowToast] = useState(false);

  // useEffect(() => {
  //   const checkNetwork = async () => {
  //     const status = await Network.getStatus();
  //     setIsConnected(status.connected);
  //     if (!status.connected) {
  //       setShowToast(true);
  //       setTimeout(() => setShowToast(false), 3000);
  //     }
  //   };

  //   checkNetwork();

  //   const handleNetworkChange = (status: any) => {
  //     setIsConnected(status.connected);
  //     if (!status.connected) {
  //       setShowToast(true);
  //       setTimeout(() => setShowToast(false), 3000);
  //     }
  //   };

  //   const addNetworkListener = async () => {
  //     const listener = await Network.addListener('networkStatusChange', handleNetworkChange);
  //     return listener;
  //   };

  //   let networkListener: any;

  //   const setupListener = async () => {
  //     networkListener = await addNetworkListener();
  //   };

  //   setupListener();
    
    
  //   return () => {
  //     if (networkListener) {
  //       networkListener.remove();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const handleNetworkStatus = (connected: boolean) => {
      setIsConnected(connected);
      if (!connected) {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
      }
    };

    const checkNetwork = async () => {
      const status = await Network.getStatus();
      handleNetworkStatus(status.connected);
    };

    // Initial check
    checkNetwork();

    // Listen to network status changes
    const setupListener = async () => {
      const listener = await Network.addListener('networkStatusChange', (status) => {
        handleNetworkStatus(status.connected);
      });
      return listener;
    };

    let networkListener: any;

    setupListener().then((listener) => {
      networkListener = listener;
    });

    return () => {
      if (networkListener) {
        networkListener.remove();
      }
    };
  }, []);

  return { isConnected, showToast };
};

export default useNetworkStatus;