import mapboxgl from 'mapbox-gl';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import {
    // IonPage,
    IonContent,
    // IonHeader,
    // IonToolbar,
    // IonButtons,
    // IonBackButton,
    // IonGrid,
    // IonRow,
    // IonCol,
    // IonCard,
    IonIcon,
    // IonModal,
    // IonBackdrop
} from '@ionic/react';

import { arrowForwardOutline } from 'ionicons/icons';

import 'mapbox-gl/dist/mapbox-gl.css';

import '../theme/maprelated.css';
import '../theme/spotcards.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const MarkerCF: React.FC<{
    onClick: any;
    // zoomLevel: any;
    // children: any;
    feature: any;
}> = ({
    onClick,
    // zoomLevel,
    // children,
    feature }) => {
    // const _onClick = () => {
    //   onClick(feature.properties.description);
    // };

    return (
      <button onClick={onClick}
      className="marker2">
        {/* {children} */}
            <div>{feature.properties.description}</div>
      </button>
    );
  };

//   i think tomorrow go back to default (blue) markers and have the flyto funcction in the pop up menu. also when you click open a popup.


const MapViewMultiCF: React.FC<{
    // BCView: any;
    filteredBarsAndClubs: any;
    filteredGeoJSONBC: any;
    // cityCoordinates: any;
    // cityZoom: any;
    // citySelected: any;
    // rootsContainingMarkers: any;
    // setRootsContainingMarkers: any;
    // fromCirc: any;
    pride: boolean;
}> = ( {
    // BCView,
    filteredBarsAndClubs,
    filteredGeoJSONBC,
    // cityCoordinates,
    // cityZoom,
    // citySelected,
    // rootsContainingMarkers,
    // setRootsContainingMarkers
    // fromCirc
    pride
} ) => {

    const mapContainerCFMult = useRef<any>(null);

    const [myMapCF, setMyMapCF] = useState<any>(null);

    const [rootsContainingMarkers, setRootsContainingMarkers] = useState<any>(null);

    const [myMapboxMarkers, setMyMapboxMarkers] = useState<any>(null);

    const [zoomClick, setZoomClick] = useState<any>(8);
    


    useEffect(() => {
        
        // const zoomlvl = fromCirc ? 1 : cityZoom;
        // const coords = fromCirc ? [-44.41952708361774, 35.16391785925185] : cityCoordinates;
        // const zoomlvl = 1;
        // const coords = [-44.41952708361774, 35.16391785925185];

        const mapBCMult = new mapboxgl.Map({
            container: mapContainerCFMult.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [-44.41952708361774, 35.16391785925185],
            zoom: 1,
            attributionControl: false
        });

        // var zoomLevel;

        // mapBCMult.on('zoom', () => {
        //     zoomLevel = mapBCMult.getZoom();
        //     console.log(zoomLevel);
        // });

        setMyMapCF(mapBCMult);
        
        return () => mapBCMult.remove();

    },[]);

    useEffect (() => {

        if (myMapCF === null) {
            // console.log(filteredGeoJSONBC, "my map is null");
            return;
        }

        if (myMapCF === undefined) {
            // console.log(filteredGeoJSONBC, "my map is undefined");
            return;
        }

        if (!filteredGeoJSONBC) {
            // console.log(filteredGeoJSONBC, "no filtered geojson");
            return
        }

        // if (myMap === undefined) {
        //     console.log(filteredGeoJSONBC, "my map is undefined");
        //     return;
        // }

        // why do i need if statements

        if (myMapboxMarkers) {
            myMapboxMarkers.forEach((marker: any) => marker.remove());
            setMyMapboxMarkers(null);
        }

        if (rootsContainingMarkers) {
            rootsContainingMarkers.forEach((rootparam: any) => rootparam.unmount());
            setRootsContainingMarkers(null);
        }

        // is the above the only way to remove these things... like theres no point having state if its going to be wiped on each reload..

        const arrayOfMapBoxMarkers: any = [];
        const arrayOfRoots: any = [];

        const zoomlvl = 8;

            // Render custom marker components
            filteredGeoJSONBC.features.forEach((feature: any) => {
                // Create a React ref
                // const ref = useRef<any>(null);
                // Create a new DOM node and save it to the React ref
                // const ref = React.createRef<any>();

                const el = document.createElement("div");
                el.className = 'marker';
                // Render a Marker Component on our new DOM node
                const root = createRoot(el);
                arrayOfRoots.push(root);
                root.render(
                <MarkerCF onClick={()=>myMapCF.flyTo({center: feature.geometry.coordinates, zoom: zoomlvl})} feature={feature} />);

                // Create a Mapbox Marker at our new DOM node
                const mapboxMarker = new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .addTo(myMapCF);

                arrayOfMapBoxMarkers.push(mapboxMarker);

        });

        setMyMapboxMarkers(arrayOfMapBoxMarkers);
        setRootsContainingMarkers(arrayOfRoots);
        // console.log(arrayOfMapBoxMarkers, 'what do mapbox markers look like');
        // console.log(arrayOfRoots, 'what do roots look like');

        return () => {
            // console.log('map removed big markers');
            // mapBCMult.remove();
            if (myMapboxMarkers) {
                myMapboxMarkers.forEach((marker: any) => marker.remove());
                setMyMapboxMarkers(null);
            }
    
            if (rootsContainingMarkers) {
                rootsContainingMarkers.forEach((rootparam: any) => rootparam.unmount());
                setRootsContainingMarkers(null);
            }
        }

    }, [myMapCF, filteredGeoJSONBC])



// so there was an issue switching between cities while the map was open because this would try to load filteredgeojson of previous city. so ive
// set a conditional in bars and clubs which says unless the first filered bar or club has a city name of sydney then dont render the map
// it works but obviously not the best way to do it... 
// so why didnt i need that this time...



    // useEffect(() => {
    //     console.log('my map is this changing so')
    // }, [myMapCF]);

    return (
        <div className="BCmapmain">
            <div className="mapboxstyle">
            {
                filteredBarsAndClubs.length === 0
                &&
                <div className="mapblank">
                    <div className="mapmsg">No festivals found.</div>
                </div>
            }
                <div ref={mapContainerCFMult} className="mapcontainer" />
            </div>
            {
                filteredBarsAndClubs.length > 0
                &&
            <div className="mapviewcontainerBC">
                <IonContent className="mapioncontent">
                            {
                                filteredBarsAndClubs?.map((barclub: any, index: any) => {
                                    return <div
                                            className="mapspotcard"
                                            key={index}
                                            onClick={()=>myMapCF.flyTo({center: [barclub.lng, barclub.lat], zoom: zoomClick})}
                                            >   
                                        <div className="mapspotsecondsect">
                                            <div className="mapspotcirccont3">
                                                <div className="mapspotcirc3">{barclub.order}</div>
                                            </div>
                                                
                                            {/* {
                                                barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_src} alt=""
                                                onError={(e: any) => e.target.src = barclub.image_url}
                                                ></img>
                                            }
                                            {
                                                !barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_url} alt=""
                                                ></img>
                                            } */}
                                        </div>
                                        {/* <div className="mapspotsecondsect">
                                            {
                                                barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_src} alt=""
                                                onError={(e: any) => e.target.src = barclub.image_url}
                                                ></img>
                                            }
                                            {
                                                !barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_url} alt=""
                                                ></img>
                                            }
                                        </div> */}
                                        <div className="mapspotmainsect">
                                            <div className="mapspotmainsectinner">
                                                <div className="mapspotmainsectinnert">{barclub.name}</div>
                                                {/* {
                                                    !fromCirc
                                                    &&
                                                    <div className="searchspotmainsectinnerothb addcut">{barclub.suburb}</div>
                                                } */}
                                                {/* {
                                                    fromCirc
                                                    &&
                                                    <div className="searchspotmainsectinnerothb addcut">{barclub.city}{", "}{barclub.country}</div>
                                                } */}
                                              
                                                    <div className="searchspotmainsectinnerothb addcut">{barclub.city}{", "}{barclub.country === "United States" ? "U.S." : barclub.country}</div>
                                                
                                            </div>
                                        </div>
                                        {/* {
                                                    !fromCirc
                                                    &&
                                        <div className="mapspotlastsection">
                                            <Link to={`/barsandclubs/${barclub.id}`} style={{ display: 'contents' }} onClick={(e) => e.stopPropagation()}>
                                                    <div className="mapspotarrowcont">
                                                        <IonIcon icon={arrowForwardOutline} className="mapspotarrow" />
                                                    </div>
                                            </Link>
                                        </div>
                                        } */}
                                         
                                        <div className="mapspotlastsection">
                                            <Link to={pride ? `/pride/${barclub.id}` : `/circuit/${barclub.id}`} style={{ display: 'contents' }} onClick={(e) => e.stopPropagation()}>
                                                    <div className="mapspotarrowcont">
                                                        <IonIcon icon={arrowForwardOutline} className="mapspotarrow" />
                                                    </div>
                                            </Link>
                                        </div>
                                        
                                    </div>
                                })
                            }
                </IonContent>
            </div>
            }
        </div>
    );
}

export default MapViewMultiCF;