import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    // IonHeader,
    IonModal,
    IonMenuButton,
    IonToast,
    IonLoading,
    IonTextarea
} from '@ionic/react';

import { 
    globeOutline,
    callOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    // alertCircle,
    // shareOutline,
    alertCircleOutline,
    starOutline,
    star,
    chevronBack,
    // phonePortrait
} from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import { database } from '../lib/init-firebase';
import { ref, update, push } from 'firebase/database';


import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';

const MIN_LOADING_DURATION = 1000; // 1 second in milliseconds


const BarAndClubWindow: React.FC<{
    isWeb: any;
    gridNo: any;
    barsAndClubs: any[];
    parties: any[];
    currentDateAndTime: any;
    currentDateAndTimePlusTwoWeeks: any;
    likedBarsAndClubs: any;
    setLikedBarsAndClubs: any;
    handleIncrement: any;
    handleDecrement: any;
}> = (props) => {

    const selectedBarClubId = useParams<{ id: string }>().id;
    const selectedBarClub = props.barsAndClubs.find(barclub => barclub.id === selectedBarClubId);
    // console.log(selectedBarClub, 'selected bc');
    const [isModalRv, setIsModalRv] = useState(false);
    const [messageRv, setMessageRv] = useState('');

  const [showLoadingRv, setShowLoadingRv] = useState(false);
  const [showToastRv, setShowToastRv] = useState(false);
  const [toastMessageRv, setToastMessageRv] = useState('');
  const [toastColorRv, setToastColorRv] = useState('');

  const [ratingRv, setRatingRv] = useState<any>(0);


    // theres no reload in bars and clubs so current date plus two weeks would depend on poeple relaoding .. but i assume given its two weeks it wont ever get that off..like would app reload are a certain period

    const [isWindowMapOpenBC, setIsWindowMapOpenBC] = useState<any>(false);
    const [isWindowMapLoadedBC, setIsWindowMapLoadedBC] = useState<any>(false);
    // const [myMapBC, setMyMapBC] = useState<any>(null);
    //why dont i use myMapP? ive got rid of setting it for the moment.

    const modalMapBC= useRef<HTMLIonModalElement>(null);
    const modalAddReview = useRef<HTMLIonModalElement>(null);

    const mapContainerBC = useRef<any>(null);

    const handleLikeBarOrClubW = (barclubhash: any) => {
        // event.preventDefault();
        props.setLikedBarsAndClubs((prev: any)=>[...prev, barclubhash]);
        props.handleIncrement("venues", barclubhash);
      }
    
      const handleUnlikeBarOrClubW = (barclubhash: any) => {
        // event.preventDefault();
        props.setLikedBarsAndClubs(props.likedBarsAndClubs.filter((BClikedhash:any) => BClikedhash !== barclubhash));
        props.handleDecrement("venues", barclubhash);
      }

      const closeRv = () => {
        setIsModalRv(false);
        setMessageRv('');
      }
      
      
      const addRev = async () => {
        const startTime = Date.now();
        setShowLoadingRv(true);
      
        try {
          // const db = getDatabase();
          const feedbackRef = ref(database, 'data/reviews/venues');
          
          const newFeedbackKey = push(feedbackRef).key;
          
          const feedbackData = {
            id: newFeedbackKey,
            venue_id: selectedBarClub.id,
            venue_name: selectedBarClub.name,
            venue_city: selectedBarClub.city,
            review: messageRv.trim(),
            rating: ratingRv,
            timestamp: Date.now()
          };
      
          const updates: { [key: string]: any } = {};
          updates[`data/reviews/venues/${newFeedbackKey}`] = feedbackData;
          await update(ref(database), updates);
      
          setToastMessageRv('Review submitted successfully!');
          setToastColorRv('success');
          // setMessage('');
        } catch (error) {
          console.error("Error submitting review:", error);
          setToastMessageRv('Failed to submit review. Please try again.');
          setToastColorRv('danger');
        } finally {
          const endTime = Date.now();
          const elapsedTime = endTime - startTime;
          if (elapsedTime < MIN_LOADING_DURATION) {
            await new Promise(resolve => setTimeout(resolve, MIN_LOADING_DURATION - elapsedTime));
          }
          setShowLoadingRv(false);
          setIsModalRv(false);
          setMessageRv('');
          setShowToastRv(true);
        }
      };

    useEffect(() => {

        if (!mapContainerBC.current) {
            return
        }

        const mapBC = new mapboxgl.Map({
            container: mapContainerBC.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedBarClub?.lng, selectedBarClub?.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedBarClub?.lng, selectedBarClub?.lat]).addTo(mapBC);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapBC(mapBC);

        return () => mapBC.remove();

    },[isWindowMapLoadedBC]);

    const handleDismissWindowMapModalBC = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because...
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedBC(false);
        setIsWindowMapOpenBC(false);
    }

    return(
        <IonPage>
            {
                selectedBarClub ? (
            <IonContent >
                <IonModal
                ref={modalAddReview}
                // id="example-modalPartiesNotifications"
                isOpen={isModalRv}
                onDidDismiss={closeRv}
                className="notifmodal2"
                >
                  <IonToolbar className="notiftoolbar">
                              <IonButtons slot="secondary">
                                      <IonButton
                                      // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolr"}
                                    onClick={closeRv}
                                    // disabled={showLoading || message.trim() === ''}
                                    className="btnstw"
                                    >
                          
                                      Close
                                      {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                                  
                                  </IonButton>
                              </IonButtons>
                      <div className="notifheader">Review Venue</div>
                      <IonButtons slot="primary">
                              <IonButton
                              // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolp"}
                              onClick={addRev}
                              disabled={showLoadingRv || messageRv.trim() === '' || ratingRv === 0}
                              className="btnstw"
                              >
                            
                                Submit
                                {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                            
                            </IonButton>
                      </IonButtons>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">


                    <div className="notifgradient">
                        <div className="ratingbox">
                            <IonIcon icon={ratingRv >= 1 ? star : starOutline} className="staremp" onClick={()=>setRatingRv(1)} />
                            <IonIcon icon={ratingRv >= 2 ? star : starOutline} className="staremp" onClick={()=>setRatingRv(2)} />
                            <IonIcon icon={ratingRv >= 3 ? star : starOutline} className="staremp" onClick={()=>setRatingRv(3)} />
                            <IonIcon icon={ratingRv >= 4 ? star : starOutline} className="staremp" onClick={()=>setRatingRv(4)} />
                            <IonIcon icon={ratingRv >= 5 ? star : starOutline} className="staremp" onClick={()=>setRatingRv(5)} />
                        </div>

                      <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={11}
                                value={messageRv}
                                onIonInput={(e:any)=>setMessageRv(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please share your thoughts on the venue. What did you like? What could be improved? The response will remain anonymous."
                                disabled={showLoadingRv}
                                />
                                <div className="plslimit">Please limit the response to 300 characters.</div>
                      </div>

                      <div className="btnsfeedback">
                            
                          
                        </div>

                                  
                    </div>

                                    

                              

                    </IonContent>
                              <IonLoading
                                isOpen={showLoadingRv}
                                message={'Submitting review...'}
                                className="custom-loading"
                              />
        </IonModal>


                <IonToast
                                isOpen={showToastRv}
                                onDidDismiss={() => setShowToastRv(false)}
                                message={toastMessageRv}
                                duration={3000}
                                color={toastColorRv}
                              />
                <IonModal
                ref={modalMapBC}
                className="windowmapmodal"
                isOpen={isWindowMapOpenBC}
                onDidDismiss={handleDismissWindowMapModalBC}
                onDidPresent={() => setIsWindowMapLoadedBC(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerBC} className="mapcontainerinner" />
                    </div>
                </IonModal>

                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="ionbackbuttoncontoth">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                    </div>
                    {  
                    
                        !props.isWeb
                        &&               
                    props.likedBarsAndClubs.filter((likedBChash: any) => likedBChash === selectedBarClub.hash).length === 0
                    &&
                    <div
                    className="windowheartcont"
                    // onClick={()=>props.setLikedBarsAndClubs((prev: any)=>[...prev, selectedBarClub.hash])}
                    onClick={() => handleLikeBarOrClubW(selectedBarClub.hash)}
                    >
                        <IonIcon icon={heartOutline} color="danger" className="windowheart" />
                    </div>
                    }
                    {
                        
                            !props.isWeb
                            &&
                    props.likedBarsAndClubs.filter((likedBChash: any) => likedBChash === selectedBarClub.hash).length > 0
                    &&
                    <div
                    className="windowheartcont"
                    // onClick={()=>props.setLikedBarsAndClubs(props.likedBarsAndClubs.filter((likedBChash: any) => likedBChash !== selectedBarClub.hash))}
                    onClick={() => handleUnlikeBarOrClubW(selectedBarClub.hash)}
                    >
                        <IonIcon icon={heartSharp} color="danger" className="windowheart" />
                        {/* <div className="windowheartlit"></div> */}
                    </div>
                    }

                    <div className="windowtopcont">
                        {
                            selectedBarClub.image_src
                            &&
                            <img className="windowimgbox" src={selectedBarClub.image_src} alt=""
                            onError={(e: any) => e.target.src = selectedBarClub.image_url}

                            ></img>

                        }
                        {
                            !selectedBarClub.image_src
                            &&
                            <img className="windowimgbox" src={selectedBarClub.image_url} alt=""
                            ></img>

                        }
                        <div className="windowimggrad">
                            <div className="windowtitlebox">
                                <div className="windowtitlefull">
                                    {selectedBarClub.name}
                                </div>
                            </div>

                        </div>
                    </div>
                    

                    <div className="windowmainsetwidth">
                    {
                        props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                        &&
                            <div className="windowmainheadingstd pinkfont">
                                    <div className="livenow">LIVE NOW</div>
                                    {/* <div className="livelines">
                                        <div className="livelinet"></div>
                                        <div className="livelineb"></div>
                                    </div> */}
                            </div>
                    }
                    </div>

                    {
                            props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                            &&
                            <div className="gridviewgridBC">
                                {
                                    props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime)))
                                    .map((party: any, index: number) => {
                                        return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardBC">
                                            
                                            {
                                            (party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_src} alt=""
                                            onError={(e: any) => e.target.src = party.image_url}

                                            />   

                                        }
                                        {
                                            !(party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_url} alt=""
                                            />   

                                        }

                                            <div className="gridviewcardimagecover">
                                            
                                                          
                                                <div className="newgridcaltt">
                                                        <div className="livefromnewoth">ends</div>
                                                        
                                                        <div className="colorw">{party.finish_time}</div>

                                                </div>

                                                <div className="gridviewbottomcontainer19">
                                                    
                                                    {
                                                        party.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{party.name}</div>
                                                    }
                                                    {
                                                        party.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{party.name_short}</div>
                                                    }
                                                    
                                               
                                                </div>

                                                           
    
                                            
                                                    </div>
                                                    </div>
                                            </Link>   
                                        

                                    })
                                }
                            </div>
                            }


                       

                        <div className="windowmainsetwidth">

                        

                        {
                        (selectedBarClub.description !== "" || selectedBarClub.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="addflx">
                                <div className="windowmainheadingrv">
                                    About
                                </div>
                                <div className="viewmapbtnpurp"
                                // onClick={() => setIsWindowMapOpenCruises(true)}
                                onClick={() => setIsModalRv(true)}
                                >REVIEW</div>
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedBarClub.description}
                            </div>
                     

                            {
                                (selectedBarClub.extra_info !== "")
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedBarClub.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedBarClub.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>

                            }
                        </div>
                        }

                        

                        


                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                            Opening hours
                            </div>
                            <div className="openinghoursbox">
                            <div className="timetablebox">
                                <div className="">
                                    <div className="weekday">
                                        Monday
                                    </div>
                                    <div className="weekday">
                                        Tuesday
                                    </div>
                                    <div className="weekday">
                                        Wednesday
                                    </div>
                                    <div className="weekday">
                                        Thursday
                                    </div>
                                    <div className="weekday">
                                        Friday
                                    </div>
                                    <div className="weekday">
                                        Saturday
                                    </div>
                                    <div className="weekday">
                                        Sunday
                                    </div>
                                </div>
                                <div className="BCtimes">
                                    
                                    {
                                    selectedBarClub.monday_open !== ""
                                    &&
                                    !(selectedBarClub.monday_open === "12am" && selectedBarClub.monday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.monday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.monday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.monday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.monday_open === "12am" && selectedBarClub.monday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.tuesday_open !== ""
                                    &&
                                    !(selectedBarClub.tuesday_open === "12am" && selectedBarClub.tuesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.tuesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.tuesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.tuesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.tuesday_open === "12am" && selectedBarClub.tuesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.wednesday_open !== ""
                                    &&
                                    !(selectedBarClub.wednesday_open === "12am" && selectedBarClub.wednesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.wednesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.wednesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.wednesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.wednesday_open === "12am" && selectedBarClub.wednesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.thursday_open !== ""
                                    &&
                                    !(selectedBarClub.thursday_open === "12am" && selectedBarClub.thursday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.thursday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.thursday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.thursday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.thursday_open === "12am" && selectedBarClub.thursday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.friday_open !== ""
                                    &&
                                    !(selectedBarClub.friday_open === "12am" && selectedBarClub.friday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.friday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.friday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.friday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.friday_open === "12am" && selectedBarClub.friday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.saturday_open !== ""
                                    &&
                                    !(selectedBarClub.saturday_open === "12am" && selectedBarClub.saturday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.saturday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.saturday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.saturday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.saturday_open === "12am" && selectedBarClub.saturday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedBarClub.sunday_open !== ""
                                    &&
                                    !(selectedBarClub.sunday_open === "12am" && selectedBarClub.sunday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.sunday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            <div className="tinyball"></div>
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedBarClub.sunday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedBarClub.sunday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedBarClub.sunday_open === "12am" && selectedBarClub.sunday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>

                        {
                        selectedBarClub.price_info !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Entrance Fee
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedBarClub.price_info}
                            </div>
                        </div>
                        }

                        {
                        selectedBarClub.happy_hour !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Happy Hour
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedBarClub.happy_hour}
                            </div>
                        </div>
                        }

                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            <div className="windowmainvenuecardbox">
                                <div className="windowvenueleft">
                                {selectedBarClub.suite_number === "" ? "" : <div>{selectedBarClub.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {selectedBarClub.street_number === "" ? <div>{selectedBarClub.street_name}</div> : <div className="limitaddresstooneline">{selectedBarClub.street_number}{" "}{selectedBarClub.street_name}</div>}
                                        {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                    </div>
                                    {selectedBarClub.city !== "London" ? <div>{selectedBarClub.suburb} {selectedBarClub.postcode}</div> : <div><div>{selectedBarClub.suburb}</div><div> {selectedBarClub.postcode}</div></div>}
                                </div>
                                <div className="windowvenueright">
                                    {/* <div className="viewreview" onClick={() => setIsWindowMapOpenBC(true)}>
                                        View Map
                                    </div> */}
                                    <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenBC(true)}>
                                        MAP
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="windowmainboxstd">
                            {
                            (selectedBarClub.instagram !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.instagram.com/${selectedBarClub.instagram}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Instagram</div>
                                    <div className="wmtxtb">@{selectedBarClub.instagram}</div>
                                </div>
                                    
                                <div className="wmiconinsta"
                                
                                >
                                        <IonIcon icon={logoInstagram} className="insticn" />
                                </div>
                            </div>
                            }
                            {/* {
                                (selectedBarClub?.facebook !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedBarClub.facebook, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Facebook</div>
                                        <div className="wmtxtb">@{selectedBarClub.facebook.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconfb"
                                    
                                    >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                    </div>
                                </div>
                            } */}
                            {
                            (selectedBarClub.facebook !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.facebook.com/${selectedBarClub.facebook}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Facebook</div>
                                    <div className="wmtxtb">{selectedBarClub.facebook}</div>
                                </div>
                                    
                                <div className="wmiconfb"
                                
                                >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                </div>
                            </div>
                            }
                            {
                                (selectedBarClub.website !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedBarClub.website, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Website</div>
                                        <div className="wmtxtb">{selectedBarClub.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconsc"
                                    // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson.youtube}`, '_system', 'location=yes')}
                                    
                                    >
                                        <IonIcon icon={globeOutline} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                            {
                            (selectedBarClub.phone !== 0 && selectedBarClub.phone !== "")
                            &&
                            <a className="aremove" href={`tel:+${selectedBarClub.phone}`}>
                            <div className="wmcont">
                                <div className="wmflex">
                                    <div className="wmtxt">Phone</div>
                                    <div className="wmtxtb">+{selectedBarClub.phone}</div>
                                </div>
                                
                                    <div className="wmiconph">
                                        <IonIcon icon={callOutline} color="" className="icoth" />
                                    </div>
                                
                                
                            </div>
                            </a>
                            }
                            
                        </div>

                        <div className="windowmainheadingstd">
                            Upcoming events
                        </div>
                           
                        {
                        props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 0
                        &&
                        <div className="windowmaintextboxstd">
                            Please check the venue&apos;s social media accounts or website.
                        </div>
                        }
                    
                    </div> 
                    {/* end of first set width main section */}
                    
                    {
                        (
                            props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length >= 1
                            &&
                    (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 1) && (props.gridNo === 1)
                    ||
                    (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 2) && (props.gridNo === 2)
                    ||
                    (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 3) && (props.gridNo === 3)
                    ||
                    (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 4) && (props.gridNo === 4)
                    ||
                    (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 5) && (props.gridNo === 5)
                        )
                    // &&
                    // props.gridNo >= 2
                    &&
                    <div className="gridviewgridBC">
                        {
                        props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks)
                            .map((party, index) => {
                            return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardBC">
                                            {
                                                party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_src} alt=""
                                                onError={(e: any) => e.target.src = party.image_url}

                                                />
                                            }
                                            {
                                                !party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_url} alt="" />
                                            }
                                            <div className="gridviewcardimagecover">
                                               
                                            <div className="newgridcal">
                                                <div className="topdate">{party.start_date}</div>
                                                
                                                <div className="botdate">{party.start_month.slice(0,3)}</div>

                                            </div>

                                            <div className="gridviewbottomcontainer19">
                                               
                                                {
                                                    party.name_short === ""
                                                    &&
                                                    <div className="gridviewtitlefull">{party.name}</div>
                                                }
                                                {
                                                    party.name_short !== ""
                                                    &&
                                                    <div className="gridviewtitlefull">{party.name_short}</div>
                                                }
                                          
                                              
                                            </div>
                                                
                                            </div>
                                        </div>
                                    </Link>
                            })
                        }
                    </div>
                    }

                    {

                    // (props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > 2 || props.gridNo < 2)
                    props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length >= 1
                    &&
                    props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > props.gridNo
                    &&
                    <div className="swipercontainerstd gvgaddmarginbot">
                        <Swiper
                        slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                        loop={false}
                        modules={[IonicSlides]}
                        // className="swiperaddspace"
                        // above was id before. add to parties swiper too?
                        // spaceBetween={6}
                        slidesOffsetBefore={10}
                        slidesOffsetAfter={10}
                        spaceBetween={2}
                        >
                            {props.parties.filter(party => party.venue === selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks)
                            .map((party, index) => {
                                return <SwiperSlide key={index}>
                                            <div className="swipercard">
                                                    <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                                        {
                                                            party.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={party.image_src} alt=""
                                                            onError={(e: any) => e.target.src = party.image_url}

                                                            />
                                                        }
                                                        {
                                                            !party.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={party.image_url} alt=""
                                                            />
                                                        }
                                                        <div className="gridviewcardimagecover">
                                                            
                                               
                                                            {/* {
                                                            !party.tickets_available
                                                            &&
                                                            <div className="gridviewextrasoldoutbox">
                                                                <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                            </div>
                                                            } */}
                                                            <div className="newgridcal">
                                                                <div className="topdate">{party.start_date}</div>
                                                                
                                                                <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                            </div>

                                                            <div className="gridviewbottomcontainer19">
                                                              
                                                                {
                                                                    party.name_short === ""
                                                                    &&
                                                                    <div className="gridviewtitlefull">{party.name}</div>
                                                                }
                                                                {
                                                                    party.name_short !== ""
                                                                    &&
                                                                    <div className="gridviewtitlefull">{party.name_short}</div>
                                                                }
                                                                
                                                            
                                                            </div>
                                                        </div>
                                                    </Link>
                                                
                                            </div>
                                        </SwiperSlide>
                            })}
                        </Swiper>
                        {/* </div> */}
                    </div>
                    }

                  
                    
                </div>                                 
            </IonContent>
            ) : (
                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                    <IonButtons
                    // slot="start"
                    >
                        <IonBackButton defaultHref="/" text="">
                        </IonBackButton>
                    </IonButtons>
                    </div>
                    <div className="innerwindowflex">
                        <div className="notfoundmsg">
                        This venue has been removed. Please click the back button.
                        </div>
                        
                    </div>
                </div>
            )
        }
        </IonPage>
    )
}

export default BarAndClubWindow;