// add live events to pop up and also sold out. (btw i think live should override sold out right? but could have both.. like if have overlay opacity effect for sold out... what i am doing for grid view?)
// currently dont have conditional for image back up in either cal or pop up
// check whether toolbar scrolls when you have multiple events and then fix per ioncontent page
// havent done image conditionals. need to work out (and this applies to other components) whether im going to move some logic into useeffect
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
    IonContent,
    IonModal,
    IonToolbar,
} from '@ionic/react';

import '../theme/gridview.css';
import '../theme/spotcards.css';
import '../theme/calview.css';


const CalViewParties: React.FC<{
    currentDateAndTime: any;
    dateOfLastParty: any;
    filteredParties: any;
    filteredPartiesInProgressAndToday:any;
    // mardiGras: any;
}> = ( {
    currentDateAndTime,
    dateOfLastParty,
    filteredParties,
    filteredPartiesInProgressAndToday
} ) => {
    
    const [arrayOfMonths, setArrayOfMonths] = useState<any>(null);
    const [dateSelectedCalView, setDateSelectedCalView] = useState<any>({});
    const [isAgendaOpen, setIsAgendaOpen] = useState<any>(false);

    const modalCal = useRef(null);

    const handleOpenAgenda = (dateClicked: any) => {
        if ((dateClicked.getTime() + ( 3600 * 1000 * 24)) <= currentDateAndTime) {
            // console.log(new Date(dateClicked.getTime() + ( 3600 * 1000 * 24)), 'date clicked1');
            // console.log(dateClicked, 'date clicked2');
            // console.log(currentDateAndTime, 'current date');
            return;
        }
        setDateSelectedCalView(dateClicked);
        setIsAgendaOpen(true);
        // console.log(dateClicked, "JU");
    }

    const handleCloseAgenda = () => {
        setIsAgendaOpen(false);
        setDateSelectedCalView({});
    }

    const handleDateForwardOneDay = () => {
        const lastDayOfMonth = new Date(dateOfLastParty.getFullYear(), dateOfLastParty.getMonth() + 1, -1);
        if (dateSelectedCalView > lastDayOfMonth) {
            // console.log(dateSelectedCalView, 'date clicked forward');
            // console.log(lastDayOfMonth, 'first date of next month');
            return;
        }
        const nextDay = new Date(dateSelectedCalView.getTime() + ( 3600 * 1000 * 24));
        setDateSelectedCalView(nextDay);
    }

    const handleDateBackOneDay = () => {
        if (dateSelectedCalView <= currentDateAndTime) {
            // console.log(new Date(dateSelectedCalView.getTime()), 'date clicked1');
            // console.log(dateSelectedCalView, 'date clicked back');
            // console.log(currentDateAndTime, 'current date');
            return;
        }
        const nextDay = new Date(dateSelectedCalView.getTime() - ( 3600 * 1000 * 24));
        setDateSelectedCalView(nextDay);
    }
    
    const createArrayOfMonths = () => {
        const arrayToAdd = [];
        let currentMonth = currentDateAndTime.getMonth();
        let currentYear = currentDateAndTime.getFullYear();
        const endMonth = dateOfLastParty.getMonth() === 11 ? 0 : (dateOfLastParty.getMonth() + 1);
        const endYear = dateOfLastParty.getMonth() === 11 ? (dateOfLastParty.getFullYear() + 1) : dateOfLastParty.getFullYear();
        // console.log(dateOfLastParty.getMonth(), "last date get month before");
        // console.log(dateOfLastParty.getFullYear(), "last date get year before");
        console.log(currentYear, "current year");
        console.log(currentMonth, "current month");
        console.log(endMonth, "current end month");
        console.log(endYear, "current end year");
        while (!(currentMonth === endMonth && currentYear === endYear)) {
            // console.log(dateOfLastParty.getMonth(), "last date get month");
            // console.log(dateOfLastParty.getFullYear(), "last date get year");
            const tempArray = [];
            const date = new Date(currentYear, currentMonth, 1);
            while (date.getMonth() === currentMonth) {
                tempArray.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            const tempArrayForExtraDays = [];
            let start;
            if (tempArray[0].getDay() === 0) {
                start = 6;
            } else {
                start = tempArray[0].getDay()-1;
            }
            // console.log(start, "STARTY")
            for (let i = start; i >= 1; i--) {
                const nextDate = new Date(tempArray[0].getFullYear(),tempArray[0].getMonth(),tempArray[0].getDate() - i);
                // console.log(nextDate, "NEXT DATE")
                tempArrayForExtraDays.push(nextDate);
                // console.log(tempArray, "each iteration");
            }
            const combinedArray = tempArrayForExtraDays.concat(tempArray);
            // console.log(combinedArray, "COMBINED ARRAY");
            arrayToAdd.push(combinedArray);
            currentYear = (currentMonth === 11 ? (currentYear + 1) : currentYear);
            currentMonth = (currentMonth === 11 ? 0 : (currentMonth + 1));
            // console.log(currentMonth, "this is the current month");
        }
        console.log(arrayToAdd, 'herere');
        setArrayOfMonths(arrayToAdd);
    };


    useEffect(()=> {
        createArrayOfMonths();
        // console.log(arrayOfMonths, "ARRAYOFMONTHS");
    }, [currentDateAndTime, dateOfLastParty]);
    // should parties be a dependency as well? depends do I want the months to change?
    
        
    return (
        <React.Fragment>
            <IonModal
            ref={modalCal}
            className="caldatepopupmodal"
            isOpen={isAgendaOpen}
            onDidDismiss={handleCloseAgenda}
            >
                {
                (dateSelectedCalView instanceof Date)
                &&
                // what is above conditional for?
                <>
                    {/* having the content wrap around toolbar may be problematic in that it seems that toolbar is going to scroll */}
                    <IonToolbar className="toolbarcalpopup">
                        <div className="calpopupheadercontainer">
                            <div className="calpopupleft" onClick={handleDateBackOneDay}>
                                {
                                (dateSelectedCalView > currentDateAndTime)
                                &&
                                <div className="calviewchevroncontainerl">
                                    <div className="calviewchevron">^</div>
                                </div>
                                }
                                {
                                (dateSelectedCalView <= currentDateAndTime)
                                &&
                                <div className="calviewchevroncontainerdbl">
                                    <div className="calviewchevron">^</div>
                                </div>
                                }
                            </div>
                            
                            <div className="calpopupheader">
                                {dateSelectedCalView.toLocaleDateString("en-GB", {weekday: "short", year: 'numeric', month: 'short', day: 'numeric'})}
                            </div>
                            
                            <div className="calpopupright" onClick={handleDateForwardOneDay}>
                                {
                                (dateSelectedCalView <= new Date(dateOfLastParty.getFullYear(), dateOfLastParty.getMonth() + 1, -1))
                                &&
                                <div className="calviewchevroncontainerr">
                                    <div className="calviewchevron">^</div>
                                </div>
                                }
                                {
                                (dateSelectedCalView > new Date(dateOfLastParty.getFullYear(), dateOfLastParty.getMonth() + 1, -1))
                                &&
                                <div className="calviewchevroncontainerdbr">
                                    <div className="calviewchevron">^</div>
                                </div>
                                }
                            </div>
                        </div>
                    </IonToolbar>
                    <IonContent fullscreen className="calcontent">
                    {
         
          (
            filteredPartiesInProgressAndToday.length > 0

          )
          &&
          dateSelectedCalView.getDate() === currentDateAndTime.getDate() && dateSelectedCalView.getMonth() === currentDateAndTime.getMonth() && dateSelectedCalView.getFullYear() === currentDateAndTime.getFullYear()
          &&
                        <div className="ultimategridviewcontainer">
                          

{
            filteredPartiesInProgressAndToday.map((partymatch: any, index: number) => {
                                return <Link key={index} to={`/parties/${partymatch.id}`} style={{ display: 'contents' }}>
                                    <div className="calviewspotcard"
                                    onClick={handleCloseAgenda}
                                    >

                                                    <div className="searchspotfirstsection">
                                                        <img className="searchfirstsectimg" src={partymatch.image_src} alt=""></img>
                                                    
                                                    </div>
                                                    <div className="searchspotmainsectionnew">
                                                        
                                                            <div className="searchspotmainsectinneroth2">{partymatch.name}</div>
                                                            {
                                                              (currentDateAndTime >= partymatch.daterealstart)
                                                              &&
                                                              <div className="searchspotmainsectinnerothb2">
                                                                  <div className="addcut">{partymatch.venue}</div>
                                                                  <div className="tinyballw"></div>
                                                                  <div className="rdcol2">LIVE</div>
                                                        
                                                              </div>
                                                            }
                                                            {
                                                              (currentDateAndTime < partymatch.daterealstart)
                                                              &&
                                                              <div className="searchspotmainsectinnerothb2">
                                                                  <div className="addcut">{partymatch.venue}</div>
                                                                  <div className="tinyball"></div>
                                                                  <div className="grncol2">{partymatch.timestring}</div>
                                                        
                                                              </div>
                                                            }
                                                            
                                                    
                                                    </div>
                                        
                                      
                                       
                                    </div>
                                </Link> 
                            }  )}
                        </div>
                    }

{
         
         (
            !(dateSelectedCalView.getDate() === currentDateAndTime.getDate() && dateSelectedCalView.getMonth() === currentDateAndTime.getMonth() && dateSelectedCalView.getFullYear() === currentDateAndTime.getFullYear())
          &&
          
           filteredParties?.filter((ele: any) =>
            (
                // party.daterealstart > currentDateAndTime
                // &&
                ele.daterealstart.getDate() === dateSelectedCalView.getDate()
                && (ele.daterealstart.getMonth() === dateSelectedCalView.getMonth())
                && (ele.daterealstart.getFullYear() === dateSelectedCalView.getFullYear())
                )
        //         &&
        //    (ele.daterealstart > currentDateAndTime)
        //    &&
        //    !(
        //      ele.daterealstart.getMonth() === currentDateAndTime.getMonth()
        //      &&
        //      ele.daterealstart.getDate() === currentDateAndTime.getDate()
        //      && 
        //      ele.daterealstart.getFullYear() === currentDateAndTime.getFullYear()
        //    )
           // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
           ).length > 0
         )
         &&
         
                       <div className="ultimategridviewcontainer">
                         

{
           filteredParties?.filter((ele: any) =>
            (
                // party.daterealstart > currentDateAndTime
                // &&
                ele.daterealstart.getDate() === dateSelectedCalView.getDate()
                && (ele.daterealstart.getMonth() === dateSelectedCalView.getMonth())
                && (ele.daterealstart.getFullYear() === dateSelectedCalView.getFullYear())
                )
        //         &&
        //    (ele.daterealstart > currentDateAndTime)
        //    &&
        //    !(
        //      ele.daterealstart.getMonth() === currentDateAndTime.getMonth()
        //      &&
        //      ele.daterealstart.getDate() === currentDateAndTime.getDate()
        //      && 
        //      ele.daterealstart.getFullYear() === currentDateAndTime.getFullYear()
        //    )
           // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
           )
                           .map((partymatch: any, index: number) => {
                               return <Link key={index} to={`/parties/${partymatch.id}`} style={{ display: 'contents' }}>
                                   <div className="calviewspotcard"
                                   onClick={handleCloseAgenda}
                                   >

                                                   <div className="searchspotfirstsection">
                                                       <img className="searchfirstsectimg" src={partymatch.image_src} alt=""></img>
                                                   
                                                   </div>
                                                   <div className="searchspotmainsectionnew">
                                                       
                                                           <div className="searchspotmainsectinneroth2">{partymatch.name}</div>
                                                           <div className="searchspotmainsectinnerothb2">
                                                               <div className="addcut">{partymatch.venue}</div>
                                                               <div className="tinyball"></div>
                                                               <div className="grncol2">{partymatch.timestring}</div>
                                                           {/* <div className="tinyball3"></div>
                                                           {party.start_time} */}
                                                           </div>
                                                   
                                                   </div>
                                       
                                       {/* <div className="calspotfirstsection">
                                           <img className="calspotfirstsectimg" src={partymatch.image_src} alt=""></img>               
                                       </div>
                                       <div className="calspotmainsection">
                                           <div className="calspotmainsectioninner">
                                               <div className="calspotmainsectinnert">{partymatch.name}</div>
                                               <div className="calspotmainsectinnerb">{partymatch.venue}</div>
                                           </div>
                                       </div>
                                       
                                       <div className="calspotlastsection">
                                           <div className="calspotlastsectcont">
                                               {partymatch.start_time}
                                           </div>
                                           <div className="calspotlastsectconm">
                                               to
                                           </div>
                                           <div className="calspotlastsectconb">
                                               {partymatch.finish_time}
                                           </div>
                                           
                                       </div> */}
                                      
                                   </div>
                               </Link> 
                           }  )}
                       </div>
                   }





                    {
                       
                        (
                        
                            filteredPartiesInProgressAndToday.length === 0
                          
                          &&
                          dateSelectedCalView.getDate() === currentDateAndTime.getDate() && dateSelectedCalView.getMonth() === currentDateAndTime.getMonth() && dateSelectedCalView.getFullYear() === currentDateAndTime.getFullYear()
                        )
                        ||
                        (
                            !(dateSelectedCalView.getDate() === currentDateAndTime.getDate() && dateSelectedCalView.getMonth() === currentDateAndTime.getMonth() && dateSelectedCalView.getFullYear() === currentDateAndTime.getFullYear())
                            &&
                            (filteredParties?.filter((ele: any) =>
                                (
                                    // party.daterealstart > currentDateAndTime
                                    // &&
                                    ele.daterealstart.getDate() === dateSelectedCalView.getDate()
                                    && (ele.daterealstart.getMonth() === dateSelectedCalView.getMonth())
                                    && (ele.daterealstart.getFullYear() === dateSelectedCalView.getFullYear())
                                    )
                                    ).length === 0)
                        )
                    
                        &&
                        <div className="calspotnoparties">
                            No scheduled parties. Check if filters are applied.
                        </div>
                    }
                    </IonContent>
                </>
                }
            </IonModal>
            {
            
           
            
                filteredParties?.length === 0
                &&
                <div className="ultimategridviewcontainer">
                    <div className="noparties gridviewlineheightsm">
                        There are no parties that correspond to the selected filter criteria. Please remove one or more filters.
                    </div>
            </div>
            }
            {
                filteredParties?.length > 0
                &&
                <div className="calendar">
                {
                arrayOfMonths?.map((month: any, index: any) => {
                    return <div className="calmonth"
                    key={index}
                    >
                        <div className="calmonthheader">
                            <div className="calmonthheaderinner">
                                <div className="calmonthheaderdate">
                                    {month[25].toLocaleDateString("en-GB", {month: 'short', year: 'numeric'}).toUpperCase()}
                                </div>
                            </div>
                            <div className="calmonthdaysbox">
                                <div className="calmonthday">Mon</div>
                                <div className="calmonthday">Tue</div>
                                <div className="calmonthday">Wed</div>
                                <div className="calmonthday">Thu</div>
                                <div className="calmonthday">Fri</div>
                                <div className="calmonthday">Sat</div>
                                <div className="calmonthday">Sun</div>
                            </div>
                        </div>
                        <div className={month.length>35 ? "caldatesgridlarge" : "caldatesgridstd"}>
                            {
                            month.map((date: any, index: any) => {
                                return <div
                                key={index}
                                className={date.getMonth() === month[25].getMonth() ? "calvisibledate" : "calinvisibledate"}
                                onClick={() => handleOpenAgenda(date)}
                                style={date < currentDateAndTime ? {opacity: '0.2'} : {opacity: '1', cursor: 'pointer'}}
                                id={date.getDate() === currentDateAndTime.getDate() && date.getMonth() === currentDateAndTime.getMonth() && date.getFullYear() === currentDateAndTime.getFullYear() ? "todaydate" : ""}
                                >
                                    <div className="caldatebox">
                                    {
                                        ((date >= currentDateAndTime) || (date.getDate() === currentDateAndTime.getDate() && date.getMonth() === currentDateAndTime.getMonth() && date.getFullYear() === currentDateAndTime.getFullYear()))
                                        &&
                                            filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear()).length > 0
                                            &&
                                            <div>
                                                {
                                                filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_src
                                                &&
                                                <img
                                                className="caldateimg"
                                                src={filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_src} alt=""
                                                onError={(e: any) => e.target.src = filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_url}
                                                />
                                                }
                                                {
                                                !(filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_src)
                                                &&
                                                <img
                                                className="caldateimg"
                                                src={filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_url} alt=""
                                                
                                                />
                                                }
                                                <div className="caldateimgoverlay"></div>
                                            </div>
                                        }
                                        {/* {
                                            filteredParties?.filter((party: any) => party.daterealstart > currentDateAndTime).filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear()).length > 0
                                            &&
                                            <div>
                                                <img
                                                className="caldateimg"
                                                src={filteredParties?.filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear())[0].image_src} alt=""
                                                />
                                                <div className="caldateimgoverlay"></div>
                                            </div>
                                        }
                                        {
                                            filteredParties?.filter((party: any) => party.daterealstart > currentDateAndTime).filter((party: any) => date.getDate() === party.daterealstart.getDate() && date.getMonth() === party.daterealstart.getMonth() && date.getFullYear() === party.daterealstart.getFullYear()).length === 0
                                            &&
                                            filteredParties?.filter((party: any) => date.getDate() === party.daterealfinish.getDate() && date.getMonth() === party.daterealfinish.getMonth() && date.getFullYear() === party.daterealfinish.getFullYear()).length > 0
                                            &&
                                            <div>
                                                <img
                                                className="caldateimg"
                                                src={filteredParties?.filter((party: any) => date.getDate() === party.daterealfinish.getDate() && date.getMonth() === party.daterealfinish.getMonth() && date.getFullYear() === party.daterealfinish.getFullYear())[0].image_src} alt=""
                                                />
                                                <div className="caldateimgoverlay"></div>
                                            </div>
                                        } */}
                                        {/* above conditionals rectify start vs finish date issue */}
                                    
                                        <div className="caldatenum">
                                            {date.toLocaleDateString("en-GB", {day: 'numeric'})}
                                        </div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                    </div>
                })
                }
            </div>

            }
            
    
        </React.Fragment>
    );
}

export default CalViewParties;