import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonToolbar,
    IonButtons,
    IonButton,

    IonBackButton,
    IonIcon,
    IonHeader,
    IonModal,
    IonMenuButton,
    IonToast,
    IonLoading,
    IonTextarea
} from '@ionic/react';

import { 
    globeOutline,
    callOutline,
    logoFacebook,
    logoInstagram,
    // heartOutline,
    // heartSharp,
    // alertCircle,
    // shareOutline,
    alertCircleOutline,
    chevronBack
} from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import { database } from '../lib/init-firebase';
import { ref, update, push } from 'firebase/database';


import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';

const MIN_LOADING_DURATION = 1000; // 1 second in milliseconds



const SaunaWindow: React.FC<{
    gridNo: any;
    saunas: any[];
    parties: any[];
    currentDateAndTime: any;
    currentDateAndTimePlusTwoWeeks: any
    // likedBarsAndClubs: any;
    // setLikedBarsAndClubs: any;
    // handleIncrement: any;
    // handleDecrement: any;
}> = (props) => {

    const selectedSaunaId = useParams<{ id: string }>().id;
    const selectedSauna = props.saunas.find(sauna => sauna.id === selectedSaunaId);
    // console.log(selectedBarClub, 'selected bc');

    // theres no reload in bars and clubs so current date plus two weeks would depend on poeple relaoding .. but i assume given its two weeks it wont ever get that off..like would app reload are a certain period

    const [isModalRv, setIsModalRv] = useState(false);
    const [messageRv, setMessageRv] = useState('');

  const [showLoadingRv, setShowLoadingRv] = useState(false);
  const [showToastRv, setShowToastRv] = useState(false);
  const [toastMessageRv, setToastMessageRv] = useState('');
  const [toastColorRv, setToastColorRv] = useState('');
  
    const [isWindowMapOpenSau, setIsWindowMapOpenSau] = useState<any>(false);
    const [isWindowMapLoadedSau, setIsWindowMapLoadedSau] = useState<any>(false);
    // const [myMapBC, setMyMapBC] = useState<any>(null);
    //why dont i use myMapP? ive got rid of setting it for the moment.

    const modalMapSau= useRef<HTMLIonModalElement>(null);
    const modalAddReviewSau = useRef<HTMLIonModalElement>(null);

    const mapContainerSau = useRef<any>(null);

    const closeRv = () => {
        setIsModalRv(false);
        setMessageRv('');
      }
      
      
      const addRev = async () => {
        const startTime = Date.now();
        setShowLoadingRv(true);
      
        try {
          // const db = getDatabase();
          const feedbackRef = ref(database, 'data/reviews/venues');
          
          const newFeedbackKey = push(feedbackRef).key;
          
          const feedbackData = {
            id: newFeedbackKey,
            venue_id: selectedSauna.id,
            venue_name: selectedSauna.name,
            venue_city: selectedSauna.city,
            review: messageRv.trim(),
            rating: 0,
            timestamp: Date.now()
          };
      
          const updates: { [key: string]: any } = {};
          updates[`data/reviews/venues/${newFeedbackKey}`] = feedbackData;
          await update(ref(database), updates);
      
          setToastMessageRv('Review submitted successfully!');
          setToastColorRv('success');
          // setMessage('');
        } catch (error) {
          console.error("Error submitting review:", error);
          setToastMessageRv('Failed to submit review. Please try again.');
          setToastColorRv('danger');
        } finally {
          const endTime = Date.now();
          const elapsedTime = endTime - startTime;
          if (elapsedTime < MIN_LOADING_DURATION) {
            await new Promise(resolve => setTimeout(resolve, MIN_LOADING_DURATION - elapsedTime));
          }
          setShowLoadingRv(false);
          setIsModalRv(false);
          setMessageRv('');
          setShowToastRv(true);
        }
      };

    useEffect(() => {

        if (!mapContainerSau.current) {
            return
        }

        const mapSau = new mapboxgl.Map({
            container: mapContainerSau.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedSauna?.lng, selectedSauna?.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedSauna?.lng, selectedSauna?.lat]).addTo(mapSau);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapBC(mapBC);

        return () => mapSau.remove();

    },[isWindowMapLoadedSau]);

    const handleDismissWindowMapModalSau = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because...
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedSau(false);
        setIsWindowMapOpenSau(false);
    }

    return(
        <IonPage>
            {
                selectedSauna ? (
            <IonContent >
                <IonModal
                ref={modalAddReviewSau}
                // id="example-modalPartiesNotifications"
                isOpen={isModalRv}
                onDidDismiss={closeRv}
                className="notifmodal2"
                >
                  <IonToolbar className="notiftoolbar">
                              <IonButtons slot="secondary">
                                      <IonButton
                                      // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolr"}
                                    onClick={closeRv}
                                    // disabled={showLoading || message.trim() === ''}
                                    >
                          
                                      Close
                                      {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                                  
                                  </IonButton>
                              </IonButtons>
                      <div className="notifheader">Review Venue</div>
                      <IonButtons slot="primary">
                              <IonButton
                              // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolp"}
                              onClick={addRev}
                              disabled={showLoadingRv || messageRv.trim() === ''}
                              >
                            
                                Submit
                                {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                            
                            </IonButton>
                      </IonButtons>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                    <div className="notifgradient">

                      <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={11}
                                value={messageRv}
                                onIonInput={(e:any)=>setMessageRv(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please share your thoughts on the venue. What did you like? What could be improved? The response will remain anonymous."
                                disabled={showLoadingRv}
                                />
                                <div className="plslimit">Please limit the response to 300 characters.</div>
                      </div>

                      <div className="btnsfeedback">
                            
                          
                        </div>

                                  
                    </div>

                                    

                              

                    </IonContent>
                              <IonLoading
                                isOpen={showLoadingRv}
                                message={'Submitting review...'}
                                className="custom-loading"
                              />
        </IonModal>


                <IonToast
                                isOpen={showToastRv}
                                onDidDismiss={() => setShowToastRv(false)}
                                message={toastMessageRv}
                                duration={3000}
                                color={toastColorRv}
                              />
                <IonModal
                ref={modalMapSau}
                className="windowmapmodal"
                isOpen={isWindowMapOpenSau}
                onDidDismiss={handleDismissWindowMapModalSau}
                onDidPresent={() => setIsWindowMapLoadedSau(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerSau} className="mapcontainerinner" />
                    </div>
                </IonModal>

                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="ionbackbuttoncontoth">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                    <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                    </div>
                    

                    <div className="windowtopcont">
                        {
                            selectedSauna.image_src
                            &&
                            <img className="windowimgbox" src={selectedSauna.image_src} alt=""
                            onError={(e: any) => e.target.src = selectedSauna.image_url}

                            ></img>

                        }
                        {
                            !selectedSauna.image_src
                            &&
                            <img className="windowimgbox" src={selectedSauna.image_url} alt=""
                            ></img>

                        }
                        <div className="windowimggrad">
                            <div className="windowtitlebox">
                                <div className="windowtitlefull">
                                    {selectedSauna.name}
                                </div>
                            </div>

                        </div>
                    </div>
                    

                    <div className="windowmainsetwidth">

                    {
                        props.parties.filter(party => party.venue === selectedSauna.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                        &&
                            <div className="windowmainheadingstd pinkfont">
                                    <div className="livenow">LIVE NOW</div>
                                    {/* <div className="livelines">
                                        <div className="livelinet"></div>
                                        <div className="livelineb"></div>
                                    </div> */}
                            </div>
                    }
                    </div>

                    {
                            props.parties.filter(party => party.venue === selectedSauna.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                            &&
                            <div className="gridviewgridBC">
                                {
                                    props.parties.filter(party => party.venue === selectedSauna.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime)))
                                    .map((party: any, index: number) => {
                                        return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardBC">
                                            
                                            {
                                            (party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_src} alt=""
                                            onError={(e: any) => e.target.src = party.image_url}

                                            />   

                                        }
                                        {
                                            !(party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_url} alt=""
                                            />   

                                        }

                                            <div className="gridviewcardimagecover">

                                            <div className="newgridcaltt">
                                                        <div className="livefromnewoth">ends</div>
                                                        
                                                        <div className="colorw">{party.finish_time}</div>

                                                    </div>

                                                <div className="gridviewbottomcontainer19">
                                                    
                                                    {
                                                        party.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{party.name}</div>
                                                    }
                                                    {
                                                        party.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{party.name_short}</div>
                                                    }
                                                   
                                                   
                                                </div>
                                            
                                                            

                                                            
    
                                            
                                            </div>
                                            </div>
                                            </Link>   
                                        

                                    })
                                }
                            </div>
                            }
                        
                        <div className="windowmainsetwidth">




                        








                        {
                        (selectedSauna.description !== "" || selectedSauna.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            
                            <div className="addflx">
                                <div className="windowmainheadingrv">
                                    About
                                </div>
                                <div className="viewmapbtnpurp"
                                // onClick={() => setIsWindowMapOpenCruises(true)}
                                onClick={() => setIsModalRv(true)}
                                >REVIEW</div>
                            </div>

                            <div className="windowmaintextboxstd">
                                {selectedSauna.description}
                            </div>
                          
                             {
                                (selectedSauna.extra_info !== "")
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedSauna.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedSauna.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>

                            }
                        </div>
                        }

                        

                        


                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                            Opening hours
                            </div>
                            <div className="openinghoursbox">
                            <div className="timetablebox">
                                <div className="">
                                    <div className="weekday">
                                        Monday
                                    </div>
                                    <div className="weekday">
                                        Tuesday
                                    </div>
                                    <div className="weekday">
                                        Wednesday
                                    </div>
                                    <div className="weekday">
                                        Thursday
                                    </div>
                                    <div className="weekday">
                                        Friday
                                    </div>
                                    <div className="weekday">
                                        Saturday
                                    </div>
                                    <div className="weekday">
                                        Sunday
                                    </div>
                                </div>
                                <div className="BCtimes">
                                    
                                    {
                                    selectedSauna.monday_open !== ""
                                    &&
                                    !(selectedSauna.monday_open === "12am" && selectedSauna.monday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.monday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.monday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.monday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.monday_open === "12am" && selectedSauna.monday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.tuesday_open !== ""
                                    &&
                                    !(selectedSauna.tuesday_open === "12am" && selectedSauna.tuesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.tuesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.tuesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.tuesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.tuesday_open === "12am" && selectedSauna.tuesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.wednesday_open !== ""
                                    &&
                                    !(selectedSauna.wednesday_open === "12am" && selectedSauna.wednesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.wednesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.wednesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.wednesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.wednesday_open === "12am" && selectedSauna.wednesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.thursday_open !== ""
                                    &&
                                    !(selectedSauna.thursday_open === "12am" && selectedSauna.thursday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.thursday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.thursday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.thursday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.thursday_open === "12am" && selectedSauna.thursday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.friday_open !== ""
                                    &&
                                    !(selectedSauna.friday_open === "12am" && selectedSauna.friday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.friday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.friday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.friday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.friday_open === "12am" && selectedSauna.friday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.saturday_open !== ""
                                    &&
                                    !(selectedSauna.saturday_open === "12am" && selectedSauna.saturday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.saturday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.saturday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.saturday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.saturday_open === "12am" && selectedSauna.saturday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    selectedSauna.sunday_open !== ""
                                    &&
                                    !(selectedSauna.sunday_open === "12am" && selectedSauna.sunday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.sunday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            -
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{selectedSauna.sunday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedSauna.sunday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (selectedSauna.sunday_open === "12am" && selectedSauna.sunday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>

                        {
                        selectedSauna.price_info !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Entrance Fee
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedSauna.price_info}
                            </div>
                        </div>
                        }

                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            <div className="windowmainvenuecardbox">
                                <div className="windowvenueleft">
                                {selectedSauna.suite_number === "" ? "" : <div>{selectedSauna.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {selectedSauna.street_number === "" ? <div>{selectedSauna.street_name}</div> : <div className="limitaddresstooneline">{selectedSauna.street_number}{" "}{selectedSauna.street_name}</div>}
                                        {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                    </div>
                                    {/* <div>{selectedSauna.suburb} {selectedSauna.postcode}</div> */}
                                    {selectedSauna.city !== "London" ? <div>{selectedSauna.suburb} {selectedSauna.postcode}</div> : <div><div>{selectedSauna.suburb}</div><div> {selectedSauna.postcode}</div></div>}
                                </div>
                                <div className="windowvenueright">
                                    <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenSau(true)}>
                                        MAP
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="windowmainboxstd">
                            {
                            (selectedSauna.instagram !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.instagram.com/${selectedSauna.instagram}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Instagram</div>
                                    <div className="wmtxtb">@{selectedSauna.instagram}</div>
                                </div>
                                    
                                <div className="wmiconinsta"
                                
                                >
                                        <IonIcon icon={logoInstagram} className="insticn" />
                                </div>
                            </div>
                            }
                            {/* {
                                (selectedSauna?.facebook !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedSauna.facebook, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Facebook</div>
                                        <div className="wmtxtb">@{selectedSauna.facebook.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconfb"
                                    
                                    >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                    </div>
                                </div>
                            } */}
                            {
                            (selectedSauna.facebook !== "")
                            &&
                            <div className="wmcont"
                            onClick={()=>window.open(`https://www.facebook.com/${selectedSauna.facebook}`, '_system', 'location=yes')}
                            >
                                <div className="wmflex">
                                    <div className="wmtxt">Facebook</div>
                                    <div className="wmtxtb">{selectedSauna.facebook}</div>
                                </div>
                                    
                                <div className="wmiconfb"
                                
                                >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                </div>
                            </div>
                            }
                             {
                                (selectedSauna.website !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedSauna.website, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Website</div>
                                        <div className="wmtxtb">{selectedSauna.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconsc"
                                    // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson.youtube}`, '_system', 'location=yes')}
                                    
                                    >
                                        <IonIcon icon={globeOutline} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                            {
                            (selectedSauna.phone !== 0 && selectedSauna.phone !== "")
                            &&
                            <a className="aremove" href={`tel:+${selectedSauna.phone}`}>
                            <div className="wmcont">
                                <div className="wmflex">
                                    <div className="wmtxt">Phone</div>
                                    <div className="wmtxtb">+{selectedSauna.phone}</div>
                                </div>
                                
                                <div className="wmiconph">
                                    
                                    <IonIcon icon={callOutline} color="white" className="icoth" />
                                    
                                </div>
                                
                            </div>
                            </a>
                            }
                           
                        </div>

                        <div className="windowmainheadingstd">
                            Upcoming events
                        </div>
                           
                        {
                        props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 0
                        &&
                        <div className="windowmaintextboxstd">
                            Please check the venue&apos;s social media accounts or website.
                        </div>
                        }
                    
                    </div> 
                    {/* end of first set width main section */}
                    
                    {
                    // ((props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 1) || (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 2))
                    // &&
                    (
                        props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length >= 1
                        &&
                (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length === 1) && (props.gridNo === 1)
                ||
                (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 2) && (props.gridNo === 2)
                ||
                (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 3) && (props.gridNo === 3)
                ||
                (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 4) && (props.gridNo === 4)
                ||
                (props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length <= 5) && (props.gridNo === 5)
                    )
                // &&
                // props.gridNo >= 2
                &&
                    <div className="gridviewgridBC">
                        {
                        props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks)
                            .map((party, index) => {
                            return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardBC">
                                            {
                                                party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_src} alt=""
                                                onError={(e: any) => e.target.src = party.image_url}

                                                />
                                            }
                                            {
                                                !party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_url} alt="" />
                                            }
                                            <div className="gridviewcardimagecover">

                                                    {/* {
                                                    !party.tickets_available
                                                    &&
                                                    <div className="gridviewextrasoldoutbox">
                                                        <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                    </div>
                                                    } */}
                                                    <div className="newgridcal">
                                                        <div className="topdate">{party.start_date}</div>
                                                        
                                                        <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                    </div>

                                                    <div className="gridviewbottomcontainer19">
                                                        
                                                        {
                                                            party.name_short === ""
                                                            &&
                                                            <div className="gridviewtitlefull">{party.name}</div>
                                                        }
                                                        {
                                                            party.name_short !== ""
                                                            &&
                                                            <div className="gridviewtitlefull">{party.name_short}</div>
                                                        }
                                                        
                                                    
                                                    </div>
                                               
                                                
                                            </div>
                                        </div>
                                    </Link>
                            })
                        }
                    </div>
                    }

                    {
                    // props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > 2
                    // &&
                    props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length >= 1
                    &&
                    props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > props.gridNo
                    &&
                    <div className="swipercontainerstd gvgaddmarginbot">
                        <Swiper
                        slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                        loop={false}
                        modules={[IonicSlides]}
                        // className="swiperaddspace"
                        // above was id before. add to parties swiper too?
                        // spaceBetween={6}
                        // slidesOffsetBefore={30}
                        // slidesOffsetAfter={30}
                        slidesOffsetBefore={10}
                        slidesOffsetAfter={10}
                        spaceBetween={2}
                        >
                            {props.parties.filter(party => party.venue === selectedSauna.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks)
                            .map((party, index) => {
                                return <SwiperSlide key={index}>
                                            <div className="swipercard">
                                                    <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                                        {
                                                            party.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={party.image_src} alt=""
                                                            onError={(e: any) => e.target.src = party.image_url}

                                                            />
                                                        }
                                                        {
                                                            !party.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={party.image_url} alt=""
                                                            />
                                                        }
                                                        <div className="gridviewcardimagecover">
                                                          
                                                            <div className="newgridcal">
                                                                <div className="topdate">{party.start_date}</div>
                                                                
                                                                <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                            </div>

                                                            <div className="gridviewbottomcontainer19">
                                                                
                                                                {
                                                                    party.name_short === ""
                                                                    &&
                                                                    <div className="gridviewtitlefull">{party.name}</div>
                                                                }
                                                                {
                                                                    party.name_short !== ""
                                                                    &&
                                                                    <div className="gridviewtitlefull">{party.name_short}</div>
                                                                }
                                                                
                                                            
                                                            </div>
                                               
                                                           
                                                        </div>
                                                    </Link>
                                                
                                            </div>
                                        </SwiperSlide>
                            })}
                        </Swiper>
                        {/* </div> */}
                    </div>
                    }
                        

              
                    
                </div>                                 
            </IonContent>
            ) : (
                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                    <IonButtons
                    // slot="start"
                    >
                        <IonBackButton defaultHref="/" text="">
                        </IonBackButton>
                    </IonButtons>
                    </div>
                    <div className="innerwindowflex">
                        <div className="notfoundmsg">
                        This venue has been removed. Please click the back button.
                        </div>
                        
                    </div>
                </div>
            )
        }
        </IonPage>
    )
}

export default SaunaWindow;