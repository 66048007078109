import React, { useRef, useEffect } from 'react';
import { IonHeader, IonButton, IonContent, IonIcon, IonicSlides } from '@ionic/react';

import {
  mapOutline,
  searchOutline,
  optionsOutline,
  calendarOutline,
  heartCircleOutline,
  starHalfOutline,
  linkOutline,
  notificationsOutline,
  logoInstagram,
  chevronForward
} from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';


import 'swiper/css';

import 'swiper/css/bundle';

import 'swiper/css/autoplay';  // If using autoplay
import 'swiper/css/navigation';  // If using navigation



import '../theme/landing.css'; // Importing the external CSS

import logonew from '../images/logomarktwo.png';

import screens from '../images/screens2.png';

import btnsoth from '../images/btns2.png';
import notifscrn from '../images/iphonescreen.png';

import notifbell3 from '../images/bell4.png';
import trilog from '../images/tgalogo_hq.png';


import notif from '../images/notificationsdemo.png';


const LandingPage: React.FC<{setIsLanding:any}> = (props) => {

  const notifSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const box1 = document.querySelector('.box1');
          const box2 = document.querySelector('.box2');
          const box3 = document.querySelector('.box3');

          if (entry.isIntersecting) {
            // Add the 'appear' class when the section comes into view
            box1?.classList.add('appear');
            setTimeout(() => {
              box2?.classList.add('appear');
            }, 500);
            setTimeout(() => {
              box3?.classList.add('appear');
            }, 1000);
          } else {
            // Remove the 'appear' class when the section goes out of view
            box1?.classList.remove('appear');
            box2?.classList.remove('appear');
            box3?.classList.remove('appear');
          }
        });
      },
      { threshold: 0.5 }
    );

    const current = notifSectionRef.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return (
    <IonContent>
      {/* Header with Logo and Button */}
      <IonHeader className="landing-header">
        <div className="landing-header-div">
          <img src={logonew} alt="The Gay Agenda Logo" className="logo" />
          {/* <IonButton href="https://www.instagram.com/thegayagenda_official" target="_blank" rel="noopener noreferrer" className="instagram-button">
            Follow
          </IonButton> */}
          <div className="twobtncont">
            <button className="instagram-button" onClick={() => window.open('https://www.instagram.com/thegayagenda_official', '_blank')}>
                <IonIcon icon={logoInstagram} className="instagram-icon" />
                <div className="disapp">Follow</div>
            </button>
            <button className="web-button" onClick={() => props.setIsLanding(false)}>
                {/* <IonIcon icon={logoInstagram} className="instagram-icon" /> */}
                <div>Web App</div>
                <IonIcon icon={chevronForward} className="web-icon" />
            </button>
          </div>
          
        </div>
      </IonHeader>

      {/* Main Container */}
      <div className="main-container">
        {/* Left Side with Text */}
        {/* <div className="text-content">
            <h1>Your Digital Portal to the <span>LGBTQ+ Universe</span>.</h1>
            <p className="categories">Events, Bars & Clubs, Saunas, Cruises, Festivals, Performers, Drag & More.</p>
          <div className="stats">
            <div>
              <p>Events</p>
              <h2>+2,000</h2>
            </div>
            <div>
              <p>Venues</p>
              <h2>+150</h2>
            </div>
            <div>
              <p>Cities</p>
              <h2>4</h2>
            </div>
          </div>
          <p className="subtext">+ Drag Expos, Tours, Cruises, Festivals & More!</p>

          
        </div>
        <div className="download-section">
        <p className="download-text">Download the app now.</p>
          <div className="download-buttons">
            <img src={gpbtn} alt="Google Play" className="download-img" />
            <img src={ipbtn} alt="App Store" className="download-img" />
          </div>
      </div> */}

      {/* Left Side with Text and Download Section */}
  <div className="left-wrapper">
    <div className="text-content">
      <h1>Your Digital Portal to the <span>LGBTQ+ Universe</span>.</h1>
      <div className="categories">Events, Bars & Clubs, Saunas, Cruises, Festivals, Performers, Drag & More.</div>
    </div>

    <div className="download-section">
      <div className="download-text">Download the app now.</div>
      <div className="download-buttons">
        {/* <img src={gpbtn} alt="Google Play" className="download-img" />
        <img src={ipbtn} alt="App Store" className="download-img" /> */}
             
             <div className="invleft" onClick={() => window.open('https://play.google.com/store/apps/details?id=io.thegayagenda.app&hl=en_US&pli=1', '_blank')}></div>
            <div className="invright" onClick={() => window.open('https://apps.apple.com/us/app/the-gay-agenda/id6472125056', '_blank')}></div>
        <img src={btnsoth} className="download-img" />
      </div>
    </div>
  </div>

        {/* Right Side with iPhone Simulators Image */}
        <div className="image-container">
     
         
          <img src={screens} alt="iPhone Simulators" className="iphone-image" />
        </div>
      </div>

      {/* Pink Strip */}
      {/* <div className="pink-strip">
        <div className="section">
          <IonIcon icon={searchOutline} className="icon" />
          <div className="feature-heading">Search & Filter</div>
          <p>Quickly find events and venues tailored to your interests using advanced search and customisable filters.</p>
        </div>
        <div className="section">
          <IonIcon icon={mapOutline} className="icon" />
          <div className="feature-heading">Maps</div>
          <p>Easily explore nearby venues and events with interactive maps designed to keep you connected to what&apos;s happening around you.</p>
        </div>
        
        <div className="section">
          <IonIcon icon={calendarOutline} className="icon" />
          <div className="feature-heading">Calendar</div>
          <p>Plan your schedule easily with a clear calendar view of upcoming events, helping you stay organised.</p>
        </div>
        <div className="section">
          <IonIcon icon={heartCircleOutline} className="icon" />
          <div className="feature-heading">Favourites</div>
          <p>Save your favourite events, venues, performers and more for quick and easy access whenever you need them.</p>
        </div>
      </div>
      <div className="section">
          <IonIcon icon={mapOutline} className="icon" />
          <div className="feature-heading">Maps</div>
          <p>Easily explore nearby venues and events with interactive maps designed to keep you connected to what&apos;s happening around you.</p>
        </div>
        <div className="section">
          <IonIcon icon={mapOutline} className="icon" />
          <div className="feature-heading">Maps</div>
          <p>Easily explore nearby venues and events with interactive maps designed to keep you connected to what&apos;s happening around you.</p>
        </div>
        <div className="section">
          <IonIcon icon={mapOutline} className="icon" />
          <div className="feature-heading">Maps</div>
          <p>Easily explore nearby venues and events with interactive maps designed to keep you connected to what&apos;s happening around you.</p>
        </div> */}

<div className="pink-strip-wrapper">
      <Swiper
        modules={[IonicSlides,Autoplay]}
        spaceBetween={30}  /* Space between slides */
        slidesPerView={4}  /* Show 4 sections on a laptop */
        
        loop={true}  /* Enable continuous loop */
        autoplay={{ delay: 2000, disableOnInteraction: false }} /* Autoplay settings */
        breakpoints={{
          1024: { slidesPerView: 4 },
          768: { slidesPerView: 3 },
          500: { slidesPerView: 2 },
          0: { slidesPerView: 1 }
        }} /* Responsive settings for different screen sizes */
      >
        <SwiperSlide>
          <IonIcon icon={heartCircleOutline} className="icon" />
          <div className="feature-heading">Favourites</div>
          <p>Save your favourite events, venues, performers and more for quick and easy access whenever you need them.</p>
        </SwiperSlide>
        <SwiperSlide>
          <IonIcon icon={mapOutline} className="icon" />
          <div className="feature-heading">Maps</div>
          <p>Easily explore nearby venues, events, and performers with interactive maps.</p>
        </SwiperSlide>
        <SwiperSlide>
          <IonIcon icon={searchOutline} className="icon" />
          <div className="feature-heading">Search & Filter</div>
          <p>Quickly find events, venues, or performers using advanced search and customizable filters.</p>
        </SwiperSlide>
        <SwiperSlide>
          <IonIcon icon={calendarOutline} className="icon" />
          <div className="feature-heading">Calendar</div>
          <p>Plan your schedule easily with a clear calendar view of upcoming events, helping you stay organized.</p>
        </SwiperSlide>
        <SwiperSlide>
          <IonIcon icon={starHalfOutline} className="icon" />
          <div className="feature-heading">Reviews</div>
          <p>Submit reviews and rate your favourite venues to help others make informed decisions.</p>
        </SwiperSlide>
        {/* <SwiperSlide>
          <IonIcon icon={notificationsOutline} className="icon" />
          <div className="feature-heading">Notifications</div>
          <p>Receive real-time notifications about upcoming events, promotions, new app features and more.</p>
        </SwiperSlide> */}
        <SwiperSlide>
          <IonIcon icon={linkOutline} className="icon" />
          <div className="feature-heading">Links</div>
          <p>Get quick access to websites, ticketing platforms, and social media accounts with just one click.</p>
        </SwiperSlide>
      </Swiper>
    </div>

      {/* New Section with Image and Text */}
      {/* <div className="bottom-section">
        <div className="text-content-bottom">
          <p>Discover the most exciting LGBTQ+ events, venues, and performers across the world. Whether youre planning a night out or looking for something new, weve got you covered.</p>
        </div>
        <div className="image-container-bottom">
          <img src={notif} alt="Exciting LGBTQ+ Events" className="bottom-image" />
        </div>
      </div> */}
      <div className="notif-section" ref={notifSectionRef}>
      <div className="notif-left">
        <div className="phone-container">
          <img src={notifscrn} alt="iPhone Screen" className="phone-image" />
          
          {/* Notification Boxes */}
          <div className="notification-box box1">
            <div className="notification-header">
              <img src={trilog} alt="Logo" className="notif-logo" />
              <span>Exclusive: 20% Off Disco Inferno Tickets</span>
            </div>
            <div className="notification-content">
            🎉 Save 20% on tickets for <strong>Disco Inferno</strong> this Friday. Use code: <span>DISCO20</span> at checkout. Offer ends soon!
            </div>
          </div>

          <div className="notification-box box2">
            <div className="notification-header">
              <img src={trilog} alt="Logo" className="notif-logo" />
              <span>Only 5 Tickets Left for Rainbow Rave!</span>
            </div>
            <div className="notification-content">
              🚨 Only 5 tickets left for <strong>Rainbow Rave</strong>—act fast before they&apos;re gone!
            </div>
          </div>

          <div className="notification-box box3">
            <div className="notification-header">
              <img src={trilog} alt="Logo" className="notif-logo" />
              <span>New Performer Announced: DJ Glitter!</span>
            </div>
            <div className="notification-content">
            🎤 Exciting news! <strong>DJ Glitter</strong> will be performing at <strong>Stardust Soirée</strong> this Saturday—must-see event!
            </div>
          </div>
        </div>
      </div>

      <div className="notif-right">
        <img src={notifbell3} className="notif-bell" />
        {/* <div className="icon-circle">
          <IonIcon icon={notificationsOutline} className="notif-icon" />
        </div> */}
        <h1>Never miss a beat with <span>realtime updates</span></h1>
        <p>Receive real-time notifications about upcoming events, promotions, new app features, and more.</p>
      </div>
    </div>

      <div className="footer">
        <p>© 2024 A.C.N. 664 126 069 PTY LTD. All Rights Reserved.</p>
        <p>For any inquiries, please email: <a href="mailto:admin@thagayagenda.io">admin@thagayagenda.io</a></p>
      </div>
    </IonContent>
  );
};

export default LandingPage;