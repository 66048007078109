// geoGSON order thing needs to be checked. i think just go with alphabetical for the moment. and maybe do it in App
// map spot cards need improvement. also note the first header row cut to one line thing is slightly spilling into second header

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonMenuButton,
    IonModal,
    IonToggle,
    IonFab,
    IonFabButton,
    // IonButton,
    // IonSearchbar
} from '@ionic/react';

import {
    // locationOutline,
    options,
    searchOutline,
    notificationsOutline,
    lockClosedOutline,
    // heartSharp,
    // heartOutline,
    // reorderThreeOutline,
    gridOutline,
    mapOutline,
    chevronForwardOutline,
    calendarOutline,
    helpCircleOutline
} from 'ionicons/icons';

import MapViewMulti from './MapViewMulti';

import logohq from '../images/tgalogo_hq.png';


import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/searchbars.css';
import '../theme/spotcards.css';
import '../theme/filtermodals.css';
// import '../theme/swipers.css';
import '../theme/segments.css';


// Accent-folding function
const removeAccents = (str:string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };


const Health: React.FC<{

    clinics: any[];
  
    citySelected:any;

    cityCoordinates: any;
    cityZoom: any;

    notifications: any;
    setNotifications: any;
    viewedNotifications: any;
    setViewedNotifications: any;
    viewedNotificationsAreLoaded: any;

}> = (props) => {
    
    

    const [healthView, setHealthView] = useState("gridview");
    const [isInfoOpenH, setIsInfoOpenH] = useState(false);

    const [filteredClinics, setFilteredClinics] = useState<any>(null);
    const [filteredGeoJSONH, setFilteredGeoJSONH] = useState<any[]>([]);

    const [isNotificationsOpenH, setIsNotificationsOpenH] = useState(false);

  
    const modalNotificationsH = useRef<HTMLIonModalElement>(null);
    const modalInfoH = useRef<HTMLIonModalElement>(null);

    
    const handleViewChangeH = (viewClicked: string) => {
      if (viewClicked === healthView) return;
      if (viewClicked === "gridview") setHealthView("gridview");
      if (viewClicked === "mapview") setHealthView("mapview");
    };

    const handleTogViewH = () => {
        if (healthView === "gridview") setHealthView("mapview");
        if (healthView === "mapview") setHealthView("gridview");
      }

    const handleOpenNotificationsH = () => {
        setIsNotificationsOpenH(true);
        const arrayOfNotificationsNotAlreadyInState: any = [];
        if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
            // const obj1 = {}
            arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
          }
          if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
            // const obj1 = {}
            arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
          }
        for (let i = 0; i < props.notifications.length; i++) {
          if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
            arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
          }
        }
        props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
      }
    
      const handleCloseNotificationsH = () => {
        setIsNotificationsOpenH(false);
      }

    

    


    const filterClinicList = () => {

    // let tempArrayOfFilteredSau:any = [];
    
    
    const tempArrayOfFilteredClinics = props.clinics.filter(clinic => (
        true
    ));
    
    console.log(tempArrayOfFilteredClinics, 'clinic 1')
    
  

    tempArrayOfFilteredClinics.sort(function (a: any, b: any) {
        a = a.order;
        b = b.order;
        return a - b
    });

    console.log(tempArrayOfFilteredClinics, 'clinic 2')

    // keeping sort here because seems to work in combination with below which makes map correct (order is the number for each pin)


    for (let i = 0; i < tempArrayOfFilteredClinics.length; i++) {
        tempArrayOfFilteredClinics[i].order = i + 1;
    }

    console.log(tempArrayOfFilteredClinics, 'clinic 3')
    
    setFilteredClinics(tempArrayOfFilteredClinics);

    // if im doing this below do i need to do it in App onvalue as well (also for BC). filteredGeoJSONBC/Sau seems to be independent of geoJSON prop you pass to this component...
    const newGeoJSONObject:any = {
        "features": []
        }

        for (let i = 0; i < tempArrayOfFilteredClinics.length; i++) {
        const newObj = {
            "type": "Feature",
            "properties": {
            "title": tempArrayOfFilteredClinics[i].name,
            "description": tempArrayOfFilteredClinics[i].order
            },
            "geometry": {
            "coordinates": [tempArrayOfFilteredClinics[i].lng, tempArrayOfFilteredClinics[i].lat],
            "type": "Point"
            }
        }
        newGeoJSONObject.features.push(newObj);
        }
        
        // console.log(newGeoJSONObject, 'object geojson');
        setFilteredGeoJSONH(newGeoJSONObject);
    };

    // useEffect(() => {
    // setPresentingElementBC(pageBC.current);
    // }, []);

    useEffect(() => {
        filterClinicList();
    }, [
    props.clinics,
    // props.citySelected,
    // isSearchBarOpenBC,
    // props.cityCoordinates,
    // searchTextBC,
    // props.geoJSONsau,
    // above may be redundant since it will change with bars and clubs... but state async... but worth having two updates?
    // also as i note above that filteredgeojson seems independent of geojson prop..
    // do i need current date and time if only to sync with parties and the whole 2 weeks upcoming thing
    
    ]);
    // tma about last two. what about city selected? well geojson would change... well actually there is no city. this is global... in saunas now..why did i write the thing about global

    

    useEffect(() => {
        console.log(filteredClinics, 'clinic')
    })

    return (
        <IonPage
        // ref={pageSau}
        >
          <IonHeader>
            <IonToolbar className="transparenttoolbar">
            <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">

                        <div className="abstlb">
                          <IonButtons className="btnposition">
                            <IonMenuButton color="primary" className="toolbaricontransparent" />
                          </IonButtons>
                        </div>
                          
                         
                            {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}

                        
                          
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsH}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
           
                    <div className="abscontionmenu">
                        <div className="toolbarother">
                                    <img className="trihq" src={logohq} alt="" />
                                    {/* <div className="logocirc">

                                    <img className="trihq" src={logohq} alt="" />
                                    </div> */}
                        
                        </div>
                    </div>

            <div className="abscontionmenu">
                              
                             
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              
            </div>
            <div className="abscontionmenu">
                          
                        
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                         

                          
            </div>
           
          </div>

                <div className="toolbar3">
                            <div className="searchtoolbarweb">
                                        <div className="misctitle">Health Clinics</div>
                            </div>

                            <div className="menusectb">



                      

                   

                                    <div className="newviewtog">
                                        <div className={healthView === "gridview" ? "segtext segcolored" : "segtext"}
                                        onClick={() => handleViewChangeH("gridview")}
                                        >
                                        <IonIcon icon={gridOutline} className="grdic"></IonIcon>
                                        </div>
                                        <div className="segtog">
                                        <IonToggle
                                        className="grdcaltog"
                                        value={healthView}
                                        checked={healthView === "mapview"}
                                        onIonChange={handleTogViewH}
                                        >
                                        </IonToggle>
                                        </div>
                                        <div className={healthView === "mapview" ? "segtext segcolored" : "segtext"}
                                        onClick={() => handleViewChangeH("mapview")}
                                        >
                                        <IonIcon icon={mapOutline} className="calic"></IonIcon>
                                        </div>
                                        
                                    </div>
                
                            </div>

                            <div className="infocontr2" onClick={()=>setIsInfoOpenH(true)}>
                                <IonIcon icon={helpCircleOutline} className="helpicon2"></IonIcon>

                            </div>
                </div>

               

                    <div className="mgdatesothpp">
                        <div className="infocontl"></div>
                        <div className="gridviewsectionheader6">
                                <div className="extbox pinkcol">
                                    {/* {selectedFest.name} */}
                                    {/* {props.citySelected} */}
                                    {/* {props.citySelected.name_abbr} */}
                                    {props.citySelected.name_abbr || props.citySelected.city}
                                    </div>
                                    {/* <div className="tinyball2"></div> */}
                                    <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                                    <div className="extbox whitecol">
                                    {/* {selectedFest.datestring} */}
                                    Health Clinics
                                    </div>

                                    


                                    
                            
                        </div>

                        <div className="infocontr" onClick={()=>setIsInfoOpenH(true)}>
                                        <IonIcon icon={helpCircleOutline} className="helpicon"></IonIcon>

                        </div>



                </div>
            </IonToolbar>
        </IonHeader>
          
    
        <IonContent fullscreen>

            {/* NOTIFICATIONS MODAL */}
          <IonModal
          ref={modalNotificationsH}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenH}
          onDidDismiss={handleCloseNotificationsH}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                        <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                        <div className="notifcardmain">
                                            {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                            <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                            <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                        </div>  

                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                              Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                        <div className="notifcardmalt">
                                           
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                        </div>
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>

        <IonModal
          ref={modalInfoH}
          isOpen={isInfoOpenH}
          onDidDismiss={() => setIsInfoOpenH(false)}
          className="infomodal"
          >
            <div className="infomodmsg">
            The clinics featured are dedicated to LGBTQ+ health and well-being, offering services like sexual health and STI testing, HIV prevention and treatment, general healthcare, and mental health support in safe, inclusive environments.
            </div>
          </IonModal>

        {/* <IonFab slot="fixed" vertical="top" horizontal="end">
                    <IonFabButton onClick={()=>setIsInfoOpenSau(true)}>
                      <IonIcon icon={helpCircleOutline} className="infoicon"></IonIcon>
                    </IonFabButton>
          </IonFab> */}
        
        

            
            {
            healthView ==="gridview"
            &&
            <div className="ultimategridviewcontainer">
                {/* {
                filteredBarsAndClubs?.length === 0 
                &&
                (tagsSelectedBC.length > 0 || isLikedBCTagOn)
                &&
                <div className="noparties gridviewlineheightsm">
                    There are no <span className="upcoming">bars or clubs</span> that correspond to the selected filter criteria. Please remove one or more filters.
                </div>
                } */}
                <div className="gridviewgrid"
                // style={{gridTemplateColumns: `repeat(${props.gridNo}, calc(100% / ${props.gridNo}))`}}
                >
                    {
                    filteredClinics?.map((clinic: any, index: number) => {
                        return <Link key={index} to={`/clinics/${clinic.id}`} style={{ display: 'contents' }}>
                            <div className="trybox2">
                            <div
                            className="gridviewcardstdnewnew"
                            >
                                {
                                clinic.image_src
                                &&
                                <img className="gridviewcardimage" src={clinic.image_src} alt=""
                                onError={(e: any) => e.target.src = clinic.image_url}
                                />
                                }
                                {
                                !clinic.image_src
                                &&
                                <img className="gridviewcardimage" src={clinic.image_url} alt=""
                                />
                                }
                                
                                <div className="gridviewcardimagecover2">



                                {/* <div className="gridviewBCballs">
                                        {
                                            sauna.monday_open !== ""
                                            &&
                                            <div className="gridballop">M</div>
                                        }
                                        {
                                            sauna.monday_open === ""
                                            &&
                                            <div className="gridballcl">M</div>
                                        }
                                        {
                                            sauna.tuesday_open !== ""
                                            &&
                                            <div className="gridballop">T</div>
                                        }
                                        {
                                            sauna.tuesday_open === ""
                                            &&
                                            <div className="gridballcl">T</div>
                                        }
                                        {
                                            sauna.wednesday_open !== ""
                                            &&
                                            <div className="gridballop">W</div>
                                        }
                                        {
                                            sauna.wednesday_open === ""
                                            &&
                                            <div className="gridballcl">W</div>
                                        }
                                        {
                                            sauna.thursday_open !== ""
                                            &&
                                            <div className="gridballop">Th</div>
                                        }
                                        {
                                            sauna.thursday_open === ""
                                            &&
                                            <div className="gridballcl">Th</div>
                                        }
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        {
                                            sauna.friday_open !== ""
                                            &&
                                            <div className="gridballop">F</div>
                                        }
                                        {
                                            sauna.friday_open === ""
                                            &&
                                            <div className="gridballcl">F</div>
                                        }
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        {
                                            sauna.saturday_open !== ""
                                            &&
                                            <div className="gridballop">S</div>
                                        }
                                        {
                                            sauna.saturday_open === ""
                                            &&
                                            <div className="gridballcl">S</div>
                                        }
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        {
                                            sauna.sunday_open !== ""
                                            &&
                                            <div className="gridballop">Su</div>
                                        }
                                        {
                                            sauna.sunday_open === ""
                                            &&
                                            <div className="gridballcl">Su</div>
                                        }
                                    </div> */}

                                    

                                    

                                    

                                   
                                </div>
                                
                            </div>


                                    <div className="gridviewbottomcontainertry">
                                        <div className="gridviewtitlecontainer">
                                        {
                                            clinic.name_short === ""
                                            &&
                                            <div className="gridviewtitlefulloth">{clinic.name}</div>
                                        }
                                        {
                                            clinic.name_short !== ""
                                            &&
                                            <div className="gridviewtitlefulloth">{clinic.name_short}</div>
                                        }

                                        
                                            {/* <div className="midcontsau">
                                                <div className="midcontdate">
                                                
                                                    <div className="addcut2">{clinic.happy_hour}</div>
                                                </div>
                                                
                                            
                                            </div> */}
                                        
                                        


                                         <div className="midcont2">
                                                
                                            <div className="midcontdateballs">
                                                   {/* Closes at 2pm */}

                                                   {
                                            clinic.monday_open !== ""
                                            &&
                                            <div className="gridballop">M</div>
                                        }
                                        {
                                            clinic.monday_open === ""
                                            &&
                                            <div className="gridballcl">M</div>
                                        }
                                        {
                                            clinic.tuesday_open !== ""
                                            &&
                                            <div className="gridballop">T</div>
                                        }
                                        {
                                            clinic.tuesday_open === ""
                                            &&
                                            <div className="gridballcl">T</div>
                                        }
                                        {
                                            clinic.wednesday_open !== ""
                                            &&
                                            <div className="gridballop">W</div>
                                        }
                                        {
                                            clinic.wednesday_open === ""
                                            &&
                                            <div className="gridballcl">W</div>
                                        }
                                        {
                                            clinic.thursday_open !== ""
                                            &&
                                            <div className="gridballop">Th</div>
                                        }
                                        {
                                            clinic.thursday_open === ""
                                            &&
                                            <div className="gridballcl">Th</div>
                                        }
                                        {
                                            clinic.friday_open !== ""
                                            &&
                                            <div className="gridballop">F</div>
                                        }
                                        {
                                            clinic.friday_open === ""
                                            &&
                                            <div className="gridballcl">F</div>
                                        }
                                        {
                                            clinic.saturday_open !== ""
                                            &&
                                            <div className="gridballop">S</div>
                                        }
                                        {
                                            clinic.saturday_open === ""
                                            &&
                                            <div className="gridballcl">S</div>
                                        }
                                        {
                                            clinic.sunday_open !== ""
                                            &&
                                            <div className="gridballop">Su</div>
                                        }
                                        {
                                            clinic.sunday_open === ""
                                            &&
                                            <div className="gridballcl">Su</div>
                                        }
                                        </div>


                                    </div>
                                    
                                    


                                    <div className="midcontalt">
                                                    {clinic.description}
                                    </div>


                                </div>


                                
                            </div>



                            </div>
                        </Link>
                        })
                        } 
                </div>
            </div>    
            }

            {/* do i need another conditional to say that only show this section if geojsonexists. because liek what if bars and clubs loaded and someone in a fraction of time clicked map view. */}
            {
            healthView==="mapview"
            &&
            // note I am using BC related names out of laziness (saves having to change logic in MapViewMulti or have separate one for sauna). i do want different styling tho so tma how to do this.
            <MapViewMulti
            // BCView={saunaView}
            filteredBarsAndClubs={filteredClinics}
            filteredGeoJSONBC={filteredGeoJSONH}
            cityCoordinates={props.cityCoordinates}
            cityZoom={props.cityZoom}
            // citySelected={props.citySelected}
            // rootsContainingMarkers={rootsContainingMarkers}
            // setRootsContainingMarkers={setRootsContainingMarkers}
            // fromCirc={false}
            />
            }

        </IonContent>

        <div className="segmentcon">
            <IonSegment
            id="check"
            value={healthView}
            className="segment-inner-container"
            swipeGesture={false}
            >

                <IonSegmentButton value="gridview"
                layout="icon-top"
                onClick={() => handleViewChangeH("gridview")}
                >
                    {/* <IonLabel>GRID</IonLabel> */}
                    <IonLabel>Grid</IonLabel>
                    <IonIcon icon={gridOutline}></IonIcon>
                </IonSegmentButton>
                {
                    props.clinics.length > 0
                    &&
                    <IonSegmentButton value="mapview"
                    onClick={() => handleViewChangeH("mapview")}
                >
                    {/* <IonLabel>CALENDAR</IonLabel> */}
                    <IonLabel>Map</IonLabel>
                    <IonIcon icon={mapOutline}></IonIcon>
                </IonSegmentButton>
                }


                {/* <IonSegmentButton
                value="gridview"
                onClick={() => handleViewChangeSau("gridview")}
                >
                    <IonLabel>GRID</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                value="mapview"
                onClick={() => handleViewChangeSau("mapview")}
                >
                    <IonLabel>MAP</IonLabel>
                </IonSegmentButton> */}
            </IonSegment>
        </div>
        
    </IonPage>

    )



}

export default Health;