import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  IonButtons,
  IonContent,
  IonHeader,
  // IonRefresher,
  // IonRefresherContent,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonIcon,
  IonModal,
  IonToggle,
  IonSearchbar,
  IonButton,
  // IonFab,
  // IonFabButton
} from '@ionic/react';

import {
  options,
  searchOutline,
  // heartSharp,
  notificationsOutline,
  // heartOutline,
  lockClosedOutline,
  // earthOutline,
  // pricetagOutline,
  // reorderThreeOutline,
  // informationOutline,
  chevronForwardOutline,
  addOutline,
  heartOutline,
  heartSharp,
  // helpOutline,
} from 'ionicons/icons';


import logohq from '../images/tgalogo_hq.png';


import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/searchbars.css';
import '../theme/spotcards.css';
import '../theme/filtermodals.css';
// import '../theme/swipers.css';
// import '../theme/segments.css';


// function numberWithCommas(x:any) {
//   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }


// Accent-folding function
const removeAccents = (str:string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};


const Cruises: React.FC<{
  isWeb: any;
  text: any;
  isWide: any;
  cruises: any;
  citySelected: any;
  currentDateAndTime: any;
  // openPickerSelectCity: any;
  tagsCruises: any;
  notifications: any;
  setNotifications: any;
  viewedNotifications: any;
  setViewedNotifications: any;
  viewedNotificationsAreLoaded: any;
  likedCruises: any;
  setLikedCruises: any;
  cruiseCountryList: any;
  // handlePullRefresherWithPause: any;
  handleIncrement: any;
  handleDecrement: any;
}> = ( props ) => {

    const [cruiseView, setCruiseView] = useState("gridview");

    const [filteredCruises, setFilteredCruises] = useState<any>(null);
    //const [filteredCruisesInProgress, setFilteredCruisesInProgress] = useState<any[]>([]);
    //only have the above if you can literally get on a cruise half way through. otherwise have logic in App to remove cruise if started. could one day have like a countdown.

    const [isNotificationsOpenCruises, setIsNotificationsOpenCruises] = useState(false);
    const [isInfoOpenC, setIsInfoOpenC] = useState(false);


    const [tagsSelectedCruises, setTagsSelectedCruises] = useState<any[]>([]);
    const [isLikedCruisesTagOn, setIsLikedCruisesTagOn] = useState(false);

    const [isSearchBarOpenCruises, setIsSearchBarOpenCruises] = useState(false);
    const [searchTextCruises, setSearchTextCruises] = useState("");
    const [searchTextCruises2, setSearchTextCruises2] = useState("");
    const [searchedForCruises, setSearchedForCruises] = useState([]);

    const [isFiltersOpenC, setIsFiltersOpenC] = useState(false);

    // const [presentingElementCruises, setPresentingElementCruises] = useState<HTMLElement | undefined>(undefined);
    
    // const pageCruises = useRef<any>(null);
    const modalTagsCruises= useRef<HTMLIonModalElement>(null);
    const modalNotificationsCruises= useRef<HTMLIonModalElement>(null);
    const modalInfoC= useRef<HTMLIonModalElement>(null);
    const modalSearchCruises = useRef<HTMLIonModalElement>(null);

    // useEffect(() => {
    //     setPresentingElementCruises(pageCruises.current);
    // }, []);

    //   const handlePullRefresherWithPause = (event: any) => {
    //     setTimeout(() => {
    //       props.handlePullRefresher();
    //       event.detail.complete();
    //     }, 2000);
    //   }

  const addTagsCruises = (tag: string) => {
    if (tagsSelectedCruises.includes(tag)) {
      // is includes just as efficient as filter. if (tagsSelected.filter(ele => ele === tag).length > 0)
      const tagSelectedOneLess = tagsSelectedCruises.filter((ele: any) => ele !== tag);
      setTagsSelectedCruises(tagSelectedOneLess);
    } else {
      setTagsSelectedCruises((prevTags: any) => {
        return prevTags.concat(tag);
      });
    }
  };

  const handleTurnOnTagCruises = (event: CustomEvent) => {
    addTagsCruises(event.detail.value);
  };

  //can combine the above two functions into one...

  const handleOpenNotificationsCruises = () => {
    setIsNotificationsOpenCruises(true);
    const arrayOfNotificationsNotAlreadyInState: any = [];
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
    }
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
    }
    for (let i = 0; i < props.notifications.length; i++) {
      if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
        arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
      }
    }
    props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
  }

  const handleCloseNotificationsCruises = () => {
    setIsNotificationsOpenCruises(false);
  }

  const handleLikeCruise = (event: any, cruisehash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    // console.log(event.target.value, 'does this cause reset23');
    props.setLikedCruises((prev: any)=>[...prev, cruisehash]);
    props.handleIncrement("cruises", cruisehash);
  }

  const handleUnlikeCruise = (event: any, cruisehash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    props.setLikedCruises(props.likedCruises.filter((cruiselikedhash:any) => cruiselikedhash !== cruisehash));
    props.handleDecrement("cruises", cruisehash);
  }

  const handleOpenSearchBarCruises = () => {
    setIsSearchBarOpenCruises(true);
  }

  const handleCloseSearchBarCruises = () => {
    setIsSearchBarOpenCruises(false);
    setSearchTextCruises("");
    setSearchedForCruises(props.cruises);
  }

  const handleSearchTextCruises = ({ target }:any) => {
    setSearchTextCruises(target.value);
  }

  const handleSearchTextCruises2 = ({ target }:any) => {
    setSearchTextCruises2(target.value);
  }

  const handleFilterCruises = () => {

    let tempArrayOfFilteredCruises:any = [];

    if (!isLikedCruisesTagOn) {
        tempArrayOfFilteredCruises = props.cruises.filter((cruise: any) => (
        (tagsSelectedCruises.every(tag => cruise.tags_array.includes(tag)) || tagsSelectedCruises.length === 0)
      ));
    }

    if (isLikedCruisesTagOn) {
        tempArrayOfFilteredCruises = props.cruises.filter((cruise: any) => (
        (tagsSelectedCruises.every(tag => cruise.tags_array.includes(tag)) || tagsSelectedCruises.length === 0)
        &&
        props.likedCruises.filter((ele: any) => ele === cruise.hash).length > 0
      ));
    }


    setFilteredCruises(tempArrayOfFilteredCruises);
    }


    const handleFilterCruisesNew = () => {
      const tagsSelectedLength = tagsSelectedCruises.length;
      const likedCruisesSet = new Set(props.likedCruises);
    
      // Prepare search text
      const searchTextTrimmed = (searchTextCruises2 || '').trim();
      const hasSearchText = searchTextTrimmed !== '';
      const transformedSearchText = hasSearchText
        ? removeAccents(searchTextTrimmed.toLowerCase())
        : '';
    
      const filteredCruises = [];
    
      // Iterate over all cruises once
      for (const cruise of props.cruises) {
        // Check if the cruise includes all selected tags or if no tags are selected
        const hasAllTags =
          tagsSelectedLength === 0 ||
          tagsSelectedCruises.every((tag) => cruise.tags_array.includes(tag));
    
        if (!hasAllTags) {
          continue; // Skip this cruise
        }
    
        // If liked cruises filter is on, check if the cruise is liked
        if (isLikedCruisesTagOn && !likedCruisesSet.has(cruise.hash)) {
          continue; // Skip this cruise
        }
    
        // Check if the cruise's name matches the search text
        if (hasSearchText) {
          const cruiseNameTransformed = removeAccents((cruise.name || '').toLowerCase());
          if (!cruiseNameTransformed.includes(transformedSearchText)) {
            continue; // Skip this cruise
          }
        }
    
        // If all conditions are met, include the cruise
        filteredCruises.push(cruise);
      }
    
      setFilteredCruises(filteredCruises);
    };

    useEffect(() => {
      if (!props.isWide && searchTextCruises2 !== "") {
    
        setSearchTextCruises2("");
      }
      else if (props.isWide && isSearchBarOpenCruises && searchTextCruises !== "") {
        console.log('why not heee');
        handleCloseSearchBarCruises();
        
      } else if (props.isWide && isSearchBarOpenCruises && searchTextCruises === "") {
        setIsSearchBarOpenCruises(false);
      }
    }, [props.isWide])

    useEffect(() => {
        handleFilterCruisesNew();
    }, [props.cruises,
        props.tagsCruises,
        // think more about whether above is required
        tagsSelectedCruises,
        // add geojson like i did with bars?
        props.currentDateAndTime,
        // is the above required? yes i think so but TMA. what about when city changes. well that will cause time to change but why have i used city then in useeffect below re resetting tags...
        isLikedCruisesTagOn,
        props.likedCruises,
        //the above i think is for where you have filter for liked parties on and you like or unlike a party... you need it to update.
        searchTextCruises2
    ]);

    useEffect(() => {
        
        // if (!searchTextCruises) return;
    
        const tempArrayOfSearchedForCruises = props.cruises.filter((cruise: any) => {
          const newName = removeAccents(cruise.name.toLowerCase());
            return newName.includes(searchTextCruises.toLowerCase())
          }
        );
        // console.log(tempArrayOfSearchedForCruises, 'search parties something whats different')
        setSearchedForCruises(tempArrayOfSearchedForCruises)
    }, [searchTextCruises, props.cruises])
    // previous version had parties as dependent... could have it... but does it matter... if you are in the middle of search does it matter if new party gets added... ill put it in for now

    useEffect(() => {
        setTagsSelectedCruises([]);
    }, [props.citySelected]);

  return (
    <IonPage
    // ref={pageCruises}
    >
      <IonHeader>
        <IonToolbar className="transparenttoolbar">

        <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">

                      <div className="abstlb">
                          <IonButtons className="btnposition">
                            <IonMenuButton color="primary" className="toolbaricontransparent" />
                          </IonButtons>
                      </div>
                          
                         
                            {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}

                        
                          
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsCruises}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
            {/* <div className="toolbarsideele">

                  
              
             

              <div className="toolbariconcontaineralt">
                    
                    
                    <div className="abscontionmenu tlmr">
                          <IonButtons slot="start">
                            <IonMenuButton className="toolbaricontransparent" />
                          </IonButtons>
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotifications}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
              </div>

              
              
           
            </div> */}
            

            <div className="abscontionmenu">
                        <div className="toolbarother">
                                    <img className="trihq" src={logohq} alt="" />
                                    {/* <div className="logocirc">

                                    <img className="trihq" src={logohq} alt="" />
                                    </div> */}
                        
                        </div>
              </div>

            <div className="abscontionmenu">
                             
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBarCruises}
                              />
                              
                             
            </div>
            <div className="abscontionmenu">
                         
                          <IonIcon
                          icon={options}
                          className="toolbaricon"              
                          // onClick={handleOpenTags}
                          onClick={() => setIsFiltersOpenC(true)}
                          />
                          
                          

                          {
                          (tagsSelectedCruises.length > 0 || isLikedCruisesTagOn)
                          // &&
                          // props.parties.length > 0
                          
                          &&
                          <div className="filtercounter">{tagsSelectedCruises.length + (isLikedCruisesTagOn ? 1 : 0)}</div>
                          }
              </div>
           
          </div>


         
          <div className="toolbar3">
                  <div className="searchtoolbarweb">
                    <IonSearchbar showClearButton="focus" animated={true} placeholder="Search for a cruise . . ."
                    onIonInput={(event)=>handleSearchTextCruises2(event)}
                    value={searchTextCruises2}
                    className={searchTextCruises2 === "" ? "searchbarpartiesweb" : "searchbarpartiesweb2"}
                    >
                    </IonSearchbar>
                    
                  </div>

                  <div className="menusectb">



                      

                      {
                              (tagsSelectedCruises.length === 0)
                      
                              &&
                      <div className="menuicncontaltpp" onClick={() => setIsFiltersOpenC(true)}>
                      <IonIcon icon={options} className="menicn2"></IonIcon>

                          
                              <div className="filtcirc">
                          
                              <IonIcon icon={addOutline} className="menicnfilt"></IonIcon>

                            </div>
                              
                    
                        
                            {/* Filter */}
                      </div>
                        }
                        {
                              (tagsSelectedCruises.length > 0)
                      
                              &&
                      <div className="menuicncontaltpp2" onClick={() => setIsFiltersOpenC(true)}>
                      <IonIcon icon={options} className="menicn22"></IonIcon>

                          
                            
                              
                      
                          
                              <div className="filtcirc">
                                <div className="menicnfiltnum">{tagsSelectedCruises.length}</div>
                              

                            </div>
                            
                        
                        
                      </div>
                        }

                      
                
              </div>
          </div>

                <div className="mgdatesoth">

                <div className="gridviewsectionheader6">
                          <div className="extbox pinkcol">
                            {/* {selectedFest.name} */}
                            {/* {props.citySelected} */}
                            Global
                            </div>
                            {/* <div className="tinyball2"></div> */}
                            <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                            <div className="extbox whitecol">
                            {/* {selectedFest.datestring} */}
                            {props.text}
                            </div>

                            


                            
                    {/* <div className="sechead1">
                        <div className="secbbl">
                          {selectedFest.datestring}
                        </div>
                      </div>
                    
                    <div className="sechead2">
                        <div className="secbbl">
                          {selectedFest.name}
                          </div>
                    </div> */}
                    
                    
                    {/* <div className="sechead3"> 
                    <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                    </div> */}
                    
                  </div>



                </div>


        </IonToolbar>
        
      </IonHeader>

      {/* <IonRefresher slot="fixed" onIonRefresh={props.handlePullRefresherWithPause}>
          <IonRefresherContent></IonRefresherContent>
      </IonRefresher> */}

      

      <IonContent fullscreen>

         {/* NOTIFICATIONS MODAL */}
         <IonModal
          ref={modalNotificationsCruises}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenCruises}
          onDidDismiss={handleCloseNotificationsCruises}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                    <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                                 <div className="notifcardmain">
                                                    {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                                    <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                                    <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                                </div>  
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                          <div className="notifcardmalt">
                                           
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div>

                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>
        
        {/* SEARCH MODAL */}
        <IonModal
        isOpen={isSearchBarOpenCruises}
        ref={modalSearchCruises}
        // presentingElement={presentingElementCruises!}
        // why the exclamation mark and do other components have this?
        // canDismiss={canDismiss}
        onDidDismiss={() => handleCloseSearchBarCruises()}
        // breakpoints={[0]}
        >
            <IonHeader>
                <IonToolbar className="searchtoolbar">
                    <div className="searchtoolbardiv">
                        <IonSearchbar
                        showClearButton="focus"
                        animated={false}
                        placeholder="Search for a cruise . . ."
                        onIonInput={(event)=>handleSearchTextCruises(event)}
                        value={searchTextCruises}
                        className="searchbarparties"
                        >
                        </IonSearchbar>
                        <IonButtons slot="end" className="closebutton">
                            <IonButton className="inncl" color="primary" onClick={handleCloseSearchBarCruises}>Close</IonButton>
                        </IonButtons>
                    </div>
                </IonToolbar>
            </IonHeader>
            <div className="searchmodalflexbox">
                                {
                                  searchedForCruises.length === 0
                                  &&
                                  <div className="noparties2">No results found.</div>
                                }
                <IonContent>
                    {searchedForCruises.map((cruise: any, index: number) => {
                        return <Link key={index} to={`/cruises/${cruise.id}`} style={{ display: 'contents' }}
                        // onClick={() => dismiss()}
                        onClick={() => handleCloseSearchBarCruises()}
                        >
                            <div className="searchspotcard">
                                <div className="searchspotfirstsection">
                                  {
                                    cruise.image_src
                                    &&
                                    <img className="searchfirstsectimg" src={cruise.image_src} alt=""
                                    onError={(e: any) => e.target.src = cruise.image_url}
                                    ></img>
                                  }
                                  {
                                    !cruise.image_src
                                    &&
                                    <img className="searchfirstsectimg" src={cruise.image_url} alt=""
                                    ></img>
                                  }
                                </div>
                                <div className="searchspotmainsectionnew">
                                    
                                        <div className="searchspotmainsectinneroth">{cruise.name}</div>
                                        <div className="searchspotmainsectinnerothb">
                                          <div className="addcut">{cruise.datestring}</div>
                                          <div className="tinyball"></div>
                                            <div className="grncol2">{cruise.region}</div>
                                        
                                        
                                        </div>
                                        
                                 
                                </div>
                                
                            </div>
                        </Link> 
                    })}
                </IonContent>
            </div>
        </IonModal>
        
        {/* FILTERS MODAL */}
        <IonModal
        isOpen={isFiltersOpenC}
        ref={modalTagsCruises}
        // trigger="open-tagsCruises-modal"
        onDidDismiss={()=>setIsFiltersOpenC(false)}
        // initialBreakpoint={1}
        // breakpoints={[0, .25, .5, .75, 1]}
        className="filtersmodalstd"
        >
            <IonContent className="filtersmodalcontainer">
                <div className="menusectheader2">
                    GENERAL
                </div>
                <div className="filtersgrid">
                    <div className="filtersinnerflexbox">
                        <div className="filtername2">Tickets left</div>
                        <IonToggle
                        slot="end"
                        value="Tickets left"
                        checked={tagsSelectedCruises.includes("Tickets left") ? true : false}
                        onIonChange={handleTurnOnTagCruises}
                        >
                        </IonToggle>
                    </div>
                    {
                !props.isWeb
                &&
                    <div className="filtersinnerflexbox">
                          <div className="filtername2">
                            Liked
                            {/* Liked */}
                            {/* {" ("}<IonIcon icon={heartSharp} className="filternameheart" color="danger"></IonIcon>{")"} */}
                          </div>
                          <IonToggle
                          slot="end"
                          value="Liked parties"
                          checked={isLikedCruisesTagOn}
                          onIonChange={()=>setIsLikedCruisesTagOn(!isLikedCruisesTagOn)}
                          >
                          </IonToggle>
                    
                    </div>
            }
                </div>
            
                <div className="menusectheader2">
                    DESTINATIONS
                </div>
                <div className="filtersgrid">
                    {
                    props.cruiseCountryList.map((country: any, index: number) => {
                    return <div
                    key={index}
                    className="filtersinnerflexbox"
                    >
                    <div className="filtername2">{country}</div>
                    <IonToggle
                    slot="end"
                    value={country}
                    checked={tagsSelectedCruises.includes(country) ? true : false}
                    onIonChange={handleTurnOnTagCruises}
                    >
                    </IonToggle>
                    </div>
                    })
                    }
                </div>
                {
                        props.cruises.filter((cruise:any) => (cruise.tags_array.includes("Women") || tagsSelectedCruises.includes("Women"))).length > 0
                        &&
                        <div className="menusectheader2">
                            AUDIENCE
                        </div>
                    }
                    {
                        props.cruises.filter((cruise:any) => (cruise.tags_array.includes("Women") || tagsSelectedCruises.includes("Women"))).length > 0
                        &&
                    <div className="filtersgrid">
                        <div
                        
                        className="filtersinnerflexbox"
                        >
                            <div className="filtername2">Women</div>
                            <IonToggle
                            slot="end"
                            value="Women"
                            checked={tagsSelectedCruises.includes("Women") ? true : false}
                            onIonChange={handleTurnOnTagCruises}
                            >
                            </IonToggle>
                        </div>
                        
                    </div>
                    }
               
            </IonContent>
        </IonModal>


        {/* <IonModal
          ref={modalInfoC}
          isOpen={isInfoOpenC}
          onDidDismiss={() => setIsInfoOpenC(false)}
          className="infomodal"
          >
            <div className="infomodmsg">
                Prices are in US dollars and reflect the lowest-cost accommodation option, which may or may not be available.
            </div>
          </IonModal>

          <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton size="small" onClick={()=>setIsInfoOpenC(true)}>
                      <IonIcon icon={helpOutline} className="infoicon"></IonIcon>
                    </IonFabButton>
          </IonFab> */}

         
        

        {/* <IonRefresher slot="fixed" onIonRefresh={handlePullRefresherWithPause}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

        {/* dont i need "&& filterCruises.length > 0"? check other components */}

        <div className="ultimategridviewcontainer">

        {
           !props.isWide
           &&
        filteredCruises?.length === 0
        &&
        (tagsSelectedCruises.length > 0 || isLikedCruisesTagOn)
                &&
        // <div className="noeventsavailablecontainer">
          <div className="noparties gridviewlineheightsm">
            There are no <span className="upcoming">cruises</span> that correspond to the selected filter criteria. Please remove one or more filters.
          {/* </div> */}
        </div>
        }
         {
           props.isWide
           &&
        filteredCruises?.length === 0
        &&
        (searchTextCruises2 !== "" || tagsSelectedCruises.length > 0 || isLikedCruisesTagOn)
                &&
        // <div className="noeventsavailablecontainer">
          <div className="noparties gridviewlineheightsm">
           There are no <span className="upcoming">cruises</span> that correspond to the selected filter and/or search criteria.
          {/* </div> */}
        </div>
        }

              {
                    filteredCruises?.length === 0
                    &&
                    tagsSelectedCruises.length === 0
                    &&
                    !isLikedCruisesTagOn
                    &&
                    searchTextCruises2 === ""
                    &&
                    <div className="noparties gridviewlineheightsm">
                        No cruises found.
                    </div>
                }
        
         
        <div className="gridviewgrid"
                >
                    {
                    filteredCruises?.map((cruise: any, index: number) => {
                        return <Link key={index} to={`/cruises/${cruise.id}`} style={{ display: 'contents' }}>
                            <div className="trybox2">
                            <div
                            className="gridviewcardstdnewnew"
                            >
                                {
                                cruise.image_src
                                &&
                                <img className="gridviewcardimage" src={cruise.image_src} alt=""
                                onError={(e: any) => e.target.src = cruise.image_url}
                                />
                                }
                                {
                                !cruise.image_src
                                &&
                                <img className="gridviewcardimage" src={cruise.image_url} alt=""
                                />
                                }
                                
                                {
                !props.isWeb
                &&
                                <div className="gridviewcardimagecover2">

                                    {
                                    props.likedCruises.filter((likedBChash: any) => likedBChash === cruise.hash).length === 0
                                    &&
                                    <div className="gridviewlikebuttoncontainer">
                                        <IonIcon
                                        icon={heartOutline}
                                        className="gridviewlikebutton"
                                        onClick={(event)=>handleLikeCruise(event, cruise.hash)}
                                    />
                                    </div>
                                    }
                                    {
                                    props.likedCruises.filter((likedBChash: any) => likedBChash === cruise.hash).length > 0
                                    &&
                                    <div className="gridviewlikebuttoncontainer">
                                        <IonIcon
                                        icon={heartSharp}
                                        color="danger"
                                        className="gridviewlikebutton"
                                        onClick={(event)=>handleUnlikeCruise(event, cruise.hash)}
                                        />
                                    </div>
                                    }

                                 
                                   
                                   
                                    
                                </div>
                                }
                                
                            </div>



                            <div className="gridviewbottomcontainertry">
                                    <div className="gridviewtitlecontainer">
                                          {
                                            cruise.name_short === ""
                                            &&
                                            <div className="gridviewtitlefulloth">{cruise.name}</div>
                                          }
                                          {
                                            cruise.name_short !== ""
                                            &&
                                            <div className="gridviewtitlefulloth">{cruise.name_short}</div>
                                          }

                                            <div className="midcont2">
                                                <div className="addcut2">{cruise.region}</div>
                                            </div>

                                          <div className="midcont2">
                                            <div className="midcontdate">
                                                {cruise.datestring}
                                            </div>
                                            {/* <div className="tinyball">

                                            </div>
                                            <div className="midconttime">
                                                {tour.start_time}
                                            </div> */}
                                          </div>

                                          <div className="midcontalt">
                                                        {cruise.description}
                                          </div>
                                          
                                          </div>
                                       
                            </div>


                            </div>
                        </Link>
                        })
                        } 
                </div>
    

        
        
        </div>
        </IonContent>
    </IonPage>
  );
};

export default Cruises;